import React, { useEffect, useState } from 'react';
import useStyles from '../style';
import { renderErrorCreatePool } from '../../../utils/validate';
import { Chip, Tooltip } from '@material-ui/core';

function ProjectCategory(props: any) {
  const classes = useStyles();
  const { register, errors, poolDetail, setValue, isViewDetail } = props;
  const renderError = renderErrorCreatePool;
  const [rows, setRows] = useState<any[]>([]);
  const [category, setCategory] = useState<string>('');

  useEffect(() => {
    if(poolDetail){
      if(poolDetail.category === "") return;
      if(poolDetail.category){
        setRows(JSON.parse(poolDetail.category) || []);
        setValue('project_category', JSON.stringify(poolDetail.category) || [],{shouldValidate: true});
      }else{
        setRows([]);
        setValue('project_category', null,{shouldValidate: true});
      }
      
    }
  }, [poolDetail, setValue]);

  const handleAddRow = (e: any) => {
    e.preventDefault();
    if(!category) return;
    setRows(oldRow => [...oldRow,category]);
    setValue('project_category', JSON.stringify(rows),{shouldValidate: true});
    setCategory("")
    if(category && category.length > 0) {
      
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setCategory(value);
  };

  const handleDelete = (indexRow: number) => {
    if (!window.confirm('Do you want delete this record ?')) {
      return false;
    }
    const newRows = rows.filter((item: any, index: number) => index !== indexRow);

    setRows(newRows);
    if(newRows && newRows.length > 0 ){
      setValue('project_category', JSON.stringify(newRows),{shouldValidate: true});
    }else{
      setValue('project_category', null,{shouldValidate:true});
    }

  }
  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.exchangeRateTitle}>
          Category <span style={{ color: '#e51d1d' }}>*</span>
        </label>
        <input
          type="hidden"
          name="project_category"
          value={rows && rows.length > 0 ? JSON.stringify(rows) : undefined}
          ref={register({
            required: true,
          })}
        />
        <ul className={classes.listCategory}>
          {rows &&
            rows?.length > 0 &&
            rows?.map((row, index) => (
              <li key={`${row.url}-${index}`} className={`${classes.projectCategoryItem}`}>
               <Tooltip title={row}>
                <Chip
                    disabled={isViewDetail}
                    className={classes.chipProject}
                    label={row}
                    variant="outlined"
                    onDelete={() => handleDelete(index)}
                  />
               </Tooltip>
              </li>
            ))}
        </ul>

        {rows?.length < 30 && <form onSubmit={handleAddRow} className={isViewDetail ? classes.displayNone : ""}>
          <div className={classes.formControlWrap}>
            <div className={classes.formControlFlexBlock}>
              <div className={classes.formControlRate}>
                <input
                  type="text"
                  name="category_item"
                  value={category}
                  maxLength={100}
                  className={classes.formControlInput}
                  onChange={handleChange}
                />
               
              </div>
            </div>

            <span style={{ marginRight: 16, marginTop: 26, display: "block" }}>
            </span>
              <div className={classes.formControlFlexBlock}>
                <button
                  className={classes.circleButton}
                  style={{ marginRight: 16, marginTop: 9 }}
                  type="submit"
                >
                  <img src="/images/icon_plus.svg" />
                </button>
              </div>
            </div>
          <p className={classes.formErrorMessage}>
                  {renderError(errors, "project_category")}
                </p>
        </form>
        }
      </div>
    </>
  );
}

export default ProjectCategory;
