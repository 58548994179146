import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { CHAIN_ID_NAME_MAPPING } from '../../../constants';
import useStyles from '../styleTable';

const contractTableHeader = ['Contract name', 'Contract address', 'Owner'];

interface ComponentProps {
  contractAddressData: any[] | null;
}

const ContractAddressList: React.FC<any> = ({ contractAddressData }: ComponentProps) => {
  const classes = useStyles();
  const { currentNetworkId } = useSelector((state: any) => state.userCurrentNetwork);
  const checkIsKlaytn = () => {
    return CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes('klaytn');
  };
  const checkData = () => {
    if (checkIsKlaytn()) {
      return contractAddressData?.filter((item, index) => index !== 0 && index !== 1);
    } else return contractAddressData;
  };

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          {contractTableHeader.map((tableHeader: string, index: number) => (
            <TableCell key={index} className={classes.tableHeader}>
              {tableHeader}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        {contractAddressData &&
          contractAddressData.length &&
          checkData()?.map((contract: any, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell component="th">{contract.label}</TableCell>
                <TableCell>{contract.contractAddress}</TableCell>
                <TableCell>{contract.owner}</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default ContractAddressList;
