import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../components/Base/ButtonLink';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { PAGE_SIZE_X2 } from '../../constants';
import useDebounce from '../../hooks/useDebounce';
import { useSocket } from '../../hooks/useSocket';
import { getCampaigns } from '../../store/actions/campaign';
import { campaignActions } from '../../store/constants/campaign';
import { adminRoute } from '../../utils';
import CampaignsRecord from './PoolsRecord';
import useStyles from './style';

const tableHeaders = [
  'Project Name',
  'Contract Address',
  'Deployed By',
  'Network',
  'Token',
  'Amount',
  'Status',
  'Action',
];

const Pools: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    page = 1,
    lastPage,
    data: campaigns,
    searchValue,
  } = useSelector((state: any) => state.campaigns.data);
  const { loading, failure } = useSelector((state: any) => state.campaigns);
  const [currentPage, setCurrentPage] = useState(page);
  const [query, setQuery] = useState<any>(searchValue);
  const debounceSearch = useDebounce(query, 1000);
  const { socket } = useSocket();

  useEffect(() => {
    dispatch(getCampaigns(currentPage, debounceSearch));
    history.push({
      search: `?page_number=${currentPage}&page_size=${PAGE_SIZE_X2}&searchValue=${encodeURIComponent(
        debounceSearch || '',
      )}`,
    });
  }, [currentPage, dispatch, debounceSearch, history]);

  useEffect(() => {
    socket.disconnect();
  }, []);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueSearch = event.target.value;
    if (!valueSearch.startsWith(' ')) {
      dispatch({ type: campaignActions.CAMPAIGNS_REQUEST });
      setQuery(valueSearch);
      setCurrentPage(1);
    }
  };

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <Button
            to={adminRoute('/campaigns/add')}
            text={'Create New Pool'}
            icon={'icon_plus.svg'}
          />
          <div style={{ marginLeft: 20, display: 'inline-block' }}></div>
          {/* <Button
            to={adminRoute('/campaigns/add/v2')}
            text={'Create New Pool Multiple Language'}
            icon={'icon_plus.svg'}
          /> */}
        </div>
        <div className={classes.headerRight}>
          <div className={classes.boxSearch}>
            <input
              value={query}
              className={classes.inputSearch}
              onChange={onChangeSearch}
              placeholder="Search"
            />
            <img className={classes.iconSearch} src="/images/icon-search.svg" alt="" />
          </div>
        </div>
      </div>
      {/* <div className={classes.refreshCampaigns}>
        <span className={classes.refreshCampaignsContainer}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                name="checkedIcon"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFilter(e.target.checked);
                  setCurrentPage(1);
                }}
              />
            }
            label={<p className={classes.refreshCampaignsText}>Filter by your own pools</p>}
          />
        </span>
        <span
          className={classes.refreshCampaignsContainer}
          onClick={() => handleCampaignQuery(currentPage, query, startTime, finishTime, filter)}
        >
          <CachedIcon className={`${classes.refreshCampaignsIcon} refreshCampaignsIcon`} />
          <p className={classes.refreshCampaignsText}>Click to refresh pools</p>
        </span>
      </div> */}
      <TableContainer component={Paper} className={classes.tableContainer}>
        {loading ? (
          [...Array(10)].map((num, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={'100%'} />
            </div>
          ))
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {tableHeaders.map((tableHeader: string, index: number) => (
                  <TableCell
                    key={index}
                    className={`${classes.tableHeader} ${classes.tableHeaderLastItem}`}
                  >
                    {tableHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {campaigns &&
                campaigns.length > 0 &&
                campaigns.map((campaign: any, index: number) => (
                  <CampaignsRecord key={index} campaign={campaign} currentPage={currentPage} />
                ))}
            </TableBody>
          </Table>
        )}
        {failure ? (
          <p className={classes.errorMessage}>{failure}</p>
        ) : (!campaigns || campaigns.length === 0) && !loading ? (
          <p className={classes.noDataMessage}>There is no project to show</p>
        ) : (
          <>
            {campaigns && lastPage > 1 && (
              <Pagination
                page={currentPage}
                className={classes.pagination}
                count={lastPage}
                onChange={handlePaginationChange}
              />
            )}
          </>
        )}
      </TableContainer>
    </DefaultLayout>
  );
};
export default Pools;
