import { Button } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

interface IProps {
  children: any;
  type?: 'button' | 'submit';
  className?: string;
  onClick: () => void;
  disabled?:boolean
}

const ButtonAction = (props: IProps) => {
  const { children, type, className, ...onProps } = props;
  const classes = useStyles();
  return (
    <Button type={type} className={`${classes.btnSubmit} ${className || ''}`} {...onProps}>
      {children}
    </Button>
  );
};

export default ButtonAction;
