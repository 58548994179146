import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import ButtonAction from '../../../../../components/Base/ButtonCustom';
import useStyles from './styles';
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
  open: boolean;
  loading: boolean;
  handleSubmit: () => void;
  handleClose: () => void;
}
const ModalConfirmRandom = (props: IProps) => {
  const { open, loading, handleClose, handleSubmit } = props;
  const classes = useStyles();
  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle className={classes.modalTitle}>
          Confirm randomize winning tickets
          <CloseIcon className={classes.iconClose} width={20} onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.modalDescription}>
            This will rewrite the existing winning tickets result. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalAction}>
          <ButtonAction className={classes.btnCancel} onClick={handleClose}>
            Cancel
          </ButtonAction>
          <ButtonAction className={classes.btnConfirm} onClick={handleSubmit}>
            Confirm {loading ? <CircularProgress size={25} /> : null}
          </ButtonAction>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalConfirmRandom;
