import React from 'react';
import useStyles from '../../style';
import { renderErrorCreatePool } from '../../../../utils/validate';
import { MESSAGE_INVALID_URL, REGEX_URL } from '../../../../constants';

function DiscordLinkGroup(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, watch, getValues, needValidate, poolDetail, isViewDetail } =
    props;
  const renderError = renderErrorCreatePool;

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Link Group Discord</label>
        <input
          type="text"
          name="platform_discord_link"
          defaultValue={poolDetail?.platform_discord_link}
          ref={register({
            pattern: {
              value: REGEX_URL,
              message: MESSAGE_INVALID_URL,
            },
          })}
          className={classes.formControlInput}
          disabled={isViewDetail}
        />
        <p className={classes.formErrorMessage}>{renderError(errors, 'platform_discord_link')}</p>
      </div>
    </>
  );
}

export default DiscordLinkGroup;
