import { getContractInstance } from '../../../services/web3';
/* eslint-disable react-hooks/exhaustive-deps */
import { BigNumber } from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';
import stakeRewardABI from '../../../abi/Staking/PoolRewardABI.json';
import { getRewardPoolAdress } from '../../../utils/contractAddress/getAddresses';

const useRewardPoolBSC = () => {
  const currentNetworkId = localStorage.getItem('NETWORK_ID');
  const SP_ADDRESS = getRewardPoolAdress(currentNetworkId?.toString() || '') as string;
  
  const [loading, setLoading] = useState<boolean>(false);

  const fetchStakingLPInfo = useCallback(async () => {

    try {
      setLoading(true)
      const contract = getContractInstance(stakeRewardABI,SP_ADDRESS, true);
      if (!contract) {
        throw new Error('Invalid contract');
      }
      const [currentStake, apr, cap, min, max] = await Promise.all([
        contract.methods.currentTotalStaked().call(),
        contract.methods.fixedAPR().call(),
        contract.methods.maxTotalStake().call(),
        contract.methods.minStake().call(),
        contract.methods.maxStake().call(),
      ]);

      
      
      setLoading(false)
      
      return {
        currentTotalStaked:new BigNumber(currentStake).dividedBy(10 ** 18).toString(),
        APR: new BigNumber(apr).dividedBy(10 ** 2).toString(),
        maxTotalStake:new BigNumber(cap).dividedBy(10 ** 18).toString(),
        minStake:new BigNumber(min).dividedBy(10 ** 18).toString(),
        maxStake:new BigNumber(max).dividedBy(10 ** 18).toString(),
      }
    } catch (err: any) {
      console.log('ERROR: ', err);
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    fetchStakingLPInfo()
  }, [fetchStakingLPInfo])

  return {
    loading,
    fetchStakingLPInfo,
  }
}

export default useRewardPoolBSC;