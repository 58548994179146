import BigNumber from 'bignumber.js';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InstallMetameask from './components/Base/InstallMetamask';
import { useTypedSelector } from './hooks/useTypedSelector';
import { getWeb3Instance, isMetaMaskInstalled } from './services/web3';
import { logout } from './store/actions/user';
import { userActions } from './store/constants/user';
import { adminRoute } from './utils';
BigNumber.config({ EXPONENTIAL_AT: 50 });

const AppContainer = (props: any) => {
  const dispatch = useDispatch();
  const { data: loginUser } = useTypedSelector((state) => state.user);
  const { data: loginInvestor } = useTypedSelector((state) => state.investor);

  const web3Instance = getWeb3Instance("read");

  const onLoginWithoutLoginPage = async () => {
    if (isMetaMaskInstalled()) {
      const { history } = props;
      const { ethereum } = window as any;
      // const metamaskProvider = ethereum.providers.find((provider:any) => provider.isMetaMask);
      await ethereum
        .request({
          method: 'net_version',
        })
        .then((currentNetworkId: string) => {
          localStorage.setItem('NETWORK_ID', currentNetworkId);
          dispatch({ type: userActions.USER_WALLET_CHANGED, payload: currentNetworkId });
        });

      web3Instance?.eth.getAccounts().then((accounts: any) => {
        if (accounts.length === 0) {
          dispatch({ type: userActions.USER_CONNECT_WALLET_LOCK });
          dispatch({ type: userActions.USER_WALLET_CHANGED, payload: '' });

          const pathName = history.location.pathname;
          if (pathName !== '/network-change' && pathName !== '/dashboard/network-change') {
            console.log('Mismatch route: /network-change');
            dispatch(logout(false));
            setTimeout(() => {
              history.push(adminRoute('/login'));
            }, 1500);
          }
        }
      });
    }
  };

  useEffect(() => {
    onLoginWithoutLoginPage();
  }, [props.location.pathname]);

  useEffect(() => {
    const windowObj = window as any;
    const { ethereum } = windowObj;

    if (ethereum) {
      web3Instance?.eth?.getAccounts().then((accounts: any) => {
        accounts[0] &&
          dispatch({
            type: userActions.USER_CONNECT_WALLET_SUCCESS,
            payload: accounts[0],
          });
      });

      ethereum.on('accountsChanged', function (accounts: any) {
        console.log('accountsChanged');
        const account = accounts.length ? accounts[0] : '';

        if (account) {
          if (loginUser && account !== loginUser.wallet_address) {
            // dispatch(logout());
            localStorage.removeItem('access_token');
            return window.location.replace('/');
          }
          if (loginInvestor && account !== loginInvestor.wallet_address) {
            // dispatch(logout(true));
            localStorage.removeItem('access_token');
            return window.location.replace('/');
          } else {
            dispatch({
              type: userActions.USER_CONNECT_WALLET_SUCCESS,
              payload: account,
            });
          }
        } else {
          // dispatch(logout());
          localStorage.removeItem('access_token');
          dispatch({
            type: userActions.USER_CONNECT_WALLET_LOCK,
          });
          return window.location.replace('/');
        }
      });

      ethereum.on('chainChanged', (newNetworkId: string) => {
        console.log('chainChanged');
        localStorage.setItem('NETWORK_ID', String(Number(newNetworkId)));
        dispatch({ type: userActions.USER_WALLET_CHANGED, payload: String(Number(newNetworkId)) });
        localStorage.removeItem('access_token');
        return window.location.replace('/');
        // dispatch(logout());
        // if (
        //   Number(NETWORK_ID) !== Number(newNetworkId) &&
        //   Number(NETWORK_ID_BSC) !== Number(newNetworkId)
        // ) {
        //   console.log('Network change: NETWORK_ID:', newNetworkId);
        //   // dispatch(alertFailure('Please change to correct Network !'));
        // }

        // const { history } = props;
        // const pathName = history.location.pathname;
        // let backUrlKey = checkIsAdminRoute(pathName) ? BACK_URL_NETWORK_CHANGE_OWNER : BACK_URL_NETWORK_CHANGE;
        //
        // console.log('pathName', pathName);
        // console.log('backUrlKey', backUrlKey);
        //
        // if (Number(NETWORK_ID) !== Number(newNetworkId)) {
        //   console.log('CHANGE_TO_OTHER_NETWORK');
        //   if (pathName !== '/network-change') {
        //     localStorage.setItem(backUrlKey, pathName);
        //     if (checkIsAdminRoute(pathName)) {
        //       history.push('/dashboard/network-change');
        //     } else {
        //       history.push('/network-change');
        //     }
        //   }
        //   return true;
        // }
        //
        // const backUrl = localStorage.getItem(backUrlKey);
        // console.log('SAME_NETWORK');
        // console.log('backUrl', backUrl);
        //
        // if (backUrl) {
        //   if (backUrlKey === BACK_URL_NETWORK_CHANGE_OWNER) {
        //     history.push('/dashboard');
        //   } else {
        //     history.push(backUrl);
        //   }
        //   setTimeout(() => {
        //     localStorage.removeItem(backUrlKey);
        //   }, 1000);
        // }
      });
    }
  }, [loginUser]);

  if (!isMetaMaskInstalled()) {
    return <InstallMetameask />;
  }

  return <>{props.children}</>;
};

export default withRouter(AppContainer);
