import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 14,
    backgroundColor: 'white',
    '& .MuiTableCell-root': {
      border: '1px solid #E5E5E5',
    },
  },
  tableHeader: {
    // padding: '0px 0px 25px 30px',
    color: '#363636',
    fontWeight: 600,
  },
  tableBody: {
    '& > .MuiTableRow-root:last-child': {
      borderBottom: '1px solid #E5E5E5',
    },
    '& > .MuiTableRow-root:nth-child(odd)': {
      backgroundColor: 'white',
    },
  },
  tableCellHeader: {
    textAlign: 'center',
  },
}));

export default useStyles;
