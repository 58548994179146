import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { trimMiddlePartAddress } from '../../../../../utils/accountAddress';
import { convertMomentDate } from '../../../../../utils/convertDate';
import useStyles from './styles';
import { headCells } from './utils';

interface IProps {
  data: any;
}

const TicketTable = (props: IProps) => {
  const { data } = props;
  const classes = useStyles();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((cell) => (
              <TableCell key={cell.id}>{cell.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((ticket: any, index: any) => (
            <TableRow key={index}>
              <TableCell>{ticket.ticket_id || '--'}</TableCell>
              <TableCell>
                <Tooltip title={ticket.ticket_wallet_address} placement="top">
                  <span>{trimMiddlePartAddress(ticket.ticket_wallet_address, 6, 4) || '--'}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{ticket.snapshot_tier || '--'}</TableCell>
              <TableCell>{convertMomentDate(ticket.ticket_purchase_time) || '--'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TicketTable;
