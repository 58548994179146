import AddIcon from '@material-ui/icons/Add';
import StakeLPItem from './StakeLPItem';
import useStyles from './style';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import StakeLPForm from './StakeLPForm';
import { useState } from 'react';
import PoolFormDialog from '../shared/Dialog';
import ButtonAction from '../../../components/Base/ButtonCustom';

type ComponentProps = {
  stakeLPList: any;
  onRefreshLPList: () => Promise<void>;
  onCreatePoolLP: (form: any) => Promise<void>;
  onChangeTokenStatus: (form: any) => Promise<void>;
  existTokens: any;
  isPermission?:boolean,
  isPolygon?:boolean
};

const StakeLPList: React.FC<any> = ({
  stakeLPList,
  onRefreshLPList,
  onCreatePoolLP,
  onChangeTokenStatus,
  existTokens,
  isPermission,
  isPolygon
}: ComponentProps) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState<boolean>(false);

  const openCreateLPPool = () => {
    setShowModal(true);
  };

  const closeEditModal = () => {
    setShowModal(false);
  };

  const createTier = (form: any) => {
    onCreatePoolLP(form);
    closeEditModal();
  };

  return (
    <section className={classes.section}>
      <div className={classes.flexBetween}>
        <h4 className={classes.sectionTitle}>Tier Staking LPs</h4>
        <div>
          <ButtonAction onClick={onRefreshLPList}>Sync Data</ButtonAction>
          <ButtonAction className={classes.addLPStake} onClick={openCreateLPPool} disabled={!isPermission || !isPolygon}>Add LP Stake</ButtonAction>
        </div>
        {/* <IconButton className={classes.createPoolBtn} onClick={openCreateLPPool}>
          <AddIcon style={{ fontSize: '1.125rem' }} />
        </IconButton> */}
      </div>
      <ul>
        {stakeLPList.map(({ id, ...stake }: any) => (
          <li key={id}>
            <StakeLPItem {...stake} onEditToken={onChangeTokenStatus} isPermission={isPermission} isPolygon={isPolygon}/>
          </li>
        ))}
      </ul>
      <PoolFormDialog
        title="Add LP stake pool"
        showModal={showModal}
        closeModalHandler={closeEditModal}
        style={{ dialog: classes.dialog, modalTitle: classes.modalTitle }}
      >
        <p className={classes.modalSubTitle}>Support token on Polygon only</p>
        <StakeLPForm
          onClose={closeEditModal}
          onSubmit={createTier}
          existTokens={existTokens}
          errorMessageList={{
            conversionRate: 'Convension rate must be a positive number',
            maximumDecimal: 'Maximum decimal must be a positive interger',
            tokenAddress: "Token address can't be empty",
            tokenSymbol: "Token symbol can't be empty",
            icon: 'Icon must be provided',
          }}
        />
      </PoolFormDialog>
    </section>
  );
};

export default StakeLPList;
