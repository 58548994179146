import {useEffect, useMemo} from 'react';
import useStyles from "../style";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Controller } from "react-hook-form";
import {renderErrorCreatePool} from "../../../utils/validate";
import {
  ETH_NETWORK_ACCEPT_CHAINS,
  POLYGON_NETWORK_ACCEPT_CHAINS,
  NETWORK_AVAILABLE,
  KLAYTN_NETWORK_ACCEPT_CHAINS,
  CHAIN_IDS
} from "../../../constants";

function NetworkAvailable(props: any) {
  const classes = useStyles();
  const {
    setValue, errors, control,
    poolDetail, needValidate, isViewDetail
  } = props;
  const renderError = renderErrorCreatePool;
  
  const { ethereum } = window as any;
  const currentNetworkId = ethereum.networkVersion;
  const generateNetwork = useMemo(() => {
    switch (Number(currentNetworkId)) {
      case CHAIN_IDS.POLYGON:
      case CHAIN_IDS.POLYGON_TESTNET:
        return 'polygon';
      case CHAIN_IDS.KLAYTN_MAINNET:
      case CHAIN_IDS.KLAYTN_TESTNET:
       return 'klaytn';
      default:
        return 'polygon'
    }
  }, [currentNetworkId])
  
  useEffect(() => {
    if (poolDetail && poolDetail.network) {
      setValue('network', poolDetail.network);
    } else {
      setValue('network', generateNetwork);
    }
  }, [poolDetail]);
  const isDeployed = !!poolDetail?.is_deploy;

  return (
    <>
      <div className={classes.formControl}>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>Network</label>

          <Controller
            rules={{
              required: true,
              validate: {
                networkNotMatch: value => {
                  // Validate Only click Deploy button
                  if (!needValidate) return true;
                  let acceptNet = '';
                  switch (value) {
                    // case NETWORK_AVAILABLE.BSC:
                    //   acceptNet = BSC_NETWORK_ACCEPT_CHAINS[currentNetworkId]
                    //   break
                    case NETWORK_AVAILABLE.POLYGON:
                      acceptNet = POLYGON_NETWORK_ACCEPT_CHAINS[currentNetworkId]
                      break
                    case NETWORK_AVAILABLE.KLAYTN:
                      acceptNet = KLAYTN_NETWORK_ACCEPT_CHAINS[currentNetworkId]
                      break
                    default:
                      acceptNet = ETH_NETWORK_ACCEPT_CHAINS[currentNetworkId]
                  }

                  console.log('acceptNet', acceptNet);
                  if (!acceptNet) {
                    console.log('Network Deploy not match!!!');
                    return false;
                  }
                  return true;
                }
              }
            }}
            control={control}
            defaultValue="polygon"
            name="network"
            as={
              <RadioGroup row>
                {/* <FormControlLabel
                  value={NETWORK_AVAILABLE.ETH} control={<Radio />}
                  label="Ether"
                  disabled={isDeployed}
                /> */}
                <FormControlLabel 
                  value={NETWORK_AVAILABLE.POLYGON || ''} control={<Radio />}
                  label="POLYGON"
                  disabled={isDeployed || isViewDetail}
                />
                <FormControlLabel
                  value={NETWORK_AVAILABLE.KLAYTN || ''} control={<Radio />}
                  label="KLAYTN"
                  disabled={isDeployed || isViewDetail}
                />
                 {/* <FormControlLabel
                  value={NETWORK_AVAILABLE.BSC || ''} control={<Radio />}
                  label="BSC"
                  disabled={isDeployed || isViewDetail}
                /> */}
           
              </RadioGroup>
            }
          />
          <p className={classes.formErrorMessage}>
            {
              renderError(errors, 'network')
            }
            {
              renderError(errors, 'networkNotMatch')
            }
          </p>
        </FormControl>
      </div>
    </>
  );
}

export default NetworkAvailable;
