import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: '20px 25px 30px 25px',
    marginTop: 20,
  },
  titleField: {
    fontWeight: 'bold',
  },
  ticketingContent: {
    marginTop: 20,
  },
  subContent: {
    paddingLeft: 100,
  },
  titleTicketTab: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    textTransform: 'capitalize',
  },
  tabTableTicket: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#FFCC00',
    },
  },
  noItemTicket: {
    display: 'flex',
    justifyContent: 'center',
    padding: 50,
  },
  spacingBlock: {
    marginTop: 30,
  },
  bodyTable: {
    minHeight: 300,
  },
  pagination: {
    marginTop: 30,
    fontSize: 16,
    fontWeight: 400,
    '& .MuiPagination-ul': {
      justifyContent: 'center',
      display: 'flex',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#FFCC00',
    },
  },
  blockBtnAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default useStyles;
