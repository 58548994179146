import { getContractInstance } from '../../../services/web3';
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import stakeRewardABI from '../../../abi/Staking/PoolRewardABI.json';

const SP_ADDRESS = process.env.REACT_APP_POLYGON_REWARD_STAKING_POOL_ADDRESS as string


const useRewardWithdrawFee = () => {
  const [loading, setLoading] = useState<boolean>(false);
  
  const fetchRewardWithdrawFee = useCallback(async () => {

    try {
      setLoading(true)
      const contract = getContractInstance(stakeRewardABI, SP_ADDRESS)
      if (!contract) {
        throw new Error('Invalid contract');
      }
      const [aNumber, bNumber, cNumber, withDrawFeeTime,] = await Promise.all([
        contract.methods.aNumber().call(),
        contract.methods.bNumber().call(),
        contract.methods.cNumber().call(),
        contract.methods.noWithdrawFeeTime().call(),
      ]);

      
      
      setLoading(false)
      
      return {
        aNumber:aNumber,
        bNumber:bNumber,
        cNumber:cNumber,
        withDrawFeeTime:withDrawFeeTime,
      }
    } catch (err: any) {
      console.log('ERROR: ', err);
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    fetchRewardWithdrawFee()
  }, [fetchRewardWithdrawFee])

  return {
    loading,
    fetchRewardWithdrawFee,
  }
}

export default useRewardWithdrawFee;