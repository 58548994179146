import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { etherscanRouteTxHash } from '../../../utils';
import { trimMiddlePartAddress } from '../../../utils/accountAddress';
import { convertMomentDate } from '../../../utils/convertDate';
import useStyles from './styles';
import { headCells } from './utils';

const ListAdminPermission = (props: any) => {
  const { data, handleRemoveRole } = props;
  const classes = useStyles();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((cell) => (
              <TableCell key={cell.id}>{cell.label}</TableCell>
            ))}
            <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((addressAdmin: any, index: any) => (
            <TableRow key={index}>
              <TableCell>
                <Tooltip title={addressAdmin?.wallet_address} placement="top">
                  <span>{trimMiddlePartAddress(addressAdmin?.wallet_address, 5, 6) || '--'}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title={addressAdmin?.added_by} placement="top">
                  <span>{trimMiddlePartAddress(addressAdmin?.added_by, 5, 6) || '--'}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{convertMomentDate(addressAdmin?.added_at) || '--'}</TableCell>
              <TableCell>
                <a href={etherscanRouteTxHash(addressAdmin?.tx_hash)} target="_blank" rel="noreferrer">
                  <Tooltip title={addressAdmin?.tx_hash} placement="top">
                    <span>{trimMiddlePartAddress(addressAdmin?.tx_hash, 4, 5) || '--'}</span>
                  </Tooltip>
                  <OpenInNewIcon style={{ fontSize: '1rem' }} />
                </a>
              </TableCell>
              <TableCell>
                <div className={classes.tableCellFlex}>
                  <Button
                    className={`${classes.editDialogView} dialog-cta remove`}
                    onClick={() => handleRemoveRole(addressAdmin?.wallet_address)}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListAdminPermission;
