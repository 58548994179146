import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import ConfirmDialog from "../../../../components/Base/ConfirmDialog";
import { renderErrorCreatePool } from "../../../../utils/validate";
import useStyles from "../../style";
import CurrencyInputWithValidate from "../CurrencyInputWithValidate";
import BigNumber from 'bignumber.js'

export interface Raing {
  criteria: string;
  score: string;
}
function CreateEdiProjectRatingTableForm(props: any) {
  const classes = useStyles();
  const {
    isOpenEditPopup,
    setIsOpenEditPopup,
    editData,
    isEdit,
    handleCreateUpdateData,
  } = props;
  const renderError = renderErrorCreatePool;

  const {
    register,

    clearErrors,
    errors,
    handleSubmit,

    formState: { isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...editData,
    },
  });

  const submitData = (data:any) => {
    console.log('data rating', Object.values(data));
    
    const responseData = Object.values(data)
    handleCreateUpdateData && handleCreateUpdateData(responseData);
  };

  const handleSubmitPopup = () => {
    return handleSubmit(submitData)().then((res) => {
      console.log("Res: ", isValid, errors,res);
      if (isValid) {
        clearErrors();
      }
    });
  };



  return (
    <>
      <ConfirmDialog
        title={isEdit ? "Edit" : "Create"}
        open={isOpenEditPopup}
        confirmLoading={false}
        onConfirm={handleSubmitPopup}
        onCancel={() => {
          setIsOpenEditPopup(false);
          clearErrors();
        }}
        // form
      >
        {
          editData && editData.length > 0 && editData.map((_:any,index:number) => {
            
            const criteria = `criteria${index + 1}`
            const errorCriteria = errors &&  errors[criteria]
            return(
              <fieldset name={criteria} key={criteria} style={{display:"flex",alignItems:"center"}}>
                <div className={classes.formControl}>
                  <label className={classes.formControlLabel}>{`Criteria ${index + 1}`} <span style={{ color: '#e51d1d' }}>*</span></label>
                  <input
                    type="text"
                    name={`${criteria}.name`}
                    defaultValue={_.name}
                    ref={register({
                      required: true
                    })}
                    className={classes.formControlInput}
                    style={{padding:10,marginTop:0}}
                  />
                  <p className={classes.formErrorMessage}>
                    {
                      renderError(errorCriteria || {}, `name`)
                    }
                  </p>
                </div>

                <div className={classes.formControl} style={{marginLeft:16}}>
                  <label className={classes.formControlLabel}>Score <span style={{ color: '#e51d1d' }}>*</span></label>
                  <div>
                    <CurrencyInputWithValidate
                      initValue={_.score}
                      decimalsLimit={2}
                      register={register}
                      errors={errorCriteria || {}}
                      controlName={`${criteria}.score`}
                      customName="score"
                      className={classes.formControlInput}
                      placeholder="Please enter a number"
                      validateRule={{
                        required: true,
                        validate: {
                          lesserThan10: (value: any) => {   
                            return new BigNumber(value).gt(0) &&  new BigNumber(value).lt(11)
                          },
                        },
                      }}
                    />
                  </div>
                  {/* <p className={classes.formErrorMessage}>
                    {
                      renderError(errorCriteria || {}, `score`)
                    }
                  </p> */}
              </div>      
              </fieldset>
            )
          })
        }
      </ConfirmDialog>
    </>
  );
}

export default CreateEdiProjectRatingTableForm;
