import { BSC_CHAIN_ID, POLYGON_CHAIN_ID, KLAYTN_CHAIN_ID, NETWORK_AVAILABLE } from './../../constants';
import NoRewardTierPoolABI from '../../abi/Staking/PoolNoRewardABI.json';
import RewardPoolABI from '../../abi/Staking/RewardPool.json';
import PoolFactoryABI from '../../abi/PoolFactory.json';
import VestingABI from '../../abi/Vesting.json';
import TicketABI from '../../abi/Ticket.json';
import { getContractInstance } from '../../services/web3';

// import { USDT_ADDRESS, USDT_BSC_ADDRESS, USDC_ADDRESS, USDC_BSC_ADDRESS, ETH_CHAIN_ID, BUSD_BSC_ADDRESS } from '../../constants/network';
//
export const getUSDTAddress = (appChainID: string) => {
  //   return (appChainID === ETH_CHAIN_ID ? USDT_ADDRESS: USDT_BSC_ADDRESS) as string;
};
//


const getContractOwner = async (abi: any, tokenAddress: string | undefined) => {
  try {
    const connector = getContractInstance(abi, tokenAddress || '');
    if (connector) {
      const owner = await connector.methods.owner().call();
      return {owner : owner , address: tokenAddress};
    }
  } catch (err) {
    console.log({err})
    return {owner : "--" , address: "--"};
    // throw new Error('Token address is invalid.');
  }
};

export const getTierPoolAdress = (currentNetworkId:string | number) => {
  switch(currentNetworkId){
    case BSC_CHAIN_ID : 
      return process.env.REACT_APP_BSC_NO_REWARD_STAKING_POOL_ADDRESS || '';
    case POLYGON_CHAIN_ID : 
      return process.env.REACT_APP_POLYGON_NO_REWARD_STAKING_POOL_ADDRESS || ''
    case KLAYTN_CHAIN_ID : 
      return process.env.REACT_APP_KLAYTN_NO_REWARD_STAKING_POOL_ADDRESS || ''
    default: 
      return;
  }
}

export const getRewardPoolAdress = (currentNetworkId:string | number) => {
  switch(currentNetworkId){
    case BSC_CHAIN_ID : 
      return process.env.REACT_APP_BSC_REWARD_STAKING_POOL_ADDRESS || '';
    case POLYGON_CHAIN_ID : 
      return process.env.REACT_APP_POLYGON_REWARD_STAKING_POOL_ADDRESS || ''
    case KLAYTN_CHAIN_ID : 
      return process.env.REACT_APP_KLAYTN_REWARD_STAKING_POOL_ADDRESS || ''
  }
}

export const getFactoryPoolAdress = (currentNetworkId:string | number) => {
  switch(currentNetworkId){
    case BSC_CHAIN_ID : 
      return process.env.REACT_APP_SMART_CONTRACT_BSC_FACTORY_ADDRESS || '';
    case POLYGON_CHAIN_ID : 
      return process.env.REACT_APP_SMART_CONTRACT_POLYGON_FACTORY_ADDRESS || '';
    case KLAYTN_CHAIN_ID : 
      return process.env.REACT_APP_SMART_CONTRACT_KLAYTN_FACTORY_ADDRESS || '';
    default:
      return;
  }
}

export const getTicketAdress = (currentNetworkId:string | number) => {
  switch(currentNetworkId){
    case BSC_CHAIN_ID : 
      return process.env.REACT_APP_BSC_CONTRACT_NFT_TICKET_ADDRESS || '';
    case POLYGON_CHAIN_ID : 
      return process.env.REACT_APP_PLYGON_CONTRACT_NFT_TICKET_ADDRESS || ''
    case KLAYTN_CHAIN_ID : 
      return process.env.REACT_APP_KLAYTN_CONTRACT_NFT_TICKET_ADDRESS || ''
    default:
      return;
  }
}

export const getVestingAdress = (currentNetworkId:string | number) => {
  switch(currentNetworkId){
    case BSC_CHAIN_ID : 
      return process.env.REACT_APP_VESTING_CONTRACT_VESTING_ADDRESS || '';
    case POLYGON_CHAIN_ID : 
      return process.env.REACT_APP_POLYGON_CONTRACT_VESTING_ADDRESS || ''
    case KLAYTN_CHAIN_ID : 
      return process.env.REACT_APP_KLAYTN_CONTRACT_VESTING_ADDRESS || ''
    default: 
      return;
  }
}

export const getTierPoolOwner = (currentNetworkId:string | number) => {

  let NO_REWARD_ADDRESS = getTierPoolAdress(currentNetworkId)

  if (NO_REWARD_ADDRESS) {
    return getContractOwner(
      NoRewardTierPoolABI,
      NO_REWARD_ADDRESS,
    );
  } else {
    return;
  }
};

export const getRewardPoolOwner = (currentNetworkId:string | number) => {
  const REWARD_STAKING_ADDRESS = getRewardPoolAdress(currentNetworkId)
  return getContractOwner(RewardPoolABI, REWARD_STAKING_ADDRESS);
};

export const getFactoryPoolOwner = (currentNetworkId:string | number) => {
  const FACTORY_POOL_ADDRESS = getFactoryPoolAdress(currentNetworkId);
  console.log({FACTORY_POOL_ADDRESS})
  if (FACTORY_POOL_ADDRESS) {
    console.log({FACTORY_POOL_ADDRESS})
    return getContractOwner(
      PoolFactoryABI,
      FACTORY_POOL_ADDRESS,
    );
  } else {
    return;
  }
  
};

export const getTicketOwner = (currentNetworkId:string | number) => {
  const NFT_TICKET_ADDRESS = getTicketAdress(currentNetworkId)
  return getContractOwner(TicketABI, NFT_TICKET_ADDRESS);
};

export const getVestingOwner = (currentNetworkId:string | number) => {
  
  const VESTING_ADDRESS = getVestingAdress(currentNetworkId);
  if (VESTING_ADDRESS) {
    return getContractOwner(VestingABI,VESTING_ADDRESS);
  } else {
    return;
  }
};

export const getChainIdNetWork = (network: string) => {
  switch(network){
    case NETWORK_AVAILABLE.KLAYTN : 
      return process.env.REACT_APP_KLAYTN_NETWORK_ID || '';
    case NETWORK_AVAILABLE.BSC : 
      return process.env.REACT_APP_BSC_NETWORK_ID || '';
    case NETWORK_AVAILABLE.POLYGON : 
      return process.env.REACT_APP_POLYGON_NETWORK_ID || '';
    default:
      break;
  }
};

export const getNameNetWork = (network: string) => {
  switch(network){
    case NETWORK_AVAILABLE.KLAYTN : 
      return process.env.REACT_APP_KLAYTN_NETWORK_NAME || '';
    case NETWORK_AVAILABLE.BSC : 
      return process.env.REACT_APP_BSC_NETWORK_NAME || '';
    case NETWORK_AVAILABLE.POLYGON : 
      return process.env.REACT_APP_POLYGON_NETWORK_NAME || '';
    default:
      break;
  }
};
