import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ButtonLink from '../../../../../components/Base/ButtonLink';
import { STATUS_IDO } from '../../../../../constants';
import { useSocket } from '../../../../../hooks/useSocket';
import { getPoolDetail } from '../../../../../request/pool';
import { alertFailure } from '../../../../../store/actions/alert';
import { adminRoute } from '../../../../../utils';
import { convertFromJSON } from '../../../../../utils/convertTextJSON';
import { SocketEvent } from '../../../../../utils/types/socket';
import InfoV2 from '../../../InfoV2';
import NFTTicket from '../../../NFTTicket';
import ParticipantsIDO from '../../../Participants';
import useStyles from './styles';

const nav = [
  {
    id: 1,
    title: 'Info setting',
    part: 'info-ido',
    icon: 'icon-list-campaign',
    isParent: false,
  },
  {
    id: 2,
    title: 'Participants',
    part: 'participants-ido',
    icon: 'icon-users',
    isParent: false,
  },
  {
    id: 3,
    title: 'Tickets',
    part: 'ticket-ido',
    icon: 'icon-users',
    isParent: false,
  },
];

const LayoutIDODetailsV2 = () => {
  const styles = useStyles();
  const history = useHistory();
  const tabAsObject = Object.fromEntries(new URLSearchParams(history.location.search));
  const tabUrl = tabAsObject?.tab;
  const [IDOMenu, setIDOMenu] = useState<string>(tabUrl || 'info-ido');
  const [navLeft] = React.useState(nav);
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const [poolDetail, setPoolDetail] = useState<any>({});
  const [failure, setFailure] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const { socket, registerListener, unRegisterListener } = useSocket();
  const timeOutSnapShot = useRef<boolean>(false);
  const timeOutPoolOpen = useRef<boolean>(false);
  const timeOutTicketEnd = useRef<boolean>(false);

  const transformData = (arr: Array<any>, keyData: Array<string>, lang: string) => {
    const newArr = arr.map((item) => {
      let value = {} as any;
      keyData?.map((key: string) => {
        const parseData = JSON.parse(item[key]);
        value[key] = parseData[lang];
      });
      return {
        ...item,
        ...value,
      };
    });

    return newArr;
  };

  const fetchData = useCallback(() => {
    setLoading(true);
    getPoolDetail(id)
      .then(async (res) => {
        const data = res.data;
        const parseProjectName = JSON.parse(data.project_name);
        const parseProjectDescription = JSON.parse(data.project_description);
        // const parseDifferentiationCompetitors = JSON.parse(data.differentiation_from_competitors);
        const parseFAQ = JSON.parse(data.FAQ);

        const parseCategory = JSON.parse(data.category);
        const enData = {
          project_name: parseProjectName.en,
          project_description: parseProjectDescription.en,
          // differentiation_from_competitors: parseDifferentiationCompetitors.en,
          FAQ: parseFAQ.en,
          category: parseCategory.en,
          competior_benmark: transformData(data.competior_benmark, ['name'], 'en'),
          ratings: transformData(data.ratings, ['name'], 'en'),
          events: transformData(data.events, ['name', 'text'], 'en'),
        };
        const krData = {
          project_name: parseProjectName.kr,
          project_description: parseProjectDescription.kr,
          // differentiation_from_competitors: parseDifferentiationCompetitors.kr,
          FAQ: parseFAQ.kr,
          category: parseCategory.kr,
          competior_benmark: transformData(data.competior_benmark, ['name'], 'kr'),
          ratings: transformData(data.ratings, ['name'], 'kr'),
          events: transformData(data.events, ['name', 'text'], 'kr'),
        };

        const newData = {
          ...data,
          en: enData,
          kr: krData,
          start_staking_snapshot: data?.start_staking_snapshot
            ? moment(data?.start_staking_snapshot)
            : null,
          start_ticket_close: data?.start_ticket_close ? moment(data?.start_ticket_close) : null,
          start_ticket_open: data?.start_ticket_open ? moment(data?.start_ticket_open) : null,
          start_pool_end: data?.start_pool_end ? moment(data?.start_pool_end) : null,
        } as any;

        setPoolDetail(newData);
      })
      .catch((e) => {
        dispatch(alertFailure('Project not found!'));
        setFailure('Project not found!');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    socket
      .connect()
      .on('connect_error', () => {})
      .on('disconnect', () => {})
      .on('connect', () => {});
    return () => {
      socket.disconnect();
    };
  }, []);

  useLayoutEffect(() => {
    socket.emit('join-room', { token: localStorage.getItem('access_token'), room: 'admin' });
    registerListener(SocketEvent.AdminIDOChanged, async (data) => {
      if (Number(data?.ido_id) === Number(id)) {
        await fetchData();
      }
    });
    return () => {
      socket.off(SocketEvent.AdminIDOChanged);
    };
  }, []);

  useLayoutEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangeTab = (item: any) => {
    setIDOMenu(item.part);
    history.push({
      search: `?tab=${item.part}`,
    });
  };

  const validateTimeTicket = useMemo(() => {
    const poolOpen = moment(poolDetail?.start_ticket_open).unix();
    const currentDate = moment().unix();
    console.log({poolOpen, currentDate, check: poolOpen <= currentDate})
    return poolOpen <= currentDate;
  }, [poolDetail]);

  const validateTimeSnapshot = useMemo(() => {
    const poolStartSnapshot = moment(poolDetail?.start_staking_snapshot).unix();
    const currentDate = moment().unix();
    return poolStartSnapshot <= currentDate;
  }, [poolDetail]);

  useEffect(() => {
    const ticketClose = moment(poolDetail?.start_ticket_close).unix();
    const currentDate = moment().unix();
    const time = ticketClose - currentDate;

    if (!timeOutTicketEnd.current && time > 0) {
      let timeOut = setTimeout(() => {
        fetchData();
        timeOutTicketEnd.current = true;
      }, time * 1000);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [poolDetail, fetchData]);

  useEffect(() => {
    const poolStartSnapshot = moment(poolDetail?.start_staking_snapshot).unix();
    const currentDate = moment().unix();
    const time = poolStartSnapshot - currentDate;

    if (!timeOutSnapShot.current && time > 0) {
      let timeOut = setTimeout(() => {
        fetchData();
        timeOutSnapShot.current = true;
      }, time * 1000);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [poolDetail, fetchData]);

  useEffect(() => {
    const poolOpen = moment(poolDetail?.start_ticket_open).unix();
    const currentDateOpen = moment().unix();
    const time = poolOpen - currentDateOpen;
    if (!timeOutPoolOpen.current && time > 0) {
      let timeOut = setTimeout(() => {
        fetchData();
        timeOutPoolOpen.current = true;
      }, time * 1000);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [poolDetail, fetchData]);

  const itemNav = (item: any, index: any) => {
    return (
      <li
        key={index}
        onClick={() => handleChangeTab(item)}
        className={styles.itemNavLeft + ' ' + (IDOMenu === item.part && 'active')}
      >
        <span className={styles.linkItemNavLeft + ' ' + (IDOMenu === item.part && 'active')}>
          <i className={'icon ' + item.icon}></i>
          {item.title}
        </span>
      </li>
    );
  };

  const renderNav = (menu: any, index: any) => {
    if (menu.id !== 2 && menu.id !== 3) {
      return itemNav(menu, index);
      // } else if (menu.id === 2) {
    } else if (menu.id === 2 && validateTimeSnapshot && poolDetail?.is_deploy) {
      return itemNav(menu, index);
      // } else if (menu.id === 3) {
    } else if (
      menu.id === 3 &&
      validateTimeTicket &&
      poolDetail?.is_deploy &&
      (poolDetail.state === STATUS_IDO.SNAPSHOTTED || poolDetail.state === STATUS_IDO.CALCULATED || poolDetail.state === STATUS_IDO.ENDED)
    ) {
      return itemNav(menu, index);
    }
  };

  return (
    <div className={styles.IDOLayout}>
      <div className={styles.leftLayout}>
        {loading ? (
          <div className={styles.navLeft}>
            {[...Array(3)].map((num, index) => (
              <Skeleton key={index} className={styles.skeleton} width={'100%'} />
            ))}
          </div>
        ) : (
          <ul className={styles.navLeft}>{navLeft.map((item, index) => renderNav(item, index))}</ul>
        )}
      </div>
      <div className={styles.rightLayout}>
        <div className={styles.headerIDODetails}>
          <ButtonLink to={adminRoute('/campaigns')} text="Back" icon="icon-arrow-left.svg">
            <img className="icon-back" src="/images/icon-arrow-left.svg" alt="" />
            Back
          </ButtonLink>
          <div className={styles.titleIDO}>{convertFromJSON(poolDetail?.project_name) || '--'}</div>
        </div>
        {IDOMenu === 'info-ido' && poolDetail && (
          <InfoV2
            poolDetail={poolDetail}
            failure={failure}
            poolId={id}
            fetchPoolDetailData={fetchData}
          />
        )}
        {IDOMenu === 'participants-ido' && (
          <ParticipantsIDO idoID={id} poolDetail={poolDetail} onClickReCalculate={fetchData} />
        )}
        {IDOMenu === 'ticket-ido' && (
          <NFTTicket poolDetail={poolDetail} onClickConfirmModal={fetchData} />
        )}
      </div>
    </div>
  );
};
export default LayoutIDODetailsV2;
