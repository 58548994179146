import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import TicketDetail from './TicketDetail';
import Ticketing from './Ticketing';
import VestingPolicy from './VestingPolicy';

const NFTTicket = (props: any) => {
  const { poolDetail, onClickConfirmModal } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={3}>
          <Typography className={classes.titleField}>Ticket address</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{props.poolDetail?.ticket_address || '--'}</Typography>
        </Grid>
      </Grid>
      <Ticketing poolDetail={poolDetail} />
      <VestingPolicy poolDetail={poolDetail} />
      <TicketDetail poolDetail={poolDetail} confirmModal={onClickConfirmModal} />
    </Box>
  );
};

export default React.memo(NFTTicket);
