import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import useStyles from './style';

const Editor = (props: any) => {
  const classes = useStyles();
  const { title, name, poolDetail, register, setValue, errors, isViewDetail } = props;
  const defaultValue = '';
  const [description, setDescription] = useState<any>(defaultValue);
  let reactQuillRef = useRef<any>();

  useEffect(() => {
    if (poolDetail && poolDetail[name]) {
      setValue(name, poolDetail[name]);
      setDescription(poolDetail[name]);
    }
  }, [poolDetail, name, setValue]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['image'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'code-block',
  ];
  const handleProcedureContentChange = (e: any) => {
    setValue(name, e);
    setDescription(e);
  };

  const checkCharacterCount = (event: any) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() > 20000 && event.key !== 'Backspace') event.preventDefault();
  };

  return (
    <>
      <div className={classes.formCKEditor}>
        <label className={classes.formControlLabel}>{title}</label>
        <ReactQuill
          readOnly={isViewDetail}
          onKeyDown={checkCharacterCount}
          ref={reactQuillRef}
          value={description}
          placeholder="Maximun 20,000 character"
          theme={'snow'}
          onChange={handleProcedureContentChange}
          modules={modules}
          formats={formats}
          bounds={'.app'}
          className={classes.customQuill}
        />
      </div>
      <input type="hidden" name={name} ref={register()} maxLength={20000} />
    </>
  );
};

export default Editor;
