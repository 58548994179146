import { BigNumber } from 'bignumber.js';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { alertActions } from '../constants/alert';
import { createPoolLP } from '../../request/pool';

import poolNoReward from '../../abi/Staking/PoolNoRewardABI.json';
import { getContractInstance, getWeb3Instance } from '../../services/web3';
import { getTierPoolAdress } from '../../utils/contractAddress/getAddresses';
import { POLYGON_CHAIN_ID } from '../../constants';
import { getGasFeePolygon } from '../../utils/blockchain';
import Web3 from 'web3';

const deployPoolLP = (formData: any, successMessage: string, isCreate: boolean = true) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    const currentNetworkId = localStorage.getItem("NETWORK_ID");
    const TIER_ADDRESS = getTierPoolAdress(currentNetworkId || "");
    try {
      const { data } = formData;
      let stakingPoolContract = getContractInstance(poolNoReward, TIER_ADDRESS || '', true, 'write');

      if (!stakingPoolContract) {
        dispatch({
          type: alertActions.ERROR_MESSAGE,
          payload: 'Invalid staking pool address',
        });
        return;
      }

      const userWalletAddress = getState().userConnect.data;
      const transactionMethod = isCreate ? 'addExternalToken' : 'setExternalToken';


      let payload;  
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        const web3Instance = await getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        if (gasPrice) {
          payload = { 
            maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
            from: userWalletAddress
          }
        } else {
          payload = {
            from: userWalletAddress,
            gas: 8000000,
          }
        }
      } else {
          payload = {
              from: userWalletAddress,
              gas: 8000000,
          }
        } 

      await stakingPoolContract.methods[transactionMethod](
        data.token_address,
        data.status,
        new BigNumber(data.conversion_rate).multipliedBy(10 ** 18).toString(),
      )
        .send({
          ...payload
        })
        .on('transactionHash', async () => {
          if (isCreate) {
            await createPoolLP(data);
          }
        });

      // if (isCreate) {
      //   await createPoolLP(data);
      // }
      dispatch({
        type: alertActions.SUCCESS_MESSAGE,
        payload: successMessage,
      });
    } catch (err: any) {
      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });

      return err;
    }
  };
};

export const deployStakingPoolLP = (stakingPoolData: any) => {
  return deployPoolLP(stakingPoolData, 'Create Pool LP Successful!');
};

export const editStakingPoolLP = (stakingPoolData: any) => {
  return deployPoolLP(stakingPoolData, 'Update Pool LP Successful!', false);
};

export const changePoolLPStatus = (status: boolean) => {
  return deployPoolLP(
    {
      data: {
        token_address: process.env.REACT_APP_TOKEN_CODE_ADDRESS,
        status,
        conversion_rate: 1,
      },
    },
    'Update Pool Successful!',
    false,
  );
};
