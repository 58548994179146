import React, { useEffect, useState } from 'react';
import useStyles from '../style';
import FormControl from '@material-ui/core/FormControl';
// @ts-ignore
import CurrencyInput from 'react-currency-input-field';
import { fieldMustBeGreaterThanZero, renderErrorCreatePool } from '../../../utils/validate';
import BigNumber from 'bignumber.js';

function TotalCoinSold(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, poolDetail, isViewDetail, setPrice} = props;
  const [totalSoldCoin, setTotalSoldCoin] = useState('');
  const renderError = renderErrorCreatePool;

  const isDeployed = !!poolDetail?.is_deploy;

  useEffect(() => {
    if (poolDetail && poolDetail.amount) {
      setTotalSoldCoin(poolDetail.amount);
    }
  }, [poolDetail]);

  const handleChange = (value: any, name: any) => {
    setTotalSoldCoin(value);
    setPrice(value, 'amount');
    setValue('totalSoldCoin', value, { shouldValidate: true });
  };

  return (
    <div className={classes.formControl}>
      <FormControl component="fieldset">
        <label className={classes.formControlLabel}>
          Amount <span style={{ color: '#e51d1d' }}>*</span>
        </label>
        <CurrencyInput
          id="totalSoldCoin"
          placeholder="Please enter a number"
          value={totalSoldCoin || ''}
          decimalsLimit={2}
          onValueChange={handleChange}
          className={`${classes.formInputBox}`}
          disabled={isDeployed || isViewDetail}
        />
        <input
          type="hidden"
          name="totalSoldCoin"
          value={totalSoldCoin || ''}
          ref={register({
            required: true,
            validate: {
              totalSoldCoinGreaterThanZero: fieldMustBeGreaterThanZero,
            },
          })}
        />

        <p className={classes.formErrorMessage}>{renderError(errors, 'totalSoldCoin')}</p>
        <p className={classes.formErrorMessage}>
          {renderError(errors, 'totalSoldCoinGreaterThanZero')}
        </p>
      </FormControl>
    </div>
  );
}

export default TotalCoinSold;
