import { useForm } from 'react-hook-form';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { renderErrorCreatePool } from '../../../../utils/validate';
import useStyles from '../../style';
import CurrencyInputWithValidate from '../CurrencyInputWithValidate';

export interface Benchmark {
  name: string;
  ticker: string;
  market_cap: string;
  FDV: string;
  market_cap_description: string;
}
function CreateEditCompetitorBenchmarkForm(props: any) {
  const classes = useStyles();
  const { isOpenEditPopup, setIsOpenEditPopup, editData, isEdit, handleCreateUpdateData } = props;
  const renderError = renderErrorCreatePool;

  const {
    register,
    clearErrors,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...editData,
    },
  });

  const submitData = (data: Benchmark) => {
    const responseData = {
      name: data.name,
      ticker: data.ticker,
      market_cap: data.market_cap,
      FDV: data.FDV || '',
      market_cap_description: data.market_cap_description,
    };
    handleCreateUpdateData && handleCreateUpdateData(responseData);
  };

  const handleSubmitPopup = () => {
    return handleSubmit(submitData)().then((res) => {
      console.log('Res: ', isValid, errors);
      if (isValid) {
        clearErrors();
      }
    });
  };

  return (
    <>
      <ConfirmDialog
        title={isEdit ? 'Edit' : 'Create'}
        open={isOpenEditPopup}
        confirmLoading={false}
        onConfirm={handleSubmitPopup}
        onCancel={() => {
          setIsOpenEditPopup(false);
          clearErrors();
        }}
        // btnLoading={true}
      >
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>
            Name <span style={{ color: '#e51d1d' }}>*</span>
          </label>
          <div className={classes.formControlRate}>
            <input
              type="text"
              name="name"
              placeholder="Please enter a text"
              defaultValue={editData.name}
              ref={register({ required: true })}
              maxLength={100}
              className={classes.formControlInput}
            />
          </div>
          <p className={classes.formErrorMessage}>{renderError(errors, 'name')}</p>
        </div>

        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>
            Ticker <span style={{ color: '#e51d1d' }}>*</span>
          </label>
          <div className={classes.formControlRate}>
            <input
              type="text"
              name="ticker"
              placeholder="Please enter a text"
              defaultValue={editData.ticket}
              ref={register({ required: true })}
              maxLength={15}
              className={classes.formControlInput}
            />
          </div>
          <p className={classes.formErrorMessage}>{renderError(errors, 'ticker')}</p>
        </div>
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>
            Compare to Competitor's market cap <span style={{ color: '#e51d1d' }}>*</span>
          </label>
          <div className={classes.formControlRate}>
            <input
              type="text"
              name="market_cap_description"
              defaultValue={editData.market_cap_description}
              ref={register({ required: true })}
              placeholder="Please enter a text"
              className={classes.formControlInput}
            />
          </div>
          <p className={classes.formErrorMessage}>
            {renderError(errors, 'market_cap_description')}
          </p>
        </div>
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>
            Market Cap <span style={{ color: '#e51d1d' }}>*</span>
          </label>
          <div className={classes.formControlRate}>
            <CurrencyInputWithValidate
              register={register}
              errors={errors}
              initValue={editData.market_cap}
              controlName={'market_cap'}
              validateRule={{
                required: true,
              }}
            />
          </div>

          {/* <p className={classes.formErrorMessage}>
              {
                renderError(errors, 'market_cap')
              }
            </p> */}
        </div>
        {/* <div className={classes.formControl}>
          <label className={classes.formControlLabel}>
            Max Supply <span style={{ color: '#e51d1d' }}>*</span>
          </label>
          <div className={classes.formControlRate}>
            <CurrencyInputWithValidate
              register={register}
              errors={errors}
              initValue={editData.ticker}
              controlName={'FDV'}
              validateRule={{
                required: true,
              }}
            />
          </div>
        </div> */}
      </ConfirmDialog>
    </>
  );
}

export default CreateEditCompetitorBenchmarkForm;
