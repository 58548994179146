import { Button, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import { ROLE_ADMIN } from '../../../constants';
import useStyles from '../StakingPoolReward.style';
import {getTimeDuration} from '../../../utils/timeLock'
import usePermission from '../../../hooks/usePermission';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#464646',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

interface withdrawFee {
  aNumber:any, 
  bNumber:any, 
  cNumber:any, 
  withDrawFeeTime : any, 
}

type ComponentProps = {
  // stakingPools: any;
  withdrawFee: withdrawFee;
  openEditModal?: (modal: string) => void;
  openEditDrawPeriodLimitModal?:() => void;
  isPolygon?: boolean
};

const WithDrawFeeSetting: React.FC<any> = ({
  withdrawFee,
  openEditModal,
  openEditDrawPeriodLimitModal,
  isPolygon
}: ComponentProps) => {
  const classes = useStyles();
  
  const isPermission = usePermission([ROLE_ADMIN.ADMIN_POOL_NO_REWARD, ROLE_ADMIN.SUPPER_ADMIN])
  const {aNumber, bNumber, cNumber, withDrawFeeTime} = withdrawFee

  const renderRow = (title: string, child: any, canEdit = true) => {
    return (
      <TableRow>
        <TableCell>{title}</TableCell>
        <TableCell align="left">{child}</TableCell>
        <TableCell align="center">
          {canEdit && title === '% Fee' &&(
            <Button
              variant="contained"
              color="primary"
              disabled={!isPermission || !isPolygon}
              onClick={() => openEditModal && openEditModal(title)}
            >
              <EditIcon />
            </Button>
          )}

          {canEdit && title === 'No withdrawal fee after' &&(
            <Button
              variant="contained"
              color="primary"
              disabled={!isPermission || !isPolygon}
              onClick={() => openEditDrawPeriodLimitModal && openEditDrawPeriodLimitModal()}
            >
              <EditIcon />
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  };
  return (
    <section className={classes.section}>
      <TableContainer component={Paper} style={{maxWidth:1240}}>
        <Table aria-label="simple table">
        <TableHead>
          <TableRow>
              <StyledTableCell>Withdrawal fee policy</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow(
              '% Fee',
              <span>= 
                <b> {aNumber / 10**6}</b>*x^2 - <b>{bNumber / 10**6}</b>*x + <b>{cNumber / 10**6}</b>
              </span>,
            )}
            {renderRow(
              'Formular',
              <span>
                *Fee computation based on this formula: 
                  y = <b> a</b>*x^2 - <b>b</b>*x + <b>c </b>
                  <br/>
                  In which: 
                  <b>x </b> = user stake period <b> a,b,c </b> = editable coefficients provided by admin
              </span>,
              false,
            )}
            {renderRow(
              'No withdrawal fee after',
              getTimeDuration(Number(withDrawFeeTime)),
            )}
      
          </TableBody>
        </Table>
      </TableContainer>
          
    </section>
  );
};

export default WithDrawFeeSetting;
