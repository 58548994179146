import React from 'react';
import useStyles from "../style";
import {renderErrorCreatePool} from "../../../utils/validate";

function PoolName(props: any) {
  const classes = useStyles();
  const {
    register, errors,
    poolDetail, isViewDetail
  } = props;
  const renderError = renderErrorCreatePool;
  // const isDeployed = !!poolDetail?.is_deploy;

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Project Name <span style={{color:'#e51d1d'}}>*</span></label>
        <input
          type="text"
          name="project_name"
          placeholder="Enter Project Name"
          defaultValue={poolDetail?.project_name}
          ref={register({ required: true })}
          maxLength={255}
          className={classes.formControlInput}
          disabled={isViewDetail}
        />
        <p className={classes.formErrorMessage}>
          {
            renderError(errors, 'project_name')
          }
        </p>
      </div>
    </>
  );
}

export default PoolName;
