import React, { useState } from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import {adminRoute} from "../../utils";
import PoolForm from "./PoolForm";
import BackButton from "../../components/Base/ButtonLink/BackButton";
import moment from 'moment';
import { DATETIME_FORMAT } from '../../constants';
import WrapPoolForm from './WrapPoolForm';

const PoolCreate: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const __dev__ = process.env.REACT_APP_ENVIROMENT
  const [isFill, setIsFill] = useState(false);
  const fillData = () => setIsFill(true);

  const poolFillForm = {
    id: 31,
    project_name: "Monsterra IDO",
    contract_address: null,
    // network: "bsc",
    accept_currency: "usdc",
    token_address: "0xb0d9A08452334C6bd5EB430C9778D24d745798a0",
    token_symbol: "SBX",
    amount: 50000,
    price: 10000,
    cover_photo: "https://toigingiuvedep.vn/wp-content/uploads/2021/02/hinh-anh-hinh-nen-hoa-dao-dep-nhat-2.jpg",
    token_uri:"https://images.pexels.com/",
    project_description: `Epic War is a 3D Blockchain Cinematic Game that is free-to-play-to-earn. It is a massively multiplayer real-time strategy game (MMORTS) and social network based on Blockchain technology.
    Epic War offers gamers the thrill of first-person shooter action in a cutting-edge gaming environment. Scavenging settings, hundreds of mutant monsters, and a frantic in-game economy are among the game’s highlights.
    Players can design their own personalized avatar, weapon, gear or battle map. They could form alliances for game guilds and then destroy AI-driven monsters or other teams on the battlefield.
    Game Features:
    • 3D Blockchain Cinematic Game and Collectibles: Game + Comic Book + Film + NFT
    • Metaverse Co-Op RPG on multi-devices: PC/Mobile gameplay and VR gameplay
    • Free-to-play-to-earn model
    • Gaming DAO: Fund DAO + NFT DAO
    • Open World Gameplay`,
    differentiation_from_competitors: "",
    merkletree_root: null,
    FAQ: "",
    social_twitter: "social_twitter",
    social_discord: "social_discord",
    social_telegram: "social_telegram",
    ticket_address: null,
    conversion_rate: 100,
    starter_distribution: 420,
    starter_min_buy: 0,
    starter_max_buy: 1000,
    rookie_min_buy: 0,
    rookie_max_buy: 1000,
    rookie_distribution: 30,
    legend_distribution: 50,
    TGE: 10,
    // linear_vesting: 4,
    start_staking_snapshot:  moment.unix(Date.now()/1000 + (60*60)).format(DATETIME_FORMAT),
    start_ticket_open:moment.unix(Date.now()/1000 + (24*60*60)).format(DATETIME_FORMAT),
    start_ticket_close: moment.unix(Date.now()/1000 + (48*60*60)).format(DATETIME_FORMAT),
    start_pool_end: moment.unix(Date.now()/1000 + (72*60*60)).format(DATETIME_FORMAT),
    is_display: false,
    is_deploy: false,
    category: "[\"Play-and-Earn\"]",
    competior_benmark: [
      { name: "competior_benmark 1", ticker: "competior_benmark ", market_cap: 100, FDV: 100,},
      {name: "competior_benmark 2", ticker: "competior_benmark ", market_cap: 200, FDV: 200},
      { name: "competior_benmark 3", ticker: "competior_benmark ", market_cap: 300, FDV: 30},
    ],
    ratings: [
        {
          name: "RATING 1",
          score: 1000,
        },
        {
          name: "RATING 2",
          score: 2000,
        },
        {
          name: "RATING 3",
          score: 3000,
        },
        {
          name: "RATING 4",
          score: 4000,
        },
        {
          name: "RATING 5",
          score: 5000,
        },
        {
          name: "RATING 6",
          score: 6000,
        },
    ],
    "sns": [
     { url:"https://images.pexels.com/photos/4994765/pexels-photo-4994765.png?auto=compress&cs=tinysrgb&w=1600"},
     { url:"https://images.pexels.com/photos/4994765/pexels-photo-4994765.png?auto=compress&cs=tinysrgb&w=1600"},
     { url:"https://images.pexels.com/photos/4994765/pexels-photo-4994765.png?auto=compress&cs=tinysrgb&w=1600"},
    ],
    "events": [
      {text: "Lorem Ipsum", url: "dsda"},
      {text: "Lorem Ipsum", url: "dsda"},
      {text: "Lorem Ipsum", url: "dsda"}
    ]
}

  return (
    <DefaultLayout>
      <BackButton to={adminRoute('/campaigns')}/>
      {__dev__ === "DEV" ? <button onClick={fillData}>Fill data</button> : ""}
      <WrapPoolForm
        isEdit={false}
        poolDetail={isFill ? poolFillForm : undefined}
      />
    </DefaultLayout>
  )
}

export default withRouter(PoolCreate);
