import { useForm } from 'react-hook-form';
import useStyles from './style';

import { withRouter } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import PoolDescription from './Components/PoolDescription';
import PoolName from './Components/PoolName';
import ProjectCategory from './Components/ProjectCategory';
import ProjectEvent from './Components/ProjectEvent';

import { useEffect } from 'react';
import CompetitorBenchmarkTable from './Components/Benchmark/CompetitorBenchmarkTable';
import ProjectRatingTable from './Components/ProjectRating/ProjectRatingTable';
import Editor from '../../components/Base/Editor';

function PoolFormDynamic(props: any) {
  const classes = useStyles();

  const { poolDetail, isViewDetail = false, key, refForm, lang } = props;

  const form = useForm({
    mode: 'onChange',
    defaultValues: poolDetail,
    reValidateMode: 'onChange',
  });
  const { register, setValue, errors, control, watch } = form;

  useEffect(() => {
    if (refForm) refForm(form);
  }, []);

  return (
    <div className="" key={key}>
      <div className={classes.exchangeRate}>
        <Typography className={classes.exchangeRateTitle}>Pool info</Typography>

        <PoolName
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          errors={errors}
          isViewDetail={isViewDetail}
        />

        {/* <PoolDescription
          title="Summary"
          name="project_description"
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          errors={errors}
          isViewDetail={isViewDetail}
        />
        <PoolDescription
          title="FAQ"
          name="FAQ"
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          errors={errors}
          isViewDetail={isViewDetail}
        /> */}
        <Editor
          title="Summary"
          name="project_description"
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          errors={errors}
          isViewDetail={isViewDetail}
        />
        <br />
        <Editor
          title="FAQ"
          name="FAQ"
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          errors={errors}
          isViewDetail={isViewDetail}
        />

        <ProjectEvent
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          errors={errors}
          isViewDetail={isViewDetail}
        />

        <ProjectCategory
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          errors={errors}
          isViewDetail={isViewDetail}
        />

        <CompetitorBenchmarkTable
          poolDetail={poolDetail}
          setValue={setValue}
          register={register}
          watch={watch}
          errors={errors}
          control={control}
          isViewDetail={isViewDetail}
        />

        <ProjectRatingTable
          poolDetail={poolDetail}
          setValue={setValue}
          register={register}
          watch={watch}
          errors={errors}
          control={control}
          isViewDetail={isViewDetail}
        />
      </div>
    </div>
  );
}

export default withRouter(PoolFormDynamic);
