import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Button from '@material-ui/core/Button';
import useStyles from './StakeItem.style';
import { POOL_STAKE_LP_STATUS } from '../../../constants';
import { etherscanRoute } from '../../../utils';
import BigNumber from 'bignumber.js';
import { Grid } from '@material-ui/core';

interface ComponentProps {
  tokenAddress: string;
  totalStake: number;
  totalParticipant: number;
  conversionRate: number;
  tokenSymbol: string;
  contractAddress: string;
  image: string;
  status: string;
  isPermission?: boolean;
  isPolygon?: boolean;
  onEditToken: (p: any) => void;
}

const StakeLPItem: React.FC<any> = ({
  totalStake,
  totalParticipant,
  conversionRate,
  contractAddress,
  image,
  status,
  tokenSymbol,
  tokenAddress,
  onEditToken,
  isPermission,
  isPolygon
}: ComponentProps) => {
  const classes = useStyles();
  const changeTokenStatus = () => {
    onEditToken({
      token_address: tokenAddress,
      status: status === POOL_STAKE_LP_STATUS.PAUSE,
      conversion_rate: conversionRate,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.stakeHeader}>
        <p className={classes.nameDiv}>
          <span
            className={classes.roundTag}
            style={{
              backgroundImage: `url(${image})`,
            }}
          ></span>
          <span> {tokenSymbol} </span>
        </p>
        <div>
          <p>
            <a href={etherscanRoute(tokenAddress, { network: 'polygon'})} target="_blank" rel="noreferrer">
              View contract <OpenInNewIcon style={{ fontSize: '1rem' }} />
            </a>
          </p>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <span style={{fontWeight:700}}> Total Staked</span>
        </Grid>
        <Grid item xs={3}>
          <span style={{fontWeight:700}}> Participants</span>
        </Grid>
        <Grid item xs={3}>
          <span style={{fontWeight:700}}> Conversion rate</span>
        </Grid>
        <Grid item xs={3}>
          <span>
            <div className={classes.pauseButtonToken}>
              <Button
                variant="outlined"
                className={classes.pauseButton}
                onClick={changeTokenStatus}
                disabled={!isPermission || !isPolygon}
              >
                {status === POOL_STAKE_LP_STATUS.PAUSE ? 'Enable token' : 'Pause token'}
              </Button>
            </div>
          </span>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <span>
            {' '}
            {totalStake} {tokenSymbol}
          </span>
        </Grid>
        <Grid item xs={3}>
          <span> {totalParticipant}</span>
        </Grid>
        <Grid item xs={3}>
          <span>
            {' '}
            1 CODE = {conversionRate} {tokenSymbol}
          </span>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </div>
  );
};

export default StakeLPItem;
