import BigNumber from "bignumber.js";

export const renderError = (errors: any, prop: string) => {
  if (errors[prop]) {
    const errorName = prop.split("_").join(' ');
    const errorType = errors[prop].type;

    switch (errorType) {
      case 'required': {
        return 'This field is required';
      }
      case 'greaterOrEqualToday': {
        return `The ${errorName} must be after current date.`;
      }
      case 'greateOrEqualOpenTicket': {
        return 'This ticket close must be after the ticket open';
      }
      case 'greateOrEqualCloseTicket': {
        return 'This end pool time must be after the ticket close';
      }
      case 'validAddress': {
        return "Address receive is invalid.";
      }
      case 'invalidToken': {
        return errors[prop].message;
      }
      case 'tokenAlreadyUsed': {
        return 'Token address is already in use.';
      }
    };
  }

  return;
};

export const renderErrorCreatePool = (errors: any, prop: string) => {

  if (errors[prop]) {
    const errorName = prop.split("_").join(' ');
    const errorType = errors[prop].type;
    switch (errorType) {
      case 'required': {
        return 'This field is required';
      }
      case 'greateOrEqualStakingSnapshot': {
        return 'This end join pool time must be after the staking snapshot time';
      }
      case 'greaterOrEqualEndJoinPoolTime': {
        return 'This release time must be after the finish time';
      }

      case 'greaterOrEqualToday': {
        return `The ${errorName} must be after current date.`;
      }
      case 'greateOrEqualOpenTicket': {
        return 'This ticket close must be after the ticket open';
      }
      case 'greateOrEqualCloseTicket': {
        return 'This end pool time must be after the ticket close';
      }

      case 'greaterThanZero': {
        return 'This field must be greater than 0';
      }
      case 'lesserThan10': {
        return 'This field must be lesser than 10';
      }
      case 'totalSoldCoinGreaterThanZero': {
        return 'Total Sold Coin must be greater than 0.';
      }
      case 'maxBuyGreaterThanZero': {
        return 'Max Buy must be greater than 0.';
      }
      case 'minBuyGreaterMaxBuy': {
        return 'Min Buy must be less than Max Buy.';
      }

      case 'minBuyGreaterMaxBuyStarter': {
        return 'Must be an integer not larger than Starter max ';
      }
      case 'maxBuyLessersMaxBuyStarter': {
        return 'Must be an integer not smaller than Starter min ';
      }

      case 'minBuyGreaterMaxBuyRookie': {
        return 'Must be an integer not larger than Rookie max ';
      }
      case 'maxBuyLessersMaxBuyRookie': {
        return 'Must be an integer not smaller than Rookie min ';
      }


      case 'validAddress': {
        return "Address receive is invalid.";
      }
      case 'invalidToken': {
        return errors[prop].message;
      }
      case 'tokenAlreadyUsed': {
        return 'Token address is already in use.';
      }
      case 'networkNotMatch': {
        return 'Your selected network can\'t deploy. Please switch network in MetaMask or change this option';
      } 

      case 'pattern': {
        return errors[prop].message;
      }

      case 'largeThan100':{
        return 'This field must less than 100'
      }
    };
  }

  return;
};

export const fieldMustBeGreaterThanZero = (value: any) => new BigNumber(value).gt(0);
export const fieldMustBeLessThan100 = (value: any) => new BigNumber(value).lt(101);

