const styles = {
  sectionTitle: {
    fontSize: '1.5rem',
    lineHeight: '1.25',
  },
  sectionStyle: {
    marginTop: '4rem',
    fontSize: '1rem',
  },
  flexLayout: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialog: {
    '& .MuiPaper-root': {
      padding: '40px 70px',
      maxWidth: 900,
      borderRadius: 20,
    },
  },
  modalTitle: {
    fontSize: '1.5rem',
    lineHeight: '1.25',
  },
};

export default styles;
