import _clone from 'lodash/clone';
import _toPair from 'lodash/toPairs';

import { ChangeEvent, FormEvent, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from './SetupForm.style';

interface SetupForm {
  starter: number | undefined;
  rookie: number | undefined;
  legend: number | undefined;
}

interface ErrorMgs {
  starter: string;
  rookie: string;
  legend: string;
}

type ComponentProps = {
  form?: SetupForm | undefined;
  onSubmit: (data: SetupForm) => void;
  onClose: () => void;
  errorMessageList: ErrorMgs;
};

const defaultForm = {
  starter: undefined,
  rookie: undefined,
  legend: undefined,
};

const defaultError = {
  starter: false,
  rookie: false,
  legend: false,
};

const SetupTierForm: React.FC<ComponentProps> = ({
  form,
  onSubmit,
  errorMessageList,
  onClose,
}: ComponentProps) => {
  const classes = useStyles();
  const [formFields, setFormFields] = useState<SetupForm>(
    form ? _clone(form) : _clone(defaultForm),
  );
  const [errors, setErrors] = useState({
    starter: false,
    rookie: false,
    legend: false,
  });

  const validators: {
    [key: string]: (n: number) => Promise<void>;
  } = {
    starter: (value: number) => {
      if (
        value < 0 ||
        (value !== 0 && !value) // && formFields.legend && formFields.rookie && value < Number(formFields.rookie) && value < Number(formFields.legend)
      ) {
        return Promise.reject('starter');
      }

      return Promise.resolve();
    },
    rookie: (value: number) => {
      if (
        !value ||
        (value && formFields.starter && value < Number(formFields.starter)) //  && formFields.legend && value < Number(formFields.legend)
      ) {
        return Promise.reject('rookie');
      }

      return Promise.resolve();
    },
    legend: (value: number) => {
      if (!value || (value && formFields.rookie && value < Number(formFields.rookie))) {
        return Promise.reject('legend');
      }

      return Promise.resolve();
    },
  };

  const onStaterChange = async (e: any) => {
    const value = e.target.value.replaceAll(',', '');
    setFormFields({ ...formFields, starter: value });
    try {
      await validators.starter(value.trim() === '' ? NaN : Number(value));
      setErrors({ ...errors, starter: false });
    } catch (error) {
      setErrors({ ...errors, starter: true });
    }
  };

  const onRookieChange = async (e: any) => {
    const value = e.target.value.replaceAll(',', '');
    setFormFields({ ...formFields, rookie: value });
    try {
      await validators.rookie(Number(value));
      setErrors({ ...errors, rookie: false });
    } catch (error) {
      setErrors({ ...errors, rookie: true });
    }
  };

  const onLegendChange = async (e: any) => {
    const value = e.target.value.replaceAll(',', '');
    setFormFields({ ...formFields, legend: value });

    try {
      await validators.legend(value);
      setErrors({ ...errors, legend: false });
    } catch (error) {
      setErrors({ ...errors, legend: true });
    }
  };

  const formSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const validationStates = await Promise.allSettled(
        _toPair(formFields).map((entries: [string, any]) =>
          validators[entries[0]](Number(entries[1])),
        ),
      );

      const errorKeys = validationStates
        .filter((state) => state.status === 'rejected')
        .map((state: any) => state.reason);
      if (errorKeys.length > 0) {
        throw errorKeys;
      }

      setErrors(_clone(defaultError));
      onSubmit(formFields);
    } catch (errorKeys: any) {
      const errorKeyList: {
        [key: string]: any;
      } = {};
      errorKeys.forEach((key: string) => {
        errorKeyList[key] = true;
      });
      setErrors({ ...errors, ...errorKeyList });
    }
  };

  return (
    <form onSubmit={formSubmitHandler}>
      <table>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>Starter </td>
            <td>
              <TextField
                variant="outlined"
                id="starter-input"
                error={errors.starter}
                helperText={errorMessageList.starter}
                type="number"
                value={formFields.starter}
                onChange={onStaterChange}
                size="small"
                classes={{
                  root: classes.formInput,
                }}
                disabled
                fullWidth
              />
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>Super Rookie </td>
            <td>
              <TextField
                variant="outlined"
                id="rookie-input"
                error={errors.rookie}
                helperText={errorMessageList.rookie}
                type="number"
                value={formFields.rookie}
                onChange={onRookieChange}
                size="small"
                classes={{
                  root: classes.formInput,
                }}
                fullWidth
              />
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>Legend</td>
            <td>
              <TextField
                variant="outlined"
                id="legend-input"
                error={errors.legend}
                helperText={errorMessageList.legend}
                type="number"
                value={formFields.legend}
                onChange={onLegendChange}
                size="small"
                classes={{
                  root: classes.formInput,
                }}
                fullWidth
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className={classes.btns}>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Update
        </Button>
      </div>
    </form>
  );
};

export default SetupTierForm;
