import { combineReducers } from 'redux';
import {
  campaignsReducer,
  campaignCreateReducer,
  campaignDetailReducer,
  campaignICORegisterReducer,
  campaignAffiliateCreateReducer,
  campaignErc20RateSetReducer,
  campaignLatestReducer,
  campaignEditReducer,
  campaignStatusToggleReducer,
  campaignRefundTokensReducer,
  campaignProcessingReducer,
  campaignLatestActiveReducer,
} from './campaign';
import { alertReducer } from './alert';
import userReducer, {
  userConnectReducer,
  userRegisterReducer,
  investorReducer,
  investorRegisterReducer,
  userProfileReducer,
  userProfileUpdateReducer,
  userCurrentNetwork,
} from './user';
import { settingOwnerReducer } from './setting-owner';
import { balanceReducer } from './balance';

const rootReducer = combineReducers({
  user: userReducer,
  investor: investorReducer,
  investorRegister: investorRegisterReducer,
  userConnect: userConnectReducer,
  userCurrentNetwork: userCurrentNetwork,
  userRegister: userRegisterReducer,
  userProfile: userProfileReducer,
  userProfileUpdate: userProfileUpdateReducer,
  campaigns: campaignsReducer,
  campaignProcessing: campaignProcessingReducer,
  campaignCreate: campaignCreateReducer,
  campaignEdit: campaignEditReducer,
  campaignDetail: campaignDetailReducer,
  campaignICORegister: campaignICORegisterReducer,
  campaignAffiliateCreate: campaignAffiliateCreateReducer,
  campaignErc20RateSet: campaignErc20RateSetReducer,
  campaignLatest: campaignLatestReducer,
  campaignLatestActive: campaignLatestActiveReducer,
  campaignStatusToggle: campaignStatusToggleReducer,
  campaignRefundTokens: campaignRefundTokensReducer,
  settingOwner: settingOwnerReducer,
  balance: balanceReducer,
  alert: alertReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
