import { makeStyles } from '@material-ui/core';
import commonStyles from '../style.common';

const useStyles = makeStyles((theme) => ({
  sectionTitle: { ...commonStyles.sectionTitle, marginBottom: '1.5rem' },
  section: { ...commonStyles.sectionStyle },
  flexBetween: {
    ...commonStyles.flexLayout,
    alignItems: 'flex-start',
  },
  createPoolBtn: {
    cursor: 'pointer',
  },
  dialog: {
    ...commonStyles.dialog,
  },
  modalTitle: {
    ...commonStyles.modalTitle,
    textAlign: 'center',
  },
  modalSubTitle: {
    textAlign: 'center',
  },
  addLPStake: {
    marginRight: 0
  }
}));

export default useStyles;
