import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    leftLayout: {
      minWidth: '316px',
      minHeight: '100vh',
      background: '#202020',
      color: '#8D8D8D',
      padding: '20px 14px',
      position: 'relative',
    },
    navLeft: {
      width: '100%',
      marginTop: 80,
    },
    itemNavLeft: {
      width: '100%',
      marginBottom: 5,
    },
    linkItemNavLeft: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 15,
      minHeight: 74,
      padding: 19,
      fontSize: 16,
      lineHeight: '20px',
      color: '#8D8D8D',
      transition: '0.3s',

      '&.active': {
        background: 'rgb(255 255 255 / 10%)',
        color: '#FFCC00',

        '& .icon': {
          backgroundColor: '#FFCC00',
        },
      },

      '&:hover': {
        background: 'rgb(255 255 255 / 10%)',
        color: '#FFFFFF',
        transition: '0.3s',

        '& .icon': {
          backgroundColor: '#FFFFFF',
        },
      },

      '& .icon': {
        width: 30,
        height: 30,
        maskImage: `url('/images/icon_nav_left/icon-home.svg')`,
        backgroundColor: '#9A9A9A',
        maskPosition: 'center',
        maskRepeat: 'no-repeat',
        marginRight: 19,

        '&.icon-small': {
          margin: 'auto',
        },

        '&.icon-home': {
          maskImage: `url('/images/icon_nav_left/icon-home.svg')`,
        },

        '&.icon-list-campaign': {
          maskImage: `url('/images/icon_nav_left/icon-list-campaign.svg')`,
        },

        '&.icon-history': {
          maskImage: `url('/images/icon_nav_left/icon-history.svg')`,
        },

        '&.icon-setting': {
          maskImage: `url('/images/icon_nav_left/icon-setting.svg')`,
        },
      },
    },
    rightLayout: {
      width: '100%',
      padding: 30,
      backgroundColor: '#F9F9F9',
    },
    IDOLayout: {
      display: 'flex',
    },
    headerIDODetails: {
      display: 'flex',
    },
    titleIDO: {
      width: '100%',
      marginRight: 90,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 20,
      fontWeight: 500,
    },
    skeleton: {
      padding: '50px 0px',
      borderRadius: 15,
      background: 'rgb(255 255 255 / 10%)',
      '&:first-child': {
        marginTop: -20,
      },
    },
  };
});

export default useStyles;
