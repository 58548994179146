import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerRight: {
    display: 'flex',
  },
  searchSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
  },
  sectionStyle: {
    marginTop: '1rem',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    lineHeight: '1.25',
    fontWeight: 700,
    textAlign: 'center',
  },
  tableContainer: {
    padding: '30px 20px',
    borderRadius: 10,
    boxShadow: 'none',
    marginTop: 25,
  },
  table: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 14,
    backgroundColor: 'white',
  },
  tableHeader: {
    padding: '0px 0px 25px 30px',
    color: '#363636',
    fontWeight: 600,
  },
  tableBody: {
    '& > .MuiTableRow-root:last-child': {
      border: '1px solid #E5E5E5',
    },
    '& > .MuiTableRow-root:nth-child(odd)': {
      backgroundColor: 'white',
    },
  },
  skeleton: {
    padding: '25px 0px',
    marginTop: 10,
  },
  noDataMessage: {
    fontWeight: 500,
    marginTop: 30,
    textAlign: 'center',
    fontSize: 15,
  },
  errorMessage: {
    fontWeight: 500,
    marginTop: 30,
    textAlign: 'center',
    fontSize: 15,
    color: 'red',
  },
  commonButton: { textTransform: 'none' },
}));

export default useStyles;
