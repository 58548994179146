import BigNumber from 'bignumber.js'

export const SECONDS_PER_DAY = 60 * 60 * 24;
export const HOURS_PER_DAY = 60 * 60;

export const getValueLockDuration = (lockDuration :any) => {
  const standardUnit = (value:any, unit:any) => {
    console.log('unit',unit,value);
    
    return value > 1 ? `${value} ${unit}s` : `${value} ${unit}`;
  }

  const units = [
    { value: 60 * 60 * 24, unit: 'day' },
    { value: 60 * 60, unit: 'hour' },
    { value: 60, unit: 'minute' },
  ]

  for (let i = 0; i < units.length; i++) {
    const record = units[i];

    const objNumber = new BigNumber(lockDuration).div(record.value);
    const time = objNumber.integerValue(BigNumber.ROUND_CEIL);
    console.log('standardUnit',objNumber.gte(1));
    
    if (objNumber.gte(1)) {
      return standardUnit(time, record.unit)
    }
  }
  return '0 day';
}


export const getTimeDuration = (unixTimestamp:number) => {

  if(unixTimestamp <= 0) return '0 day';
  let date = Math.abs(unixTimestamp);

  let hours = Number((date / (60 * 60)).toFixed(1));

  const days = Math.floor(hours / 24);
  hours = hours - (days * 24);

  // console.log('days',days,hours,date);
  
  const dayDisplay = days > 1 ? `${days} days` : days === 0 ? '' : `${days} day`
  const hoursDisplay = hours > 1 ? `${hours} hours` : hours === 0 ? '' : `${hours} hour`

  
  // 👇️ Format as hh:mm:ss
  const time = ((days === 0 && hours === 0) ||( isNaN(days) && isNaN(hours)) ) ? '0 days ':`${dayDisplay} ${hoursDisplay}`;
  return time
}