import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    fontWeight: 500,
    marginTop: 30,
    textAlign: 'center',
    fontSize: 15,
    color: 'red',
  },
}));

export default useStyles;
