import { Box, CircularProgress, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonAction from '../../../components/Base/ButtonCustom';
import { CHAIN_ID_NAME_MAPPING, PAGE_SIZE, STATUS_IDO } from '../../../constants';
import useAddress from '../../../hooks/useAddress';
import { getListParticipants, reCalculateBuyLimits } from '../../../request/pool';
import { alertFailure } from '../../../store/actions/alert';
import { writeSnapshotOnBlockchain } from '../../../store/actions/campaign';
import { etherscanRouteTxHash } from '../../../utils';
import { convertMomentDate } from '../../../utils/convertDate';
import ParticipantsTable from '../Components/templates/ParticipantsTable';
import useStyles from './styles';

const ParticipantsIDO = (props: any) => {
  const { idoID, poolDetail, onClickReCalculate } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const walletAddress = useAddress();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [data, setData] = useState<any>([]);
  const [lastPage, setLastPage] = useState<number>(1);
  const [snapShotTime, setSnapShotTime] = useState<any>('');
  const [loadingReCalculator, setLoadingReCalculator] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentNetworkId } = useSelector((state: any) => state).userCurrentNetwork;

  const getListParticipantsFunc = useCallback(() => {
    getListParticipants(idoID, currentPage)
      .then((res) => {
        const { count } = res.meta;
        const { data, SnapshotTime } = res.data;
        setData(data);
        setLastPage(Math.ceil(count / PAGE_SIZE));
        setSnapShotTime(SnapshotTime);
      })
      .catch(() => {
        dispatch(alertFailure('Load participants fail!!!'));
      });
  }, [idoID, currentPage, dispatch]);

  useEffect(() => {
    getListParticipantsFunc();
  }, [getListParticipantsFunc]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page - 1);
  };

  const handleClickWriteOnBlockchain = async () => {
    if (loading) return;
    setLoading(true);
    await dispatch(
      writeSnapshotOnBlockchain(
        idoID,
        poolDetail.snapshot_root,
        poolDetail.contract_address,
        poolDetail.network,
        () => {
          onClickReCalculate();
          setLoading(false);
        },
      ),
    );
    // setLoading(false);
    onClickReCalculate();
    getListParticipantsFunc();
  };

  const handleCalculatorLimits = async () => {
    if (loading) return;
    setLoading(true);
    const res = await reCalculateBuyLimits(idoID, {});
    setLoadingReCalculator(true);
    getListParticipantsFunc();
    setLoading(false);
    if (res) {
      onClickReCalculate();
    }
  };

  const validateTimeSnapshot = useMemo(() => {
    const poolStartSnapshot = moment(poolDetail?.start_staking_snapshot).unix();
    const currentDate = moment().unix();
    return poolStartSnapshot <= currentDate;
  }, [poolDetail]);

  const checkNetworkAllowWriteSnapShot = () => {
    if(poolDetail) {
      return !CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes(poolDetail.network);
    } else {
      return false;
    }
  }

  const renderActionParticipants = (poolDetail: any) => {
    if (validateTimeSnapshot && poolDetail.state !== STATUS_IDO.DEPLOYED && poolDetail.snapshot_root) {
      return (
        <span>
          Snapshot stored on Blockchain{' '}
          <a
            href={etherscanRouteTxHash(poolDetail.send_snapshot_txhash, poolDetail)}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            View Transaction <OpenInNewIcon style={{ fontSize: '1rem' }} />
          </a>
        </span>
      );
    } else if (
      data?.length !== 0 &&
      validateTimeSnapshot &&
      poolDetail.snapshot_root &&
      !poolDetail.is_send_snapshot &&
      poolDetail.deployed_by?.toLowerCase() === walletAddress?.toLowerCase()
    ) {
      return (
        <ButtonAction disabled={poolDetail.is_send_snapshot || checkNetworkAllowWriteSnapShot()} onClick={handleClickWriteOnBlockchain}>
          Write Snapshot on Blockchain {loading ? <CircularProgress size={25} /> : null}
        </ButtonAction>
      );
    } else if (
      data?.length !== 0 &&
      validateTimeSnapshot &&
      poolDetail.snapshot_root &&
      poolDetail.is_send_snapshot &&
      poolDetail.deployed_by?.toLowerCase() === walletAddress?.toLowerCase()
    ) {
      return (
        <ButtonAction disabled={poolDetail.is_send_snapshot} onClick={handleClickWriteOnBlockchain}>
          Writing Snapshot on Blockchain...
        </ButtonAction>
      );
    } else if (
      !poolDetail.snapshot_root &&
      validateTimeSnapshot &&
      data?.length !== 0 &&
      poolDetail.deployed_by?.toLowerCase() === walletAddress?.toLowerCase()
    ) {
      return (
        <Box>
          {loadingReCalculator ? (
            'Calculating in progress....'
          ) : (
            <ButtonAction onClick={handleCalculatorLimits}>
              Re-calculate buy limits {loading ? <CircularProgress size={25} /> : null}
            </ButtonAction>
          )}
        </Box>
      );
    }
  };

  return (
    <Box>
      <Box className={classes.titleListUser}>
        <Typography>List of eligible users</Typography>
        <Box className={classes.actionWriteOnBlockchain}>
          {renderActionParticipants(poolDetail)}
          <Typography>Snapshot: {convertMomentDate(snapShotTime) || '--'}</Typography>
        </Box>
      </Box>
      {data.length ? (
        <Box className={classes.container}>
          <Box>
            <ParticipantsTable data={data} poolDetail={poolDetail} />
          </Box>
          <Pagination
            page={currentPage + 1}
            className={classes.pagination}
            count={lastPage}
            onChange={handlePaginationChange}
          />
        </Box>
      ) : (
        <Box className={`${classes.container} ${classes.flex}`}>
          <Box className={classes.content}>
            <span>
              The list of eligible users has not been captured yet.
              <br />
              Please wait until Snapshot
            </span>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(ParticipantsIDO);
