import React, {useEffect, useState} from 'react';
import CurrencyInput from "react-currency-input-field";
import CurrencyFormat from 'react-currency-format';
import useStyles from "../style";
import {renderErrorCreatePool} from "../../../utils/validate";

function CurrencyInputWithValidate(props: any) {
  const classes = useStyles();
  const {
    register, errors,
    initValue, controlName,
    validateRule,
    customName
  } = props;
  const renderError = renderErrorCreatePool;
  const [value, setValue] = useState(initValue);

  useEffect(() => {
    setValue(initValue)
  },[initValue])
  
  return (
    <div>
      {/* <CurrencyInput
        placeholder="Please enter a number"
        value={value}
        // decimalsLimit={12}
        onValueChange={(value: any, name: any) => {
          setValue(value);
        }}
        className={`${classes.formInputBox}`}
      /> */}
      <CurrencyFormat 
        thousandSeparator={true}  
        placeholder="Please enter a number"
        value={value}
        onValueChange={(value: any) => {
          setValue(value.value);
        }}
        className={`${classes.formInputBox1}`} 
      />
      <input
        type='hidden'
        name={controlName}
        value={value}
        ref={register(validateRule)}
      />
      
        <p className={classes.formErrorMessage}>
          {
            customName ? renderError(errors, customName) : renderError(errors, controlName)
          }
        </p>
    </div>
  );
}

export default CurrencyInputWithValidate;
