import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  btnCancel: {
    backgroundColor: '#cdcdcd !important',
    boxShadow: 'none !important',
    '&:hover': {
      background: '#cdcdcd',
      opacity: 0.8,
    },
  },
  modalTitle: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 20,
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
  iconClose: {
    position: 'absolute',
    top: 25,
    right: 25,
    opacity: 0.5,
    cursor: 'pointer',
  },
  modalDescription: {
    width: 400,
    display: 'flex',
    margin: 'auto',
    paddingBottom: 20,
    fontSize: 16,
    fontWeight: 'bold',
  },
  modalAction: {
    display: 'flex',
    margin: '20px auto',
  },

  btnConfirm: {
    marginRight: 0,
  },
}));

export default useStyles;
