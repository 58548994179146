import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import IDOChainlinkABI from '../../abi/IDOChainlinkABI.json';
import PoolFactoryABI from '../../abi/PoolFactory.json';
import stakingPoolTierABI from '../../abi/Staking/PoolNoRewardABI.json';
import stakingPoolRewardABI from '../../abi/Staking/stakeRewardABI.json';
import VestingABI from '../../abi/Vesting.json';
import { CHAIN_ID_NAME_MAPPING, POLYGON_CHAIN_ID } from '../../constants';
import { createAdmin, deleteAdmin } from '../../request/admin';
import { getContractInstance, getWeb3Instance } from '../../services/web3';
import {
  getFactoryPoolAdress,
  getRewardPoolAdress,
  getTierPoolAdress,
  getVestingAdress,
} from '../../utils/contractAddress/getAddresses';
import { alertFailure, alertSuccess, alertWarning } from './alert';
import { getGasFeePolygon } from '../../utils/blockchain';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

export const addAdminRewardStakePool = (address: string, enable: boolean, role: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      const currentNetworkId = localStorage.getItem('NETWORK_ID');
      const CONTRACT_ADDRESS = getRewardPoolAdress(currentNetworkId?.toString() || '') as string;
      const contract = getContractInstance(stakingPoolRewardABI, CONTRACT_ADDRESS, true, 'write');
      const userWalletAddress = getState().user.data.wallet_address;
      console.log({userWalletAddress, address})
      if (!contract) {
        dispatch(alertFailure('Contract is error'));
        return;
      }
      let payload;  
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        const web3Instance = await getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        if (gasPrice) {
            payload = {
              maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
              from: userWalletAddress,
          }
        } else {
          payload = {
            from: userWalletAddress,
            gas: 8000000,
          }
        }
      } else {
          payload = {
              from: userWalletAddress,
              gas: 8000000,
          }
        }

      if (enable) {
        const res = await contract.methods
          .addAdmin(address)
          .send({
            ...payload
          })
          .on('transactionHash', () => {
            dispatch(alertWarning('Add admin pending...'));
          });
        if (res && res.status === true) {
          const data = {
            wallet_address: address,
            role: role,
            tx_hash: res.transactionHash,
          };
          await createAdmin(data);
          dispatch(alertSuccess('Add admin successfully!'));
        }
      } else {
        const res = await contract.methods
          .removeAdmin(address)
          .send({
            payload
          })
          .on('transactionHash', () => {
            dispatch(alertWarning('Delete admin pending...'));
          });
        if (res && res.status === true) {
          await deleteAdmin(address, role);
          dispatch(alertSuccess('Delete admin successfully!'));
        }
      }
    } catch (err: any) {
      console.log({err})
      if (err.code === 4001) {
        dispatch(alertFailure('Transaction rejected!'));
      } else {
        dispatch(alertFailure(err.message));
      }
    }
  };
};

export const addAdminTierStakePool = (address: string, enable: boolean, role: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      const { currentNetworkId } = getState().userCurrentNetwork;
      const CONTRACT_ADDRESS = getTierPoolAdress(currentNetworkId) as string;
      const contract = getContractInstance(stakingPoolTierABI, CONTRACT_ADDRESS, true, 'write');
      const userWalletAddress = getState().user.data.wallet_address;
      if (!contract) {
        dispatch(alertFailure('Contract is error'));
        return;
      }

      let payload;  
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        const web3Instance = await getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        if(gasPrice) {
          payload = {
            maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
            from: userWalletAddress,
          }
        } else {
          payload = {
            from: userWalletAddress,
            gas: 8000000,
          }
        }
      } else {
          payload = {
              from: userWalletAddress,
              gas: 8000000,
          }
        }

      if (enable) {
        const res = await contract.methods
          .addAdmin(address)
          .send({
            ...payload
          })
          .on('transactionHash', () => {
            dispatch(alertWarning('Add admin pending...'));
          });
        if (res && res.status === true) {
          const data = {
            wallet_address: address,
            role: role,
            tx_hash: res.transactionHash,
          };
          await createAdmin(data);
          dispatch(alertSuccess('Add admin successfully!'));
        }
      } else {
        const res = await contract.methods
          .removeAdmin(address)
          .send({
            ...payload
          })
          .on('transactionHash', () => {
            dispatch(alertWarning('Delete admin pending...'));
          });
        if (res && res.status === true) {
          await deleteAdmin(address, role);
          dispatch(alertSuccess('Delete admin successfully!'));
        }
      }
    } catch (err: any) {
      if (err.code === 4001) {
        dispatch(alertFailure('Transaction rejected!'));
      } else {
        dispatch(alertFailure(err.message));
      }
    }
  };
};

export const addAdminFactoryPool = (address: string, enable: boolean, role: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      const { currentNetworkId } = getState().userCurrentNetwork;
      const CONTRACT_ADDRESS = getFactoryPoolAdress(currentNetworkId) as string;
      const contract = getContractInstance(PoolFactoryABI, CONTRACT_ADDRESS, true, 'write');
      const userWalletAddress = getState().user.data.wallet_address;
      if (!contract) {
        dispatch(alertFailure('Contract is error'));
        return;
      }
      const web3Instance = getWeb3Instance();
      const gasPrice = await web3Instance?.eth.getGasPrice();
      const obj = CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes('klaytn')
        ? {
            maxFeePerGas: gasPrice,
            maxPriorityFeePerGas: gasPrice,
          }
        : {};

      let payload;  
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        const web3Instance = await getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        if (gasPrice) {
          payload = {
            maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
            from: userWalletAddress,
          }
        } else {
          payload = {
            from: userWalletAddress,
            gas: 8000000,
          }
        }
      } else {
          payload = {
              from: userWalletAddress,
              gas: 8000000,
          }
        }

      if (enable) {
        const res = await contract.methods
          .addAdmin(address)
          .send({
            ...payload,
            ...obj,
          })
          .on('transactionHash', () => {
            dispatch(alertWarning('Add admin pending...'));
          });
        if (res && res.status === true) {
          const data = {
            wallet_address: address,
            role: role,
            tx_hash: res.transactionHash,
          };
          await createAdmin(data);
          dispatch(alertSuccess('Add admin successfully!'));
        }
      } else {
        const res = await contract.methods
          .removeAdmin(address)
          .send({
            payload,
            ...obj,
          })
          .on('transactionHash', () => {
            dispatch(alertWarning('Delete admin pending...'));
          });
        if (res && res.status === true) {
          await deleteAdmin(address, role);
          dispatch(alertSuccess('Delete admin successfully!'));
        }
      }
    } catch (err: any) {
      if (err.code === 4001) {
        dispatch(alertFailure('Transaction rejected!'));
      } else {
        dispatch(alertFailure(err.message));
      }
    }
  };
};

export const addAdminVesting = (address: string, enable: boolean, role: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      const { currentNetworkId } = getState().userCurrentNetwork;
      const CONTRACT_ADDRESS = getVestingAdress(currentNetworkId) as string;
      const contract = getContractInstance(VestingABI, CONTRACT_ADDRESS, true, 'write');
      const userWalletAddress = getState().user.data.wallet_address;
      if (!contract) {
        dispatch(alertFailure('Contract is error'));
        return;
      }
      const web3Instance = getWeb3Instance();
      const gasPrice = await web3Instance?.eth.getGasPrice();
      const obj = CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes('klaytn')
        ? {
            maxFeePerGas: gasPrice,
            maxPriorityFeePerGas: gasPrice,
          }
        : {};

      let payload;  
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        const web3Instance = await getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        if (gasPrice) {
          payload = {
            maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
            from: userWalletAddress,
          }
        } else {
          payload = {
            from: userWalletAddress,
            gas: 8000000,
          }
        }
      } else {
          payload = {
              from: userWalletAddress,
              gas: 8000000,
          }
        }  

      if (enable) {
        const res = await contract.methods
          .addAdmin(address)
          .send({
            ...payload,
            ...obj,
          })
          .on('transactionHash', () => {
            dispatch(alertWarning('Add admin pending...'));
          });
        if (res && res.status === true) {
          const data = {
            wallet_address: address,
            role: role,
            tx_hash: res.transactionHash,
          };
          await createAdmin(data);
          dispatch(alertSuccess('Add admin successfully!'));
        }
      } else {
        const res = await contract.methods
          .removeAdmin(address)
          .send({
            ...payload,
            ...obj,
          })
          .on('transactionHash', () => {
            dispatch(alertWarning('Delete admin pending...'));
          });
        if (res && res.status === true) {
          await deleteAdmin(address, role);
          dispatch(alertSuccess('Delete admin successfully!'));
        }
      }
    } catch (err: any) {
      if (err.code === 4001) {
        dispatch(alertFailure('Transaction rejected!'));
      } else {
        dispatch(alertFailure(err.message));
      }
    }
  };
};

export const addAdminIDOChainlink = (address: string, enable: boolean, role: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_RANDOM_NUMBER_POLYGON || '';
      const contract = getContractInstance(IDOChainlinkABI, CONTRACT_ADDRESS, true, 'write');
      const userWalletAddress = getState().user.data.wallet_address;
      if (!contract) {
        dispatch(alertFailure('Contract is error'));
        return;
      }
      const { currentNetworkId } = getState().userCurrentNetwork;
      let payload;  
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        const web3Instance = await getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        if (gasPrice) {
            payload = {
              maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
              from: userWalletAddress,
          };
        } else {
            payload = {
              from: userWalletAddress,
              gas: 8000000,
          }
        }
      } else {
          payload = {
              from: userWalletAddress,
              gas: 8000000,
          }
        }

      if (enable) {
        const res = await contract.methods
          .addAdmin(address)
          .send({
            ...payload
          })
          .on('transactionHash', () => {
            dispatch(alertWarning('Add admin pending...'));
          });
        if (res && res.status === true) {
          const data = {
            wallet_address: address,
            role: role,
            tx_hash: res.transactionHash,
          };
          await createAdmin(data);
          dispatch(alertSuccess('Add admin successfully!'));
        }
      } else {
        const res = await contract.methods
          .removeAdmin(address)
          .send({
            ...payload
          })
          .on('transactionHash', () => {
            dispatch(alertWarning('Delete admin pending...'));
          });
        if (res && res.status === true) {
          await deleteAdmin(address, role);
          dispatch(alertSuccess('Delete admin successfully!'));
        }
      }
    } catch (err: any) {
      if (err.code === 4001) {
        dispatch(alertFailure('Transaction rejected!'));
      } else {
        dispatch(alertFailure(err.message));
      }
    }
  };
};
