import { getContractInstance } from '../services/web3';
import erc20ABI from '../abi/Erc20.json';
import ethLinkABI from '../abi/Ethlink.json';
import stakeLPPoolABI from '../abi/Staking/PoolNoRewardABI.json';
import { getTierPoolAdress } from './contractAddress/getAddresses';
import { sleep } from '.';

const ETH_LINK_DEFAULT_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ETHLINK_ADDRESS || '';

export type TokenType = {
  name: string;
  symbol: string;
  decimals: number;
  address: string;
};

type ReturnType = TokenType | undefined;

export const getTokenInfo = async (tokenAddress: string): Promise<ReturnType> => {
  if (tokenAddress) {
    let number_of_retry = 1;
    while (number_of_retry <= 5) {
      try {
        const erc20Token = getContractInstance(erc20ABI, tokenAddress);
        if (erc20Token) {
          const tokenName = erc20Token.methods.name().call();
          const tokenSymbol = erc20Token.methods.symbol().call();
          const tokenDecimals = erc20Token.methods.decimals().call();

          const res = await Promise.all([tokenName, tokenSymbol, tokenDecimals]);
          number_of_retry = 6;
          return {
            name: res[0],
            symbol: res[1],
            decimals: res[2],
            address: tokenAddress,
          };
        } else {
          number_of_retry ++;
        }
      } catch (err) {
        number_of_retry++;
        if (number_of_retry >5) {
          console.log({err})
          throw new Error('Token address is invalid.');
        }
      }
      await sleep(500);
    }
  }   else {
    return;
  }
};

export const tokenAlreadyUsed = async (tokenAddress: string): Promise<boolean> => {
  try {
    const ethLinkContract = getContractInstance(ethLinkABI, ETH_LINK_DEFAULT_ADDRESS);

    if (ethLinkContract) {
      const tokenRegistered = await ethLinkContract.methods.tokens(tokenAddress).call();

      if (Number(tokenRegistered.registeredBy) === 0) return false;

      return Number(tokenRegistered.registeredBy) > 0;
    }
  } catch (err: any) {
    console.log(err.message);
    return true;
  }

  return false;
};

export const getShortTokenSymbol = (tokenSymbol: string, yourLength = 10) => {
  if (!tokenSymbol) tokenSymbol += '';
  if (tokenSymbol.length <= yourLength) {
    return tokenSymbol;
  }

  return `${tokenSymbol.substring(0, 10)}...`;
};

export const getExistLPTokens = async (currentNetworkId: any): Promise<ReturnType> => {
  const NO_REWARD_STAKING = getTierPoolAdress(currentNetworkId.toString())
  try {
    let stakingPoolContract = getContractInstance(
      stakeLPPoolABI,
      NO_REWARD_STAKING || '',
    );

    if (stakingPoolContract) {
      const tokenLists = await stakingPoolContract.methods.getListToken().call();
      return tokenLists;
    }
  } catch (err: any) {
    throw new Error("Can't connect to smart contract");
  }
};

export const getPoolLPStatus = async (currentNetworkId:any): Promise<ReturnType> => {
  const NO_REWARD_STAKING = getTierPoolAdress(currentNetworkId.toString())
  try {
    let stakingPoolContract = getContractInstance(
      stakeLPPoolABI,
      NO_REWARD_STAKING || '',
    );

    if (stakingPoolContract) {
      const { canStake: isStakable } = await stakingPoolContract.methods
        .externalToken(process.env.REACT_APP_TOKEN_CODE_ADDRESS)
        .call();
      return isStakable;
    }
  } catch (err: any) {
    throw new Error("Can't connect to smart contract");
  }
};
