import { makeStyles } from '@material-ui/core';
import commonStyles from '../style.common';

const useStyles = makeStyles((theme) => ({
  dialog: {
    ...commonStyles.dialog,
  },
  section: {
    ...commonStyles.sectionStyle,
    marginTop: '0',
  },
  flexBetween: {
    ...commonStyles.flexLayout,
    alignItems: 'center',
  },
  sectionTitle: commonStyles.sectionTitle,
  tableTitle: {
    paddingRight: '2.5rem',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  editBtn: {
    cursor: 'pointer',
  },
  modalTitle: {
    ...commonStyles.modalTitle,
  },
  container: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 10px rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: '2rem 2.5rem 2rem',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    position: 'relative',
  },
  buttonEditTier: {
    position: 'absolute',
    right: '2.5rem',
    borderRadius: 4,
    display: 'flex',
    height: 36.5,
    alignItems: 'center',
    justifyContent: 'center',
    // '&:hover': {
    //   background: '#d5d5d5'
    // }
  },
}));

export default useStyles;
