import { Tooltip } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { ACCEPT_CURRENCY } from '../../../constants';
import { getIconCurrencyUsdt } from '../../../utils/usdt';
import useStyles from '../style';

function ExchangeRateETH(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, control, watch, poolDetail, isViewDetail, token, setPrice } =
    props;
  const [rateValue, setRateValue] = useState(0);

  useEffect(() => {
    if (poolDetail) {
      if (poolDetail.accept_currency === ACCEPT_CURRENCY.ETH) {
        setValue('tokenRate', poolDetail.conversion_rate);
        setRateValue(poolDetail.conversion_rate);
      } else {
        // poolDetail.ether_conversion_rate
        setValue('tokenRate', poolDetail.conversion_rate || 1);
        setRateValue(poolDetail.conversion_rate || 1);
      }
    }
  }, [poolDetail]);

  const renderErrorMinMax = (errors: any, prop: string, min: number, max: number = 100) => {
    if (errors[prop]) {
      if (errors[prop].type === 'required') {
        return 'This field is required';
      } else if (errors[prop].type === 'min') {
        return `This field must be greater than ${min}`;
      } else if (errors[prop].type === 'max') {
        return `This field must be less than ${max}`;
      } else if (errors[prop].type === 'maxDecimals') {
        return `Decimals can not greater than 8`;
      }
    }
  };

  const isDeployed = !!poolDetail?.is_deploy;
  const acceptCurrency = watch('accept_currency');
  const networkAvailable = watch('network');
  let { currencyName } = getIconCurrencyUsdt({
    purchasableCurrency: acceptCurrency,
    networkAvailable: networkAvailable,
  });

  return (
    <>
      <label className={classes.exchangeRateTitle}>
        Price in{' '}
        <span style={{ textTransform: 'uppercase' }}>
          {acceptCurrency} <span style={{ color: '#e51d1d' }}>*</span>
        </span>
      </label>
      <div className={classes.formControlWrap}>
        <div className={classes.formControlFlexBlock}>
          <label className={`${classes.formControlLabel} ${classes.formControlBlurLabel}`}>
            You have
          </label>

          <div className={classes.formControlRate}>
            <input
              type="number"
              name="ethFor"
              disabled={true}
              value={1}
              className={`${classes.formInputBox} ${classes.formInputBoxEther}`}
            />
            <button className={classes.box}>{token?.symbol || ''}</button>
          </div>
        </div>

        <span style={{ margin: '22px 10px 0' }}> = </span>
        <div className={classes.formControlFlexBlock}>
          <label className={`${classes.formControlLabel} ${classes.formControlBlurLabel}`}>
            You get*
          </label>
          <div className={classes.formControlRate}>
            <CurrencyInput
              value={rateValue || 0}
              decimalsLimit={8}
              maxLength={25}
              onValueChange={(value: any, name: any) => {
                setRateValue(value);
                setPrice(value, 'conversion_rate');
                setValue('conversion_rate', value, { shouldValidated: true });
              }}
              className={`${classes.formInputBox} ${classes.formInputBoxBS}`}
              disabled={isDeployed || isViewDetail}
            />

            <input
              type="hidden"
              name={'tokenRate'}
              value={rateValue || 0}
              ref={register({
                required: true,
                validate: {
                  min: (value: any) => new BigNumber(value).comparedTo(0) > 0,
                  // maxDecimals: checkMaxEthRateDecimals
                },
              })}
              disabled={isDeployed}
            />

            <Tooltip title={currencyName}>
              <button className={`${classes.box} ${classes.boxEther}`}>{currencyName}</button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}>
        {renderErrorMinMax(errors, 'tokenRate', 0, 100)}
      </div>
    </>
  );
}

export default ExchangeRateETH;
