import React from 'react';
import useStyles from '../../style';
import { renderErrorCreatePool } from '../../../../utils/validate';

function TelegramLink(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, watch, getValues, needValidate, poolDetail, isViewDetail } =
    props;
  const renderError = renderErrorCreatePool;

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Self Telegram</label>
        <input
          type="text"
          name="platform_telegram"
          defaultValue={poolDetail?.platform_telegram}
          ref={register({
            // required: true
          })}
          className={classes.formControlInput}
          disabled={isViewDetail}
        />
        <p className={classes.formErrorMessage}>{renderError(errors, 'platform_telegram')}</p>
      </div>
    </>
  );
}

export default TelegramLink;
