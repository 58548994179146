import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: '20px 25px 30px 25px',
    marginTop: 20,
  },
  flex: {
    display: 'flex',
  },
  content: {
    margin: '20px auto',
    fontSize: 16,
  },
  bodyTable: {
    minHeight: 300,
  },
  pagination: {
    marginTop: 30,
    fontSize: 16,
    fontWeight: 400,
    '& .MuiPagination-ul': {
      justifyContent: 'center',
      display: 'flex',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#FFCC00',
    },
  },
  titleListUser: {
    textAlign: 'center',
    position: 'relative',
    marginBottom: 50
  },
  actionWriteOnBlockchain: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}));

export default useStyles;
