import { Button, makeStyles, withStyles } from '@material-ui/core';
import commonStyles from './style.common';

const useStyles = makeStyles((theme) => ({
  sectionTitle: commonStyles.sectionTitle,
  section: { ...commonStyles.sectionStyle },
  table: {
    margin: '4.5rem 1rem',
  },
  tableTitle: {
    paddingRight: '7.5rem',
    textAlign: 'left',
  },
  tableTitleShort: {
    padding: '0.25rem 0',
    paddingRight: '2.5rem',
    textAlign: 'left',
    borderRight: '1px solid #000',
  },
  tableTitleRow: {
    borderBottom: '1px solid #000',
  },
  tierData: {
    textAlign: 'center',
  },
  tierName: {
    padding: '0.5rem 2rem',
  },

  contractAddress: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  flexStart: {
    ...commonStyles.flexLayout,
    alignItems: 'flex-start',
  },
  skeleton: {
    padding: '25px 0px',
    marginTop: 10,
  },
  modalTitle: {
    ...commonStyles.modalTitle,
  },
  dialog: {
    ...commonStyles.dialog,
  },
}));

export const UpdateButton = withStyles((theme) => ({
  root: {
    cursor: 'pointer',
    textTransform: 'none',
    display: 'block',
    margin: '0 auto',
  },
}))(Button);

export default useStyles;
