import { withStyles, WithStyles } from '@material-ui/core/styles';

import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from './dialog.style';

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  customClass?: string;
}

interface DialogProps {
  showModal: boolean;
  closeModalHandler: () => void;
  children: any;
  style?: any;
  title: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, customClass, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={customClass} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const PoolFormDialog = ({ showModal, closeModalHandler, children, style, title }: DialogProps) => {
  return (
    <MuiDialog open={showModal} onClose={closeModalHandler} className={style?.dialog}>
      <DialogTitle
        customClass={style?.modalTitle}
        onClose={closeModalHandler}
        id="customized-lp-form-dialog-title"
      >
        {title}
      </DialogTitle>
      <MuiDialogContent>{children}</MuiDialogContent>
    </MuiDialog>
  );
};

export default PoolFormDialog;
