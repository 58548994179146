import { Button, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DATETIME_FORMAT } from '../../../../constants';
import { renderErrorCreatePool } from '../../../../utils/validate';
import useStyles from '../../style';
import CreateEditCompetitorBenchmarkForm, { Benchmark } from './CreateEditCompetitorBenchmarkForm';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStylesTable = makeStyles({
  table: {
    // minWidth: 650,
  },
  noData: {
    minHeight: 200,
    width: '100%',
    background: '#f0f0f0',
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function CompetitorBenchmarkTable(props: any) {
  const classes = useStyles();
  const classesTable = useStylesTable();
  const { register, setValue, errors, poolDetail, isViewDetail } = props;
  const renderError = renderErrorCreatePool;
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [editData, setEditData] = useState({});
  const [editRow, setEditRow] = useState(0);
  const [isEdit, setIsEdit] = useState(true);
  const [rows, setRows] = useState<any>([]);

  useEffect(() => {
    if (poolDetail) {
      if (poolDetail.competior_benmark && poolDetail.competior_benmark.length > 0) {
        setRows(poolDetail.competior_benmark);
        setValue('competitor_benchmark', JSON.stringify(poolDetail.competior_benmark), {
          shouldValidate: true,
        });
      } else {
        setValue('competitor_benchmark', undefined, { shouldValidate: true });
      }
    }
  }, [poolDetail]);

  const openPopupEdit = (e: any, row: any, index: number) => {
    console.log('ROW: ', row, index);
    setEditData(row);
    setEditRow(index);
    setIsEdit(true);
    setIsOpenEditPopup(true);
  };

  const openPopupCreate = (e: any) => {
    setEditData({});
    setEditRow(-1);
    setIsEdit(false);
    setIsOpenEditPopup(true);
  };

  const handleCreateUpdateData = (responseData: any) => {
    console.log('responseData', editRow, responseData);
    if (isEdit && editRow !== -1) {
      console.log('edit');

      // Update
      // @ts-ignore
      rows[editRow] = responseData;
    } else {
      console.log('create');

      // Create
      // @ts-ignore
      let newArr = JSON.parse(JSON.stringify(rows));
      newArr.push(responseData);
      console.log('newArr', newArr);

      setRows(newArr);
      setValue('competitor_benchmark', JSON.stringify(rows), { shouldValidate: true });
    }
    if (rows && rows.length > 0) {
      setValue('competitor_benchmark', JSON.stringify(rows), { shouldValidate: true });
    } else {
      setValue('competitor_benchmark', undefined, { shouldValidate: true });
    }
    setIsOpenEditPopup(false);
  };

  const deleteRecord = (e: any, row: any, index: number) => {
    console.log('ROW: ', row, index);
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Do you want delete this record ?')) {
      return false;
    }

    const newRows = [...rows];
    if (index > -1) {
      newRows.splice(index, 1);
    }
    setRows(newRows);
    if (newRows && newRows.length > 0) {
      setValue('competitor_benchmark', JSON.stringify(newRows), { shouldValidate: true });
    } else {
      setValue('competitor_benchmark', undefined, { shouldValidate: true });
    }
  };

  return (
    <>
      {isOpenEditPopup && (
        <CreateEditCompetitorBenchmarkForm
          isOpenEditPopup={isOpenEditPopup}
          setIsOpenEditPopup={setIsOpenEditPopup}
          renderError={renderError}
          editData={editData}
          isEdit={isEdit}
          handleCreateUpdateData={handleCreateUpdateData}
        />
      )}
      <div className={classes.formControl}>
        <label className={classes.exchangeRateTitle} style={{ width: '100%' }}>
          Competitor benchmark <span style={{ color: '#e51d1d' }}>*</span>
        </label>
      </div>
      <div className={`${classes.formControl} ${isViewDetail && classes.displayNone}`}>
        <Button variant="contained" color="primary" onClick={openPopupCreate}>
          Add
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table className={classesTable.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Ticker</TableCell>
              <TableCell align="right">Market Cap</TableCell>
              {/* <TableCell align="right">Max Supply</TableCell> */}
              <TableCell align="center" width={200}>
                Compare to Competitor's market cap
              </TableCell>
              <TableCell align="right" className={isViewDetail ? classes.displayNone : ''}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.map((row: Benchmark, index: number) => {
                const { name, ticker, market_cap, FDV, market_cap_description } = row;
                return (
                  <TableRow key={index}>
                    <TableCell>{name}</TableCell>
                    <TableCell align="right">{ticker}</TableCell>
                    <TableCell align="right">{market_cap}</TableCell>
                    {/* <TableCell align="right">{FDV}</TableCell> */}
                    <TableCell align="center">{market_cap_description}</TableCell>
                    <TableCell align="right" className={isViewDetail ? classes.displayNone : ''}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => openPopupEdit(e, row, index)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(e) => deleteRecord(e, row, index)}
                        style={{ marginLeft: 10, marginTop: 0 }}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className={classesTable.noData}>No data</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!rows || rows.length === 0 ? (
          <p className={classes.formErrorMessage}>{renderError(errors, 'competitor_benchmark')}</p>
        ) : (
          ''
        )}
      </TableContainer>

      <input
        type="hidden"
        name="competitor_benchmark"
        value={rows && rows.length > 0 ? JSON.stringify(rows) : undefined}
        ref={register({
          required: true,
        })}
      />
    </>
  );
}

export default CompetitorBenchmarkTable;
