import { trimMiddlePartAddress } from '../../../utils/accountAddress';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import useStyles, { ColorButton } from './style';
import { etherscanRoute } from '../../../utils';

interface StakeData {
  address: string;
  stakeAmount: {
    amount: number;
    value: number;
  };
  participantsNum: number;
  stakable: boolean;
}

interface TierUsers {
  starter: number;
  rookie: number;
  legend: number;
}

interface ComponentProps {
  stakes: StakeData | undefined;
  userNum: TierUsers | undefined;
  isPermission?:boolean,
  isPolygon?:boolean,
  onChangeLPStatus: () => Promise<void>;
}

const TierStaking: React.FC<any> = ({ stakes, userNum, onChangeLPStatus, isPermission, isPolygon}: ComponentProps) => {
  const classes = useStyles();
  
  return (
    <section className={`${classes.section} ${classes.sectionTier}`}>
      <h4 className={classes.sectionTitle}>Tier Staking CODE</h4>
      <div className={classes.container}>
        <div className={classes.flexStart}>
          <table>
            <tbody>
              <tr>
                <th className={classes.tableTitle}>Contract Address</th>
                <td className={classes.contractAddress}>
                  <span>{trimMiddlePartAddress(stakes?.address ?? '')}</span>{' '}
                  <a href={etherscanRoute(stakes?.address, { network: 'polygon'})} target="_blank" rel="noreferrer">
                    <OpenInNewIcon style={{ fontSize: '1rem' }} />
                  </a>
                </td>
              </tr>
              <tr>
                <th className={classes.tableTitle}>Total Staked amount</th>
                <td>
                  {stakes?.stakeAmount.amount.toLocaleString('en')} CODE ~{' '}
                  {stakes?.stakeAmount.value.toLocaleString('en', { maximumFractionDigits: 2 })} USD
                </td>
              </tr>
              <tr>
                <th className={classes.tableTitle}>Participants</th>
                <td>{stakes?.participantsNum.toLocaleString('en')} users</td>
              </tr>
            </tbody>
          </table>

          <ColorButton variant="contained" disableElevation onClick={onChangeLPStatus} disabled={!isPermission || !isPolygon}>
            {stakes?.stakable ? 'Pause pool' : 'Enable pool'}
          </ColorButton>
        </div>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.tableTitleRow}>
              <td className={classes.tableTitleShort}>Tier</td>
              <td className={classes.tierName}>Starter</td>
              <td className={classes.tierName}>Super Rookie</td>
              <td className={classes.tierName}>Legend</td>
            </tr>
            <tr>
              <td className={classes.tableTitleShort}>Users</td>
              <td className={classes.tierData}>{userNum?.starter.toLocaleString('en')}</td>
              <td className={classes.tierData}>{userNum?.rookie.toLocaleString('en')}</td>
              <td className={classes.tierData}>{userNum?.legend.toLocaleString('en')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default TierStaking;
