import React from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import useStyles from './styles';
import { adminRoute } from '../../../utils';
import { logout } from '../../../store/actions/user';
import { useDispatch } from 'react-redux';
import { getCurrentNetWorkWeb3 } from '../../../services/web3';
import { CHAIN_ID_NAME_MAPPING } from '../../../constants';

const nav = [
  {
    title: 'Staking',
    part: adminRoute('/staking'),
    icon: 'icon-list-campaign',
    isParent: true,
    children: [
      {
        title: 'Tier stake',
        part: adminRoute('/tier-stake'),
        icon: 'icon-users',
        isParent: false,
      },
      {
        title: 'Reward Stake',
        part: adminRoute('/reward-stake'),
        icon: 'icon-users',
        isParent: false,
      },
    ],
  },
  {
    title: 'Manage IDO',
    part: adminRoute('/campaigns'),
    icon: 'icon-list-campaign',
    isParent: false,
  },
  // {
  //   title: 'List User',
  //   part: adminRoute('/users'),
  //   icon: 'icon-users',
  // },
  {
    title: 'Manage Users',
    part: adminRoute('/kyc-users'),
    icon: 'icon-users',
    isParent: false,
  },

  {
    title: 'List Admin',
    part: adminRoute('/admins'),
    icon: 'icon-users',
    isParent: false,
  },
];

const navKlaytn = [
  {
    title: 'Manage IDO',
    part: adminRoute('/campaigns'),
    icon: 'icon-list-campaign',
    isParent: false,
  },
  {
    title: 'Manage Users',
    part: adminRoute('/kyc-users'),
    icon: 'icon-users',
    isParent: false,
  },

  {
    title: 'List Admin',
    part: adminRoute('/admins'),
    icon: 'icon-users',
    isParent: false,
  },
];

const NavLeft = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [navLeft] = React.useState(nav);
  const { smallLeft, location } = props;

  const logoutUser = () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Do you want logout?')) {
      return false;
    }
    dispatch(logout());
    history.push('/');
  };
  const currentNetworkId  = localStorage.getItem("NETWORK_ID");

  const checkNetworkAllowDeploy = () => {
    if(currentNetworkId) {
      return CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes('klaytn');
    } else {
      return false;
    }
  } 

  const checkNav = () => {
    if(checkNetworkAllowDeploy()) {
      return navKlaytn
    } else {
      return nav
    }
  }

  return (
    <ul className={styles.navLeft}>
      {checkNav().map((item: any, index: number) => {
        return (
          <li
            key={index}
            className={styles.itemNavLeft + ' ' + (location?.pathname?.includes(item.part) && 'active')}
          >
            {!item.isParent ? (
              <Link
                to={item.part}
                className={
                  styles.linkItemNavLeft + ' ' + (location?.pathname?.includes(item.part) && 'active')
                }
              >
                <i className={(smallLeft && ' icon-small') + ' icon ' + item.icon}></i>
                {!smallLeft && item.title}
              </Link>
            ) : (
              <>
                <span className={styles.linkItemNavLeft}>
                  <i className={(smallLeft && ' icon-small') + ' icon ' + item.icon}></i>
                  {!smallLeft && item.title}
                </span>
                <ul className={styles.navLeftChild}>
                  {item?.children &&
                    item?.children?.map((child: any, childIndex: number) => {
                      return (
                        <li
                          key={childIndex}
                          className={
                            styles.itemNavLeft +
                            ' ' +
                            (location?.pathname?.includes(child.part) && 'active')
                          }
                        >
                          <Link
                            to={child.part}
                            className={
                              styles.linkItemNavLeft +
                              ' ' +
                              (location?.pathname.includes(child.part) && 'active')
                            }
                          >
                            <i className={(smallLeft && ' icon-small') + ' icon ' + child.icon}></i>
                            {!smallLeft && child.title}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </>
            )}
          </li>
        );
      })}

      <li className={styles.itemNavLeft}>
        <a onClick={logoutUser} className={styles.linkItemNavLeft}>
          <i className={(smallLeft && ' icon-small') + ' icon icon-setting'}></i>
          {!smallLeft && 'Logout'}
        </a>
      </li>
    </ul>
  );
};

export default withRouter(NavLeft);
