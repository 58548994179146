export const headCells = [
  {
    id: 1,
    label: 'Wallet address',
  },
  {
    id: 2,
    label: 'Social requirements',
  },
  {
    id: 3,
    label: 'Tier',
  },
  {
    id: 4,
    label: 'Min ticket',
  },
  {
    id: 5,
    label: 'Max ticket',
  },
  {
    id: 6,
    label: 'Last update time',
  },
];
