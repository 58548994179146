import { Button, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { renderErrorCreatePool } from '../../../../utils/validate';
import useStyles from '../../style';
import CreateEditCompetitorBenchmarkForm from './CreateEdiProjectRatingTableForm';

const useStylesTable = makeStyles({
  table: {
    // minWidth: 650,
  },
  noData: {
    minHeight: 200,
    width: '100%',
    background: '#f0f0f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const defaultValue = Array.from({ length: 6 }, (v, k) => {
  return { id: k + 1, name: '', score: '' };
});

function ProjectRatingTable(props: any) {
  const classes = useStyles();
  const classesTable = useStylesTable();
  const { register, watch, setValue, control, errors, poolDetail, isViewDetail } = props;
  const renderError = renderErrorCreatePool;
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);

  const [editData, setEditData] = useState<any>(defaultValue);
  const [editRow, setEditRow] = useState(0);
  const [isEdit, setIsEdit] = useState(true);

  useEffect(() => {
    if (poolDetail) {
      if(poolDetail.ratings && poolDetail.ratings.length > 0 ){
        setEditData(poolDetail.ratings);
        setValue('project_rating', JSON.stringify(poolDetail.ratings),{shouldValidate:true});
      }else{
        setValue('project_rating', undefined,{shouldValidate:true});
      }
    }
  }, [poolDetail]);

  const openPopupEdit = (e: any) => {
    setIsEdit(true);
    setIsOpenEditPopup(true);
  };

  const openPopupCreate = (e: any) => {
    setEditData(defaultValue);
    setEditRow(-1);
    setIsEdit(false);
    setIsOpenEditPopup(true);
  };

  const handleCreateUpdateData = (responseData: any) => {
    if (isEdit && editRow !== -1) {
      // Update
      // @ts-ignore
      editData[editRow] = responseData;
    } else {
    }
   
    setIsOpenEditPopup(false);
    if(responseData && responseData.length > 0 ){
      setEditData(responseData);
      setValue('project_rating', JSON.stringify(responseData),{shouldValidate:true});
    }else{
      setValue('project_rating', undefined,{shouldValidate:true});
    }
  };

  return (
    <>
      {isOpenEditPopup && (
        <CreateEditCompetitorBenchmarkForm
          isOpenEditPopup={isOpenEditPopup}
          setIsOpenEditPopup={setIsOpenEditPopup}
          renderError={renderError}
          editData={editData}
          isEdit={isEdit}
          handleCreateUpdateData={handleCreateUpdateData}
        />
      )}
      <div className={classes.formControl}>
        <label className={classes.exchangeRateTitle} style={{ width: '100%' }}>
          Project Ratings <span style={{ color: '#e51d1d' }}>*</span>
        </label>
      </div>
      <div className={`${classes.formControl} ${isViewDetail ? classes.displayNone : ""}`}>
        {!editData[0].name && (
          <Button variant="contained" color="primary" onClick={openPopupCreate}>
            Add
          </Button>
        )}

        {editData[0].name && (
          <Button variant="contained" color="secondary" onClick={openPopupEdit}>
            Edit
          </Button>
        )}
      </div>
      <div>
        {editData && editData.length > 0 ? (
          editData.map((item: any, index: number) => {
            const { name, score } = item;
            return (
              <div className={classes.formControlWrap} key={index}>
                <div className={classes.formControlFlexBlock}>
                  <label className={`${classes.formControlLabel} ${classes.formControlBlurLabel}`}>
                    {` Criteria ${index + 1}`}
                  </label>
                  <div className={classes.formControlRate}>
                    <input
                      type="text"
                      defaultValue={name}
                      className={classes.formControlInput}
                      disabled
                    />
                  </div>
                </div>
                <span style={{ marginRight: 16, marginTop: 26, display: 'block' }}> </span>
                <div className={classes.formControlFlexBlock}>
                  <label className={`${classes.formControlLabel} ${classes.formControlBlurLabel}`}>
                    Score
                  </label>
                  <div className={classes.formControlRate}>
                    <input
                      type="text"
                      defaultValue={score}
                      disabled
                      className={classes.formControlInput}
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className={classesTable.noData}>No data</div>
        )}
        <p className={classes.formErrorMessage}>{renderError(errors, 'project_rating')}</p>
      </div>

      <input
        type="hidden"
        name="project_rating"
        value={editData && editData[0].name !== "" ? JSON.stringify(editData) : undefined}
        ref={register({
          required: true,
        })}
      />
    </>
  );
}

export default ProjectRatingTable;
