import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { CHAIN_ID_NAME_MAPPING, ROLE_ADMIN } from '../../constants';
import usePermission from '../../hooks/usePermission';
import { getPoolList, refreshPoolList } from '../../request/pool';
import { getPoolTiers, getTiersSetting, updateTiersSetting } from '../../request/tier';
import { getCurrentNetWorkWeb3 } from '../../services/web3';
import { alertFailure } from '../../store/actions/alert';
import {
  changePoolLPStatus as changeStakePoolLPStatus, deployStakingPoolLP,
  editStakingPoolLP
} from '../../store/actions/setting-tier';
import { updateTierPoolWithdrawalFee } from '../../store/actions/staking-pool';
import { getExistLPTokens, getPoolLPStatus } from '../../utils/token';
import useTierPooldWithdrawFee from './hook/useTierPooldWithdrawFee';
import WithdrawalFreeForm from './RewardPoolLP/WithdrawalFreeForm';
import WithdrawalPeriodLimitForm from './RewardPoolLP/WithdrawalPeriodLimitForm';
import WithDrawFeeSetting from './RewardPoolLP/WithDrawFeeSetting';
import SetupTier from './SetupTier';
import PoolFormDialog from './shared/Dialog';
import StakeLPList from './StakeLPList';
import useStyles from './style';
import TierStaking from './TierStaking';
interface withdrawalFee {
  aNumber: number;
  bNumber: number;
  cNumber: number;
}

interface withdrawalPeriodLimit {
  withDrawFeeTime: number;
}

const mapTierArrayToTierObject = (tiers: any[]) => {
  return tiers.reduce((tierListObj: { [key: string | number]: any }, tier: any, i: any) => {
    tierListObj[tier.id] = { label: tier.name, min: tier.min };
    return tierListObj;
  }, {});
};

const mapStakeDataToObject = (stakeData: any, stakable: any) => {
  return {
    stakes: {
      address: stakeData.contract_address,
      stakeAmount: {
        amount: stakeData.total_staked,
        value: 10_000,
      },
      participantsNum:
        Number(stakeData.legend_users) +
        Number(stakeData.starter_users) +
        Number(stakeData.rookie_users),
      stakable,
    },

    userNum: {
      starter: stakeData.starter_users,
      rookie: stakeData.rookie_users,
      legend: stakeData.legend_users,
    },
  };
};

const StakingPoolTier: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPermission = usePermission([ROLE_ADMIN.ADMIN_POOL_NO_REWARD, ROLE_ADMIN.SUPPER_ADMIN])
  
  const [loading, setLoading] = useState(true);
  const [loadingTiers, setLoadingTiers] = useState(false);
  const [isSyncPoolLP, setIsSyncPoolLP] = useState(false);
  const [isFetchedData, setIsFetchData] = useState(false);
  const [tiers, setTiers] = useState({});
  const { currentNetworkId } = useSelector((state: any) => state).userCurrentNetwork;
  const tierIdList = useMemo(() => {
    const [starterId, rookieId, legendId] = Object.keys(tiers);
    return {
      starter: starterId,
      rookie: rookieId,
      legend: legendId,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchedData]);
  const [stakingPools, setStakingPools] = useState<any>({});
  const [stakeLPList, setStakeLPList] = useState<any[]>([]);
  const [existTokens, setExistTokens] = useState([]);

  const [tierPooldlwithdrawFee, setTierPooldwithdrawFee] = useState<any>();
  const [showWithDrawFeeModal, setShowWithDrawFeeModal] = useState<boolean>(false);
  const [showWithDrawPeriodLimitModal, setShowWithDrawPeriodLimitModal] = useState<boolean>(false);
  const { loading: loadingTierPooldWithdrawFee, fetchTierPooldWithdrawFee } = useTierPooldWithdrawFee();

  const getTiersList = async () => {
    setLoadingTiers(true);
    try {
      const resObject = await getTiersSetting();

      const { data } = resObject;

      setTiers(mapTierArrayToTierObject(data));
      setIsFetchData(true);
    } catch (err) {
      dispatch(alertFailure('Fetch tiers failed!!'));
    } finally {
      setLoadingTiers(false);
    }
  };

  const getPoolTierStaking = async () => {
    setLoading(true);
    try {
      const currentNetworkId = await getCurrentNetWorkWeb3();
      
      const [{ data }, isStakable] = await Promise.all([getPoolTiers(), getPoolLPStatus(currentNetworkId?.toString())]);
      
      setStakingPools(mapStakeDataToObject(data, isStakable));
    } catch (err) {
      dispatch(alertFailure('Failed to fetch pool LP data!!'));
    } finally {
      setLoading(false);
    }
  };

  const getPoolLPList = async () => {
    setIsSyncPoolLP(true);
    try {
      const resObject = await getPoolList();
      const { data } = resObject;
      const LPList = data.map((stakeLP: any, i: number) => {
        return {
          id: i,
          tokenAddress: stakeLP.token_address,
          totalStake: stakeLP.total_staked,
          totalParticipant: stakeLP.participants,
          conversionRate: stakeLP.conversion_rate,
          tokenSymbol: stakeLP.token_symbol,
          contractAddress: stakeLP.contract_address,
          image: stakeLP.image,
          status: stakeLP.status,
        };
      });

      setStakeLPList(LPList);
    } catch (err) {
      dispatch(alertFailure('Fetch pool LP failed!!'));
    } finally {
      setIsSyncPoolLP(false);
    }
  };

  const getExistTokens = async () => {
    try {
      const currentNetworkId = await getCurrentNetWorkWeb3();
      const tokens = await getExistLPTokens(currentNetworkId);
      
      setExistTokens(tokens as any);
    } catch (error: any) {
      dispatch(alertFailure(error.message));
    }
  };

  const syncPoolLPList = async () => {
    setIsSyncPoolLP(true);
    try {
      await refreshPoolList();
      await getPoolLPList();
    } catch (error) {
      dispatch(alertFailure('Refresh failed!!'));
    } finally {
      setIsSyncPoolLP(false);
    }
  };

  const updateTiers = async (form: any) => {
    const updateTierData = [
      { id: Number(tierIdList.starter), min: Number(form.starter) },
      { id: Number(tierIdList.rookie), min: Number(form.rookie) },
      { id: Number(tierIdList.legend), min: Number(form.legend) },
    ];
    setLoadingTiers(true);
    try {
      const { data } = await updateTiersSetting(updateTierData);
      setTiers(mapTierArrayToTierObject(data));
    } catch (err) {
      dispatch(alertFailure('Updated tiers failed!!'));
    } finally {
      setLoadingTiers(false);
    }
  };

  const updatePoolLP = async (form: any, errorMessage: string = '', isCreate: boolean = true) => {
    const sendData = isCreate
      ? {
          token_address: form.tokenAddress,
          token_symbol: form.tokenSymbol,
          token_decimal: form.maximumDecimal,
          icon: form.icon,
          conversion_rate: Number(form.conversionRate),
          status: true,
        }
      : form;
    const updater = isCreate ? deployStakingPoolLP : editStakingPoolLP;

    setIsSyncPoolLP(true);
    try {
      await dispatch(updater({ data: sendData }));
      await refreshPoolList();
      await getPoolLPList();
    } catch (error) {
      dispatch(alertFailure(errorMessage));
    } finally {
      setIsSyncPoolLP(false);
    }
  };

  const createPoolLp = async (form: any) => {
    await updatePoolLP(form, 'Create pool LP failed!!');
  };

  const changeTokenStatus = async (form: any) => {
    await updatePoolLP(form, 'Edit pool LP failed!!', false);
  };

  const changePoolLPStatus = async () => {
    try {
      setLoading(true);
      const isNotStakable = !stakingPools.stakes?.stakable;

      const errorObj = await dispatch(changeStakePoolLPStatus(isNotStakable));
      if (!!errorObj) {
        throw errorObj;
      }

      setStakingPools({
        ...stakingPools,
        stakes: { ...stakingPools.stakes, stakable: isNotStakable },
      });
    } catch (error) {
      dispatch(alertFailure('Update pool LP failed!!'));
    } finally {
      setLoading(false);
    }
  };

  const fetchWithdrawalFee = async () => {
    try {
      const tierWithdrawFee = await fetchTierPooldWithdrawFee();  
      setTierPooldwithdrawFee(tierWithdrawFee);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPoolLPList();
    getTiersList();
    getPoolTierStaking();
    getExistTokens();
    fetchWithdrawalFee()
  }, []);

  const handleUpdateWithdrawalFee = (data: withdrawalFee) => {
    dispatch(updateTierPoolWithdrawalFee(data, fetchWithdrawalFee));
  };

  const handleUpdateWithdrawalPeriodLimit = (data: withdrawalPeriodLimit) => {
    dispatch(updateTierPoolWithdrawalFee(data, fetchWithdrawalFee,false));
  };

  const checkIsPolygon = useMemo(() => {
    if(currentNetworkId) {
      return CHAIN_ID_NAME_MAPPING[currentNetworkId]?.toLowerCase()?.includes('polygon')
    } else return true
  },[currentNetworkId])

  return (
    <DefaultLayout>
      {loadingTiers ? (
        <>
          <h4 className={classes.sectionTitle}>Set up tier minimum</h4>
          {[...Array(4)].map((_, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={'100%'} />
            </div>
          ))}
        </>
      ) : (
        <SetupTier stakes={tiers} onEdit={updateTiers} tierIdList={tierIdList} isPermission={isPermission} isPolygon={checkIsPolygon}/>
       
      )}
      
      {loading ? (
        <>
          <h4 className={classes.sectionTitle}>Tier Staking CODE</h4>
          {[...Array(5)].map((_, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={'100%'} />
            </div>
          ))}
        </>
      ) : (
        <TierStaking {...stakingPools} onChangeLPStatus={changePoolLPStatus} isPermission={isPermission} isPolygon={checkIsPolygon}/>
   
      )}

      {
        loadingTierPooldWithdrawFee ? (
          <>
          <h4 className={classes.sectionTitle}>Tier Staking CODE</h4>
          {[...Array(5)].map((_, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={'100%'} />
            </div>
          ))}
        </>
      ) : (
        <>
         <h4 className={classes.sectionTitle}>Tier Staking CODE</h4>
          {tierPooldlwithdrawFee && (
          <WithDrawFeeSetting
            withdrawFee={tierPooldlwithdrawFee}
            openEditModal={() => setShowWithDrawFeeModal(true)}
            openEditDrawPeriodLimitModal={() => setShowWithDrawPeriodLimitModal(true)}
            isPolygon={checkIsPolygon}
        />
        )}
        </>
        )

      }

      {isSyncPoolLP ? (
        <>
          <h4 className={classes.sectionTitle}>Tier Staking LPs</h4>
          {[...Array(10)].map((_, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={'100%'} />
            </div>
          ))}
        </>
      ) : (
        <StakeLPList
          stakeLPList={stakeLPList}
          onRefreshLPList={syncPoolLPList}
          onCreatePoolLP={createPoolLp}
          onChangeTokenStatus={changeTokenStatus}
          existTokens={existTokens}
          isPermission={isPermission}
          isPolygon={checkIsPolygon}
        />
      )}

    <PoolFormDialog
        title={'Update withdrawal fee formula'}
        showModal={showWithDrawFeeModal}
        closeModalHandler={() => setShowWithDrawFeeModal(false)}
        style={{ dialog: classes.dialog, modalTitle: classes.modalTitle }}
      >
        <WithdrawalFreeForm
          rewardPoolwithdrawFee={tierPooldlwithdrawFee}
          onClose={() => setShowWithDrawFeeModal(false)}
          onSubmit={handleUpdateWithdrawalFee}
        />
      </PoolFormDialog>

      <PoolFormDialog
        title={'Update withdrawal fee period limit'}
        showModal={showWithDrawPeriodLimitModal}
        closeModalHandler={() => setShowWithDrawPeriodLimitModal(false)}
        style={{ dialog: classes.dialog, modalTitle: classes.modalTitle }}
      >
        <WithdrawalPeriodLimitForm
          withdrawFee={tierPooldlwithdrawFee}
          onClose={() => setShowWithDrawPeriodLimitModal(false)}
          onSubmit={handleUpdateWithdrawalPeriodLimit}
        />
         </PoolFormDialog>
    </DefaultLayout>
  );
};

export default StakingPoolTier;
