import { POLYGON_CHAIN_ID, KLAYTN_CHAIN_ID } from './../../constants';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { utils, BigNumber } from 'ethers';
import { BigNumber as BigNum } from 'bignumber.js';
import { alertActions } from '../constants/alert';
import { createPool } from '../../request/staking-pool';

import stakingPoolABI from '../../abi/Staking/StakingPool.json';
// import stakingPoolRewardABI from '../../abi/Staking/stakeRewardABI.json';
import stakingPoolRewardABI from '../../abi/Staking/PoolRewardABI.json';
import stakingPoolNoRewardABI from '../../abi/Staking/PoolNoRewardABI.json';
import { getContractInstance, getWeb3Instance } from '../../services/web3';
import { alertFailure, alertSuccess } from './alert';
import { REWARD_POOL_KEY } from '../../constants';
import { getRewardPoolAdress } from '../../utils/contractAddress/getAddresses';
import { getGasFeePolygon } from '../../utils/blockchain';
import Web3 from 'web3';

const duration = {
  seconds: function (val: Number | String) {
    return BigNumber.from(val);
  },
  minutes: function (val: Number | String) {
    return BigNumber.from(val).mul(this.seconds('60'));
  },
  hours: function (val: Number | String) {
    return BigNumber.from(val).mul(this.minutes('60'));
  },
  days: function (val: Number | String) {
    return BigNumber.from(val).mul(this.hours('24'));
  },
  weeks: function (val: Number | String) {
    return BigNumber.from(val).mul(this.days('7'));
  },
  years: function (val: Number | String) {
    return BigNumber.from(val).mul(this.days('365'));
  },
};

export const deployStakingPool = (stakingPool: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {

      const poolType = stakingPool.staking_type;
      let stakingPoolContract = getContractInstance(stakingPoolABI, stakingPool.pool_address || '', true, 'write');

      if (!stakingPoolContract) {
        dispatch({
          type: alertActions.ERROR_MESSAGE,
          payload: 'Invalid staking pool address',
        });
        return;
      }

      const userWalletAddress = getState().user.data.wallet_address;
      let payload;  
      const currentNetworkId = localStorage.getItem("NETWORK_ID");
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        const web3Instance = await getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        if (gasPrice) {
          payload = {
            maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
            from: userWalletAddress,
         }
        } else {
          payload = {
            from: userWalletAddress,
            gas: 8000000,
         }
        }
      } else {
          payload = {
              from: userWalletAddress,
              gas: 8000000,
          }
        } 
      switch (poolType) {
        case 'linear':
          let newLinearPool = await stakingPoolContract.methods
            .linearAddPool(
              utils.parseEther(stakingPool.cap), // the maximum number of staking tokens the pool will receive
              utils.parseEther(stakingPool.min_investment), // the minimum investment amount users need to use in order to join the pool
              utils.parseEther(stakingPool.max_investment), // the maximum investment amount users can deposit to join the pool
              stakingPool.APR, // the APR rate
              duration.days(stakingPool.lock_duration).toNumber(), // the duration users need to wait before being able to withdraw and claim the rewards
              duration.days(stakingPool.delay_duration).toNumber(), // the duration users need to wait to receive the principal amount, after unstaking from the pool
              stakingPool.start_join_pool_time, // the time when users can start to join the pool
              stakingPool.end_join_pool_time, // the time when users can no longer join the pool
            )
            .send({
              ...payload
            });

          console.log('Deploy Response: ', newLinearPool);
          if (newLinearPool) {
            dispatch({
              type: alertActions.SUCCESS_MESSAGE,
              payload: 'Deploy Staking Pool Successful!',
            });

            const poolId = newLinearPool?.events?.LinearPoolCreated?.returnValues?.poolId;

            const createData = {
              pool_id: poolId,
              pool_address: stakingPool.pool_address,
              network_available: stakingPool.network_available,
              staking_type: 'linear',
              title: stakingPool.title,
              logo: stakingPool.logo,
              website: stakingPool.website,
              rkp_rate: stakingPool.rkp_rate,
              accepted_token_price: stakingPool.accepted_token_price,
              reward_token_price: stakingPool.reward_token_price,
            };

            await createPool(createData);
            window.location.replace('/#/dashboard/staking');
          }
          break;
        case 'alloc':
          let newAllocPool = await stakingPoolContract.methods
            .allocAddPool(
              stakingPool.alloc_point, // the allocation point of the pool, used when calculating total reward the whole pool will receive each block
              stakingPool.token, // the token which this pool will accept
              duration.days(stakingPool.delay_duration).toNumber(), // the duration user need to wait when withdraw
            )
            .send({
              ...payload
            });

          console.log('Deploy Response: ', newAllocPool);
          if (newAllocPool) {
            dispatch({
              type: alertActions.SUCCESS_MESSAGE,
              payload: 'Deploy Staking Pool Successful!',
            });

            const poolId = newAllocPool?.events?.AllocPoolCreated?.returnValues?.pid;

            const createData = {
              pool_id: poolId,
              pool_address: stakingPool.pool_address,
              network_available: stakingPool.network_available,
              staking_type: 'alloc',
              title: stakingPool.title,
              logo: stakingPool.logo,
              website: stakingPool.website,
              rkp_rate: stakingPool.rkp_rate,
              accepted_token_price: stakingPool.accepted_token_price,
              reward_token_price: stakingPool.reward_token_price,
            };

            await createPool(createData);
            window.location.replace('/#/dashboard/staking');
          }
          break;

        default:
          dispatch({
            type: alertActions.ERROR_MESSAGE,
            payload: 'Invalid staking pool type',
          });
      }
    } catch (err: any) {
      console.log('ERROR: ', err);

      // dispatch({
      //   type: campaignActions.MY_CAMPAIGN_CREATE_FAIL,
      //   payload: err.message
      // });

      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });
    }
  };
};

export const updateStakingPool = (stakingPool: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      const poolType = stakingPool.staking_type;
      let stakingPoolContract = getContractInstance(stakingPoolABI, stakingPool.pool_address || '', true, 'write');

      if (!stakingPoolContract) {
        dispatch({
          type: alertActions.ERROR_MESSAGE,
          payload: 'Invalid staking pool address',
        });
        return;
      }

      const userWalletAddress = getState().user.data.wallet_address;
      let payload;  
      const currentNetworkId = localStorage.getItem("NETWORK_ID");
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        const web3Instance = await getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        if (gasPrice) {
          payload = {
            maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
            from: userWalletAddress,
          }
        } else {
          payload = {
            from: userWalletAddress,
            gas: 8000000,
         }
        }
      } else {
          payload = {
              from: userWalletAddress,
              gas: 8000000,
          }
        } 
      switch (poolType) {
        case 'linear':
          let newLinearPool = await stakingPoolContract.methods
            .linearSetPool(
              stakingPool.pool_id,
              utils.parseEther(stakingPool.cap), // the maximum number of staking tokens the pool will receive
              utils.parseEther(stakingPool.min_investment), // the minimum investment amount users need to use in order to join the pool
              utils.parseEther(stakingPool.max_investment), // the maximum investment amount users can deposit to join the pool
              stakingPool.APR, // the APR rate
              stakingPool.end_join_pool_time, // the time when users can no longer join the pool
            )
            .send({
              ...payload
            });

          console.log('Update Response: ', newLinearPool);
          if (newLinearPool) {
            dispatch({
              type: alertActions.SUCCESS_MESSAGE,
              payload: 'Update Staking Pool Successful!',
            });
          }
          break;
        case 'alloc':
          let newAllocPool = await stakingPoolContract.methods
            .allocSetPool(
              stakingPool.pool_id,
              stakingPool.alloc_point, // the allocation point of the pool, used when calculating total reward the whole pool will receive each block
              duration.days(stakingPool.delay_duration).toNumber(), // the duration user need to wait when withdraw
            )
            .send({
              ...payload
            });

          console.log('Deploy Response: ', newAllocPool);
          if (newAllocPool) {
            dispatch({
              type: alertActions.SUCCESS_MESSAGE,
              payload: 'Update Staking Pool Successful!',
            });
          }
          break;

        default:
          dispatch({
            type: alertActions.ERROR_MESSAGE,
            payload: 'Invalid staking pool type',
          });
      }
    } catch (err: any) {
      console.log('ERROR: ', err);

      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });
    }
  };
};

export const updateRewardPool = (value: any, callBackFunc: () => void, key: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      const { currentNetworkId } = getState().userCurrentNetwork;
      const POOL_ADDRESS = getRewardPoolAdress(currentNetworkId?.toString() || '') as string;

      let contract = getContractInstance(stakingPoolRewardABI, POOL_ADDRESS, true, 'write');

      const userWalletAddress = getState().user.data.wallet_address;
      let payload;  
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        const web3Instance = await getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice(); 
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        if (gasPrice) {
          payload = {
            maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
            from: userWalletAddress,
          }
        } else {
          payload = {
            from: userWalletAddress,
            gas: 8000000,
          }
        }
      } else {
          payload = {
              from: userWalletAddress,
              gas: 8000000,
          }
        } 
      if (contract) {
        switch (key) {
          case REWARD_POOL_KEY.APR:
            const aprUnit128 = new BigNum(value).multipliedBy(Math.pow(10, 2)).toString();

            await contract.methods.changeAPR(aprUnit128).send({
              ...payload
            });
            break;
          case REWARD_POOL_KEY.CAPACITY:
            const capacityUnit256 = new BigNum(value).multipliedBy(Math.pow(10, 18)).toString();
            await contract.methods.setMaxTotalStake(capacityUnit256).send({
              ...payload
            });
            break;
          case REWARD_POOL_KEY.MIN:
          case REWARD_POOL_KEY.MAX:
            const minUnit256 = new BigNum(value.min).multipliedBy(Math.pow(10, 18)).toString();
            const maxUnit256 = new BigNum(value.max).multipliedBy(Math.pow(10, 18)).toString();
            await contract.methods.setMinMaxDeposit(minUnit256, maxUnit256).send({
              ...payload
            });
            break;
        }
        callBackFunc();
        dispatch(alertSuccess('Updated successfully!'));
      }
    } catch (err: any) {
      if (err.code === 4001) {
        dispatch(alertFailure('Transaction rejected!'));
      } else {
        dispatch(alertFailure(err.message));
      }
    }
  };
};

export const updateRewardPoolWithdrawalFee = (
  value: any,
  callBackFunc: () => void,
  isUpdateFee = true,
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      const { currentNetworkId } = getState().userCurrentNetwork;
      let CONTRACT_STAKING_POOL_ADDRESS = '';
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        CONTRACT_STAKING_POOL_ADDRESS =
          process.env.REACT_APP_POLYGON_REWARD_STAKING_POOL_ADDRESS || '';
      } else if (currentNetworkId === KLAYTN_CHAIN_ID) {
        CONTRACT_STAKING_POOL_ADDRESS =
          process.env.REACT_APP_KLAYTN_REWARD_STAKING_POOL_ADDRESS || '';
      }

      let contract = getContractInstance(stakingPoolRewardABI, CONTRACT_STAKING_POOL_ADDRESS, true, 'write');

      const userWalletAddress = getState().user.data.wallet_address;

      if (contract) {
        let payload;  
        if (currentNetworkId === POLYGON_CHAIN_ID) {
          const web3Instance = await getWeb3Instance();
          const gasPrice = await web3Instance?.eth.getGasPrice();
          const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
          if (gasPrice) {
            payload = {
              maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
              from: userWalletAddress,
            }
          } else {
            payload = {
              from: userWalletAddress,
              gas: 8000000,
            }
          }
        } else {
            payload = {
                from: userWalletAddress,
                gas: 8000000,
            }
          } 
        if (isUpdateFee) {
          const { aNumber, bNumber, cNumber } = value;
          let aNumberConvert =
            parseFloat(aNumber) === 0
              ? 0
              : new BigNum(parseFloat(aNumber))
                  .multipliedBy(10 ** 6)
                  .toFixed(0)
                  .toString();
          let bNumberConvert =
            parseFloat(bNumber) === 0
              ? 0
              : new BigNum(parseFloat(bNumber))
                  .multipliedBy(10 ** 6)
                  .toFixed(0)
                  .toString();
          let cNumberConvert =
            parseFloat(cNumber) === 0
              ? 0
              : new BigNum(parseFloat(cNumber))
                  .multipliedBy(10 ** 6)
                  .toFixed(0)
                  .toString();
          await contract.methods
            .setWithdrawFeeParam(aNumberConvert, bNumberConvert, cNumberConvert)
            .send({
              ...payload
            });
        } else {
          const { withDrawFeeTime } = value;
          const dataValue = new BigNum(withDrawFeeTime).multipliedBy(86400).toString();
          await contract.methods.setNoWithdrawFeeTime(dataValue).send({
            ...payload
          });
        }
        callBackFunc();
        dispatch(alertSuccess('Updated successfully!'));
      }
    } catch (err: any) {
      if (err.code === 4001) {
        dispatch(alertFailure('Transaction rejected!'));
      } else {
        dispatch(alertFailure(err.message));
      }
    }
  };
};

export const updateTierPoolWithdrawalFee = (
  value: any,
  callBackFunc: () => void,
  isUpdateFee = true,
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      const { currentNetworkId } = getState().userCurrentNetwork;
      let CONTRACT_STAKING_POOL_ADDRESS = '';
      if (currentNetworkId === POLYGON_CHAIN_ID) {
        CONTRACT_STAKING_POOL_ADDRESS =
          process.env.REACT_APP_POLYGON_NO_REWARD_STAKING_POOL_ADDRESS || '';
      } else if (currentNetworkId === KLAYTN_CHAIN_ID) {
        CONTRACT_STAKING_POOL_ADDRESS =
          process.env.REACT_APP_KLAYTN_NO_REWARD_STAKING_POOL_ADDRESS || '';
      }

      let contract = getContractInstance(stakingPoolNoRewardABI, CONTRACT_STAKING_POOL_ADDRESS, true, 'write');

      const userWalletAddress = getState().user.data.wallet_address;

      if (contract) {
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        let payload;  
        if (currentNetworkId === POLYGON_CHAIN_ID) {
          const web3Instance = await getWeb3Instance();
          const gasPrice = await web3Instance?.eth.getGasPrice();
            if (gasPrice) {
              payload = {
                maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                from: userWalletAddress,
            };
            } else {
              payload = {
                from: userWalletAddress,
                gas: 8000000,
            }
            }
        } else {
            payload = {
                from: userWalletAddress,
                gas: 8000000,
            }
          } 
        if (isUpdateFee) {
          const { aNumber, bNumber, cNumber } = value;
          let aNumberConvert =
            parseFloat(aNumber) === 0
              ? 0
              : new BigNum(parseFloat(aNumber))
                  .multipliedBy(10 ** 6)
                  .toFixed(0)
                  .toString();
          let bNumberConvert =
            parseFloat(bNumber) === 0
              ? 0
              : new BigNum(parseFloat(bNumber))
                  .multipliedBy(10 ** 6)
                  .toFixed(0)
                  .toString();
          let cNumberConvert =
            parseFloat(cNumber) === 0
              ? 0
              : new BigNum(parseFloat(cNumber))
                  .multipliedBy(10 ** 6)
                  .toFixed(0)
                  .toString();
          await contract.methods
            .setWithdrawFeeParam(aNumberConvert, bNumberConvert, cNumberConvert)
            .send({
              ...payload
            });
        } else {
          const { withDrawFeeTime } = value;
          const dataValue = new BigNum(withDrawFeeTime).multipliedBy(86400).toString();
          await contract.methods.setNoWithdrawFeeTime(dataValue).send({
            ...payload
          });
        }
        callBackFunc();
        dispatch(alertSuccess('Updated successfully!'));
      }
    } catch (err: any) {
      if (err.code === 4001) {
        dispatch(alertFailure('Transaction rejected!'));
      } else {
        dispatch(alertFailure(err.message));
      }
    }
  };
};
