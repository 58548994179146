import { BaseRequest } from './Request';

export const getPoolTiers = async () => {
  const baseRequest = new BaseRequest();

  let url = '/api/v1/admin/pools/no-reward/code';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getTiers = async (campaignId: any) => {
  const baseRequest = new BaseRequest();

  let url = `/pool/${campaignId}/tiers`;
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getTiersSetting = async () => {
  const baseRequest = new BaseRequest();

  let url = `/api/v1/tiers`;
  const response = (await baseRequest.get(url)) as any;

  if (response.status === 404) {
    throw new Error('Not found');
  }

  const resObject = await response.json();

  return resObject;
};

export const updateTiersSetting = async (data: any) => {
  const baseRequest = new BaseRequest();

  let url = `/api/v1/tiers/update`;
  const response = (await baseRequest.put(url, data)) as any;
  if (response.status === 404) {
    throw new Error('Not found');
  }

  const resObject = await response.json();
  return resObject;
};
