import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { CHAIN_ID_NAME_MAPPING } from '../../../constants';
import useStyles from '../styleTable';

const RolesAndPermissions: React.FC<any> = () => {
  const classes = useStyles();
  const { currentNetworkId } = useSelector((state: any) => state.userCurrentNetwork);
  const checkIsKlaytn = () => {
    return CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes('klaytn');
  };

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2}>Roles</TableCell>
          <TableCell colSpan={6} className={classes.tableCellHeader}>
            Permission
          </TableCell>
        </TableRow>
        <TableRow>
          {checkIsKlaytn() ? (
            <>
              <TableCell>Factory Pool</TableCell>
              <TableCell>Vesting</TableCell>
            </>
          ) : (
            <>
              <TableCell>Reward Staking</TableCell>
              <TableCell>Tier Staking</TableCell>
              <TableCell>Factory Pool</TableCell>
              <TableCell>Ticket</TableCell>
              <TableCell>Vesting</TableCell>
              <TableCell>IDO Chainlink</TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        <TableRow>
          <TableCell component="th">Super Admin</TableCell>
          {checkIsKlaytn() ? (
            <>
              <TableCell>
                Create IDO Pool <br />
                Update IDO pool
                <br />
                Add Admin <br />
                Remove Admin
              </TableCell>
              <TableCell>
                Update vesting
                <br />
                Add Admin
                <br />
                Remove Admin
              </TableCell>
            </>
          ) : (
            <>
              <TableCell>
                Update Pool <br />
                Add Admin <br />
                Remove Admin
              </TableCell>
              <TableCell>
                Update Pool <br />
                Add Admin <br />
                Remove Admin
              </TableCell>
              <TableCell>
                Create IDO Pool <br />
                Update IDO pool
                <br />
                Add Admin <br />
                Remove Admin
              </TableCell>
              <TableCell>
                Add
                <br />
                Remove Admin
              </TableCell>
              <TableCell>
                Update vesting
                <br />
                Add Admin
                <br />
                Remove Admin
              </TableCell>
              <TableCell>
                Random winning ticket
                <br />
                Add Admin
                <br />
                Remove Admin
              </TableCell>
            </>
          )}
        </TableRow>
        <TableRow>
          <TableCell component="th">Admin</TableCell>
          {checkIsKlaytn() ? (
            <>
              <TableCell>
                Create IDO Pool <br />
                Update IDO pool
              </TableCell>
              <TableCell>Update vesting</TableCell>
            </>
          ) : (
            <>
              <TableCell>Update Pool</TableCell>
              <TableCell>Update Pool</TableCell>
              <TableCell>
                Create IDO Pool <br />
                Update IDO pool
              </TableCell>
              <TableCell></TableCell>
              <TableCell>Update vesting</TableCell>
              <TableCell>Random winning ticket</TableCell>
            </>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default RolesAndPermissions;
