import React, {useEffect, useState} from 'react';
import useStyles from "../style";
import FormControl from '@material-ui/core/FormControl';
import {Controller} from "react-hook-form";
import {renderErrorCreatePool} from "../../../utils/validate";
import {Switch} from 'antd';
import {changeDisplayStatus} from "../../../request/pool";
import {alertSuccess} from "../../../store/actions/alert";
import {withRouter} from "react-router";
import {useDispatch} from "react-redux";

function DisplayPoolSwitch(props: any) {
  const classes = useStyles();
  const {
    setValue, errors, control,
    poolDetail, isViewDetail
  } = props;
  const renderError = renderErrorCreatePool;
  const dispatch = useDispatch();

  useEffect(() => {
    if (poolDetail && (poolDetail.is_display != undefined)) {
      setValue('is_display', !!poolDetail.is_display);
    }
  }, [poolDetail]);


  return (
    <>
      <div><label className={classes.formControlLabel}>Display</label></div>
      <div style={{color: 'red'}}>Users will not see Campaigns while the campaign is in the hidden state</div>
      <FormControl component="fieldset">
        <Controller
          control={control}
          name="is_display"
          render={(field) => {
            const { value, onChange } = field;
            return (
              <Switch
                onChange={ (switchValue) => {
                  // eslint-disable-next-line no-restricted-globals
                  // if (!confirm('Do you want change display ?')) {
                  //   return false;
                  // }
                   onChange(switchValue);
                }}
                checked={value || false}
                checkedChildren="Display"
                unCheckedChildren="Hidden"
                disabled={isViewDetail}
              />
            )
          }}
        />

        <p className={classes.formErrorMessage}>
          {
            renderError(errors, 'is_display')
          }
        </p>
      </FormControl>
      <br/>
    </>
  );
}

export default withRouter(DisplayPoolSwitch);
