import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  btnSubmit: {
    background: '#FFCC00',
    boxShadow: '0px 0px 15px rgba(243, 203, 25, 0.3)',
    borderRadius: 8,
    height: 40,
    minWidth: 92,
    fontSize: 16,
    lineHeight: 160,
    alignItems: 'center',
    color: '#FFFFFF',
    textTransform: 'inherit',
    overflow: 'hidden',
    marginRight: 20,
    padding: '0 20px',
    '&:hover': {
      background: '#FFCC00',
      opacity: 0.8,
    }, 
    '& .Mui-disabled': {
      boxShadow: 'none',
      background: '#dddddd'
    },
    '&:disabled':{
      background:'#9A9A9A',
      color:'#fff',
      boxShadow: 'none',
      cursor:'not-allowed'
    }
  },
}));

export default useStyles;
