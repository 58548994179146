import React from 'react';
import useStyles from "../style";
import {useCommonStyle} from "../../../styles";
import {etherscanRoute} from "../../../utils";
import Link from "@material-ui/core/Link";

function PoolHash(props: any) {
  const classes = useStyles();
  const commonStyle = useCommonStyle();
  const {
    poolDetail, isViewDetail
  } = props;

  return (
    <>
      {
        poolDetail?.contract_address && (
          <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Pool Contract Address</label>
          <div className={commonStyle.boldText}>
            {!!poolDetail?.is_deploy &&
              <Link href={etherscanRoute(poolDetail?.contract_address, poolDetail)} target={'_blank'}>
                {poolDetail?.contract_address}
              </Link>
            }
            {!poolDetail?.is_deploy && '--'}
          </div>
          {/*<input*/}
          {/*  type="text"*/}
          {/*  name="title"*/}
          {/*  defaultValue={poolDetail?.campaign_hash}*/}
          {/*  maxLength={255}*/}
          {/*  className={classes.formControlInput}*/}
          {/*  disabled*/}
          {/*/>*/}
        </div>
        )
      }  
    </>
  );
}

export default PoolHash;
