import React, { useEffect } from 'react';
//@ts-ignore
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  HashRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';
import AppContainer from './AppContainer';
import ErrorBoundary from './components/Base/ErrorBoundary';
import PrivateRoute from './components/Base/PrivateRoute';
import AdminList from './pages/AdminList';
import ComingSoon from './pages/ComingSoon/ComingSoon';
import ErrorPage from './pages/ErrorPage';
import KycUserList from './pages/KycUserList/KycUserList';
import Login from './pages/Login';
import NetworkChange from './pages/NetworkChange';
import NotFoundPage from './pages/NotFoundPage';
import PoolEdit from './pages/PoolCreate/PoolEdit';
import Pools from './pages/Pools';
import StakingPoolReward from './pages/StakingPools/StakingPoolReward';
import StakingPoolTier from './pages/StakingPools/StakingPoolTier';

import IDOLayoutDetailV2 from './pages/IDODetails/IDOLayoutDetailV2';
import PoolCreateV2 from './pages/PoolCreate/PoolCreateV2';
import { clearAlert } from './store/actions/alert';
import { adminRoute } from './utils';
import { CHAIN_ID_NAME_MAPPING } from './constants';

/**
 * Main App routes.
 */
const Routes: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const alert = useSelector((state: any) => state.alert);
  const { history } = props;
  const currentNetworkId = localStorage.getItem("NETWORK_ID");
  useEffect(() => {
    const { type, message } = alert;
    if (type && message) {
      NotificationManager[type](message);
    }
  }, [alert]);

  useEffect(() => {
    history.listen((location, action) => {
      dispatch(clearAlert());
    });
  }, []);

  const checkNetworkAllowDeploy = () => {
    if(currentNetworkId) {
      return CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes('klaytn');
    } else {
      return false;
    }
  } 

  return (
    <div>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={`${adminRoute('/campaigns')}`} />} />
        <PrivateRoute exact path={adminRoute()} component={Pools} />

        <PrivateRoute path={adminRoute('/campaigns')} exact component={Pools} />
        {/* <PrivateRoute path={adminRoute('/campaigns/add')} exact component={PoolCreate} /> */}
        <PrivateRoute path={adminRoute('/campaigns/add')} exact component={PoolCreateV2} />
        {/* <PrivateRoute exact path={adminRoute('/campaign-detail/:id')} component={IDODetails} /> */}
        <PrivateRoute exact path={adminRoute('/campaign-detail/:id')} component={IDOLayoutDetailV2} />
        <PrivateRoute exact path={adminRoute('/campaigns/edit/:id')} component={PoolEdit} />

        {/* <PrivateRoute path={adminRoute('/campaigns/add/v2')} exact component={PoolCreateV2} /> */}
        {/* <PrivateRoute exact path={adminRoute('/campaign-detail/v2/:id')} component={IDOLayoutDetailV2} /> */}

        <Route path={adminRoute('/login')} component={Login} />
        <Route path={adminRoute('/admins')} component={AdminList} />
        <Route path={adminRoute('/kyc-users')} component={KycUserList} />

        {!checkNetworkAllowDeploy() && <PrivateRoute path={adminRoute('/tier-stake')} exact component={StakingPoolTier} />}
        {!checkNetworkAllowDeploy() && <PrivateRoute path={adminRoute('/reward-stake')} exact component={StakingPoolReward} />}
        <Route path={adminRoute('/network-change')} component={NetworkChange} />
        <Route path={'/coming-soon'} component={ComingSoon} />
        <PrivateRoute exact path={adminRoute('/error')} component={ErrorPage} />
        <PrivateRoute exact path={adminRoute('/page-not-found')} component={NotFoundPage} />
      </Switch>
    </div>
  );
};

const RoutesHistory = withRouter(Routes);

const routing = function createRouting() {
  return (
    <>
      <NotificationContainer />
      <Router>
        <AppContainer>
          <ErrorBoundary>
            <RoutesHistory />
          </ErrorBoundary>
        </AppContainer>
      </Router>
    </>
  );
};
/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
