import { Box, Grid, Typography } from '@material-ui/core';
import { getTimeDuration } from '../../../utils/timeLock';
import useStyles from './styles';

const VestingPolicy = (props: any) => {
  const { poolDetail } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.spacingBlock}>
      <Grid item xs={3}>
        <Typography className={classes.titleField}>Vesting Policy</Typography>
      </Grid>
      <Grid item xs={9}>
        <Box className={classes.ticketingContent}>
          <Typography>TGE {poolDetail.TGE ?? '--'}% right after calculation</Typography>
          <br />
          <Typography>Linear vesting in {getTimeDuration(poolDetail.linear_vesting) ?? '--'}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VestingPolicy;
