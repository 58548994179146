import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { adminRoute } from '../../utils';
import PoolForm from './PoolForm';
import { getPoolDetail } from '../../request/pool';
import moment from 'moment';
import BackButton from '../../components/Base/ButtonLink/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import { alertFailure } from '../../store/actions/alert';

const PoolEdit: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const isEdit = true;
  const { match } = props;
  const dispatch = useDispatch();
  const { data: loginUser } = useSelector((state: any) => state.user);
  const [poolDetail, setPoolDetail] = useState();

  // @ts-ignore
  const id = match.params?.id;

  const fetchPoolDetailData = (poolId: any) => {
    getPoolDetail(poolId)
      .then(async (res) => {
        const data = res.data;
        const newData = {
          ...data,
          start_staking_snapshot: data?.start_staking_snapshot
            ? moment(data?.start_staking_snapshot)
            : null,
          start_ticket_close: data?.start_ticket_close ? moment(data?.start_ticket_close) : null,
          start_ticket_open: data?.start_ticket_open ? moment(data?.start_ticket_open) : null,
          start_pool_end: data?.start_pool_end ? moment(data?.start_pool_end) : null,
        };

        setPoolDetail(newData);

      console.log('data=>>>>>>>>>>>>>>>>>>>>',data);
      

        return res.data;
      })
      .catch((e) => {
        console.log('Error: ', e);
        dispatch(alertFailure('Pool load fail !!!'));
      });
  };

  useEffect(() => {
    fetchPoolDetailData(id);
  }, [id]);

  return (
    <DefaultLayout>
      <BackButton to={adminRoute('/campaigns')} />
      {poolDetail && (
        <PoolForm
          isEdit={isEdit}
          poolDetail={poolDetail}
          isViewDetail={false}
          fetchPoolDetailData={fetchPoolDetailData}
          poolId={id}
        />
      )}
    </DefaultLayout>
  );
};

export default withRouter(PoolEdit);
