import React, { useEffect, useState } from "react";
import useStyles from "../style";
import { renderErrorCreatePool } from "../../../utils/validate";
import CloseIcon from '@material-ui/icons/Close';
import { MESSAGE_INVALID_URL, REGEX_URL } from "../../../constants";

interface SNS {
  url: string;
}
function IDOSNS(props: any) {
  const classes = useStyles();
  const { register, errors, poolDetail, setValue, isViewDetail } = props;
  const renderError = renderErrorCreatePool;
  const [rows, setRows] = useState<any[]>([]);
  const [snss, setSnss] = useState<SNS>({
    url: "",
  });

  useEffect(() => {
    if(poolDetail){
      setRows(poolDetail.sns)
      setValue('IDOSNS', JSON.stringify(poolDetail.sns));
    }
  },[poolDetail])

  const handleAddRow = (e: any) => {
    e.preventDefault();
    if( !snss.url) return;
    setRows(oldRow => [...oldRow,snss]);
    setValue('IDOSNS', JSON.stringify(rows));

    (document.getElementById('url-sns') as HTMLInputElement).value = '';
  };

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setSnss((values) => ({ ...values, [name]: value }));
   
  };

  const handleRemoveRow = (indexRow:number) => {
    if (!window.confirm('Do you want delete this record ?')){
      return false;
    }
    const newRows = rows.filter((item: any, index: number) => index !== indexRow);

    setRows(newRows);
    setValue('IDOSNS', JSON.stringify(newRows));
  }

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>SNS</label>
        <input
          type="hidden"
          name="IDOSNS"
          value={JSON.stringify(rows)}
          ref={register({
            required: true
          })}
        />  
        <ul className={classes.SNS}>
          {rows && rows.length > 0 &&
            rows.map((row, index) => (
              <li key={`${row.url}-${index}`} className={classes.SNSItem}>
                <img  src={row.url} />
                <button
                  className={`${classes.SNSRemove} remove ${isViewDetail && classes.displayNone}`}
                  onClick={() => handleRemoveRow(index)}
                >
                  <CloseIcon/>
                </button>
              </li>
            ))}
        </ul>

        {rows?.length < 20 && 
          <form onSubmit={handleAddRow} className={isViewDetail ? classes.displayNone : ""}>
            <div className={classes.formControlWrap}>
              <div className={classes.formControlFlexBlock}>

                <div className={classes.formControlRate}>
                  <input
                    type="text"
                    name="url"
                    id="url-sns"
                    onChange={handleChange}
                    ref={register({
                      pattern: {
                        value: REGEX_URL,
                        message: MESSAGE_INVALID_URL,
                      },
                    })}
                    maxLength={255}
                    className={classes.formControlInput}
                  />
                </div>
              </div>

              <span style={{ marginRight: 16, marginTop: 20, display: "block" }}>
                {" "}
              </span>

              <div className={classes.formControlFlexBlock}>
                <button
                  hidden={rows && rows.length >= 20}
                  className={classes.circleButton}
                  style={{ marginRight: 16, marginTop: 15 }}
                  type="submit"
                  disabled={errors.url}
                >
                  <img src="/images/icon_plus.svg" />
                </button>
              </div>
            </div>
            <div className={classes.formControlWrap}>
              <p className={classes.formErrorMessage}>
                {renderError(errors, "url")}
              </p>
            </div>
              <p style={{fontStyle:"italic"}}>*Maximum 20 pictures</p>
          </form>
        }
      </div>
    </>
  );
}

export default IDOSNS;
