import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  select: {
    width: 150,
    height: 40,
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: 8,
    '& .MuiInput-root.MuiInputBase-root': {
      display: 'flex',
    },
    '& .MuiSelect-root': {
      paddingLeft: 10,
      '&:focus': {
        background: 'none',
      },
    },
  },
  list: {
    backgroundColor: '#E5E5E5 !important',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default useStyles;
