import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  tableTitle: {
    paddingTop: '0.5rem',
    paddingRight: '2.5rem',
    textAlign: 'left',
    fontSize: '1rem',
    verticalAlign: 'top',
  },

  table: {
    '& tr': {
      padding: '0.75rem 0',
    },
  },

  formInput: {
    '& > p': {
      margin: '0.75rem 0',
    },
  },
  conversionInput: {
    display: 'flex',
    alignItems: 'flex-start',

    '& > span': {
      marginTop: '0.5rem',
      marginRight: '1rem',
    },
  },

  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '75%',
    margin: '0 auto',
  },

  formInputBox: {
    display: 'block',
    border: '1px solid #DFDFDF',
    flex: 1,
    padding: '13px',
    borderRadius: 5,
    marginTop: 5,
    backgroundColor: 'white',
    transition: '.1s all ease-in',

    '&:focus': {
      outline: 'none',
    },
  },

  formControl:{
    marginBottom:16
  },
  formControlFlex:{
    display:'flex',
    justifyContent:'space-between'
  },
  formLabel:{
    marginRight:10
  },
  formErrorMessage: {
    marginTop: 7,
    color: 'red'
  },
}));

export default styles;
