import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 10px rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: '2rem 2.5rem 3rem',
    marginBottom: '2rem',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  roundTag: {
    display: 'inline-block',
    width: '3rem',
    height: '3rem',
    borderRadius: '50%',
    background: '#818181',
    marginRight: '2rem',
    backgroundSize:'cover'
  },
  nameDiv: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    fontSize: '1.125rem',
  },
  stakeHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  stakeTable: {
    width: '100%',

    '&  th': {
      fontSize: '1rem',
      fontWeight: '700',
    },

    '& td': {
      fontSize: '1.125rem',
    },
  },
  pauseButton: {
    textTransform: 'none',
    padding: '0.5rem 2.5rem',
  },
  pauseButtonToken: {
    textAlign: 'end'
  }
}));

export default useStyles;
