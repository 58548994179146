import React, { useEffect, useState } from 'react';
import useStyles from '../style';
import { renderErrorCreatePool } from '../../../utils/validate';

function TokenSymbol(props: any) {
  const classes = useStyles();
  const { register, errors, poolDetail, isViewDetail } = props;
  const renderError = renderErrorCreatePool;

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>
          Token Symbol (display) <span style={{ color: '#e51d1d' }}>*</span>
        </label>
        <input
          type="text"
          name="token_symbol"
          defaultValue={poolDetail?.token_symbol || ''}
          ref={register()}
          className={classes.formControlInput}
          disabled={isViewDetail}
        />

        <p className={classes.formErrorMessage}>{renderError(errors, 'token_symbol')}</p>
      </div>
    </>
  );
}

export default TokenSymbol;
