import { Button, Paper, TableContainer } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { CHAIN_ID_NAME_MAPPING, ROLE_ADMIN } from '../../constants';
import usePermission from '../../hooks/usePermission';
import { getAdminList } from '../../request/admin';
import {
  addAdminFactoryPool,
  addAdminIDOChainlink,
  addAdminRewardStakePool,
  addAdminTierStakePool,
  addAdminVesting,
} from '../../store/actions/setting-owner';
import {
  getFactoryPoolOwner,
  getRewardPoolOwner,
  getTierPoolOwner,
  getVestingOwner,
} from '../../utils/contractAddress/getAddresses';
import ListAdminTable from './AdminListTable';
import ContractAddressList from './ContractAddressList';
import RolesAndPermissions from './RolesAndPermission';
import ConfirmModal from './shared/Dialog';
import useStyles from './style';

const ManageAdminPage: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isPermission = usePermission([ROLE_ADMIN.SUPPER_ADMIN]);
  const { currentNetworkId } = useSelector((state: any) => state.userCurrentNetwork);

  const [contractAddressData, setContractAddressData] = useState<any>(null);
  const [adminList, setAdminList] = useState<any>(null);
  const [isEditTing, setIsEditing] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [isCollectData, setIsCollectData] = useState(false);
  const checkIsKlaytn = () => {
    return CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes('klaytn');
  };
  const [currentRole, setCurrentRole] = useState(checkIsKlaytn() ? ROLE_ADMIN.ADMIN_FACTORY_POOL : ROLE_ADMIN.ADMIN_POOL_REWARD);
  const store = useSelector((state: any) => state);
  const { roles } = store.user.data
  const getContractAddressOwners = async () => {
    const [
      tierPoolOwnerAddress,
      factoryPoolOwnerAddress,
      rewardPoolOwnerAddress,
      vestingOwnerAddress,
    ] = await Promise.all([
      getTierPoolOwner(currentNetworkId),
      getFactoryPoolOwner(currentNetworkId),
      getRewardPoolOwner(currentNetworkId),
      getVestingOwner(currentNetworkId),
    ]);

    setContractAddressData([
      {
        label: 'Reward Staking',
        contractAddress: rewardPoolOwnerAddress?.address,
        owner: rewardPoolOwnerAddress?.owner,
      },
      {
        label: 'Tier Staking',
        contractAddress: tierPoolOwnerAddress?.address,
        owner: tierPoolOwnerAddress?.owner,
      },
      {
        label: 'Factory Pool',
        contractAddress: factoryPoolOwnerAddress?.address,
        owner: factoryPoolOwnerAddress?.owner,
      },
      // {
      //   label: 'Ticket',
      //   contractAddress: ticketOwnerAddress?.address,
      //   owner: ticketOwnerAddress?.owner,
      // },
      {
        label: 'Vesting',
        contractAddress: vestingOwnerAddress?.address,
        owner: vestingOwnerAddress?.owner,
      },
    ]);
  };

  const fetchAdminList = async () => {
    try {
      if (roles.includes('SUPPER_ADMIN')) {
        const adminListData = await getAdminList(currentRole);
        const { data } = adminListData;
        setAdminList(data);
      }
    } catch (err) {
      console.log('errFetchList Admin', err);
    }
  };

  const onSearchAdminWalletAddressHandler = (e: any) => {
    setSearchWord(e.target.value);
  };

  const onEditRolesHandler = () => {
    setIsEditing(true);
  };

  const onStopEditting = () => {
    setIsEditing(false);
    setShowDialog(true);
  };

  const onSaveRoleChangeHandler = (adminRoles: any) => {
    setShowDialog(false);
    setIsCollectData(false);
  };

  const hideModal = () => {
    setShowDialog(false);
  };

  const onConfirmSave = () => {
    setIsCollectData(true);
    setShowDialog(false);
  };

  useEffect(() => {
    getContractAddressOwners();
  }, [currentNetworkId]);

  useEffect(() => {
    fetchAdminList();
  }, [currentRole]);

  const handleEditRole = async (role: string, value: boolean, address: string) => {
    switch (role) {
      case ROLE_ADMIN.ADMIN_POOL_REWARD:
        await dispatch(addAdminRewardStakePool(address, value, ROLE_ADMIN.ADMIN_POOL_REWARD));
        fetchAdminList();
        break;
      case ROLE_ADMIN.ADMIN_POOL_NO_REWARD:
        await dispatch(addAdminTierStakePool(address, value, ROLE_ADMIN.ADMIN_POOL_NO_REWARD));
        fetchAdminList();
        break;
      case ROLE_ADMIN.ADMIN_FACTORY_POOL:
        await dispatch(addAdminFactoryPool(address, value, ROLE_ADMIN.ADMIN_FACTORY_POOL));
        fetchAdminList();
        break;
      case ROLE_ADMIN.ADMIN_VESTING:
        await dispatch(addAdminVesting(address, value, ROLE_ADMIN.ADMIN_VESTING));
        fetchAdminList();
        break; 
      case ROLE_ADMIN.ADMIN_IDO_CHAINLINK:
        await dispatch(addAdminIDOChainlink(address, value, ROLE_ADMIN.ADMIN_IDO_CHAINLINK));
        fetchAdminList();
        break;
    }
  };

  return (
    <DefaultLayout>
      <section>
        <h3 className={classes.sectionTitle}>List of contract</h3>
        <TableContainer component={Paper} className={classes.tableContainer}>
          {contractAddressData === null ? (
            [...Array(7)].map((num: any, index: number) => (
              <div key={index}>
                <Skeleton className={classes.skeleton} width={'100%'} />
              </div>
            ))
          ) : (
            <ContractAddressList contractAddressData={contractAddressData} />
          )}
        </TableContainer>
      </section>
      <section className={classes.sectionStyle}>
        <h3 className={classes.sectionTitle}>Role and permissions</h3>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <RolesAndPermissions />
        </TableContainer>
      </section>
      {isPermission && (
        <section className={classes.sectionStyle}>
          <h3 className={classes.sectionTitle}>Admin permission</h3>
          {/* <div className={classes.searchSection}>
            <Input
              placeholder="Search"
              value={searchWord}
              onChange={onSearchAdminWalletAddressHandler}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </div> */}

          <TableContainer component={Paper} className={classes.tableContainer}>
            <ListAdminTable
              adminList={adminList}
              isEditting={isEditTing}
              setAdmin={handleEditRole}
              setCurrentRole={setCurrentRole}
              fetchListAdmin={fetchAdminList}
            />
          </TableContainer>
        </section>
      )}
      <ConfirmModal
        showModal={showDialog}
        closeModalHandler={hideModal}
        title="Confirm transaction"
      >
        <p>This update require a transaction. Do you want to continue?</p>
        <div className={classes.modalButtonContainer}>
          <Button onClick={hideModal} className={classes.commonButton}>
            Close
          </Button>
          <Button onClick={onConfirmSave} className={classes.commonButton}>
            Confirm
          </Button>
        </div>
      </ConfirmModal>
    </DefaultLayout>
  );
};

export default ManageAdminPage;
