import { Typography } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useCommonStyle } from '../../../styles';
import { fieldMustBeGreaterThanZero, renderErrorCreatePool } from '../../../utils/validate';
import useStyles from '../style';
import BigNumber from 'bignumber.js';
import { STATUS_IDO } from '../../../constants';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

function BuyingLimit(props: any) {
  const classes = useStyles();
  const renderError = renderErrorCreatePool;
  const { register, setValue, errors, poolDetail, watch, setNotAllowCreate, isViewDetail } = props;

  const [startMinBuy, setStartMinBuy] = useState('');
  const [startMaxBuy, setStartMaxBuy] = useState('');

  const [rookieMinBuy, setRookieMinBuy] = useState('');
  const [rookieMaxBuy, setRookieMaxBuy] = useState('');

  const isDisploy = !!poolDetail?.is_deploy;
  useEffect(() => {
    if (poolDetail) {
      setStartMinBuy(poolDetail?.starter_min_buy);
      setStartMaxBuy(poolDetail?.starter_max_buy);
      setRookieMinBuy(poolDetail.rookie_min_buy);
      setRookieMaxBuy(poolDetail.rookie_max_buy);
      setValue('starter_min_buy', poolDetail?.starter_min_buy);
      setValue('starter_max_buy', poolDetail?.starter_max_buy);
      setValue('rookie_min_buy', poolDetail.rookie_min_buy);
      setValue('rookie_max_buy', poolDetail.rookie_max_buy);
    }
  }, [poolDetail]);

  const renderErrStarter = (field: any) => {
    if (startMinBuy && startMaxBuy) {
      const maxBuyBigNumber = new BigNumber(startMaxBuy);
      if (maxBuyBigNumber.comparedTo(startMinBuy) < 0) {
        return `Must be an integer not ${
          field === 'max' ? 'larger' : 'smaller'
        } than Starter ${field}`;
      }
    }
  };

  const renderErrRookie = (field: any) => {
    if (rookieMinBuy && rookieMaxBuy) {
      const maxBuyBigNumber = new BigNumber(rookieMaxBuy);
      if (maxBuyBigNumber.comparedTo(rookieMinBuy) < 0) {
        return `Must be an integer not ${
          field === 'max' ? 'larger' : 'smaller'
        } than Rookie ${field}`;
      }
    }
  };

  useEffect(() => {
    let starterInput;
    let rookieInput;
    if (rookieMinBuy && rookieMaxBuy) {
      const maxBuyBigNumber = new BigNumber(rookieMaxBuy);
      if (maxBuyBigNumber.comparedTo(rookieMinBuy) < 0) {
        starterInput = true;
      } else {
        starterInput = false;
      }
    }
    if (startMinBuy && startMaxBuy) {
      const maxBuyBigNumber = new BigNumber(startMaxBuy);
      if (maxBuyBigNumber.comparedTo(startMinBuy) < 0) {
        rookieInput = true;
      } else {
        rookieInput = false;
      }
    }
    if (starterInput || rookieInput) {
      return setNotAllowCreate(true);
    } else {
      return setNotAllowCreate(false);
    }
  }, [rookieMinBuy, rookieMaxBuy, startMinBuy, startMaxBuy, setNotAllowCreate]);

  const validateTimeTicketOpen = useMemo(() => {
    if (poolDetail) {
      const poolOpen = moment(poolDetail?.start_ticket_open).unix();
      const currentDate = moment().unix();
      return poolOpen <= currentDate;
    } else return false;
  }, [poolDetail]);

  return (
    <div style={{ marginTop: 20 }}>
      <Typography className={classes.exchangeRateTitle} style={{ marginRight: 16, marginLeft: 16 }}>
        Buying limit (tickets){' '}
      </Typography>
      <div style={{ paddingLeft: 16 }}>
        {/* BUY LIMIT FOR STARTER TIER  */}
        <div className={classes.formControlWrapBuying}>
          <Typography
            className={classes.exchangeRateTitle}
            style={{ marginRight: 16, marginLeft: 16 }}
          >
            Starter{' '}
          </Typography>

          <div className={classes.blockBuyingLimit}>
            <div className={classes.formControlFlexBlock}>
              <div className={classes.formControlWrap}>
                <label className={classes.formControlLabel} style={{ marginRight: 10 }}>
                  Min
                </label>
                <CurrencyFormat 
                  thousandSeparator={true} 
                  placeholder="Please enter a number"
                  value={startMinBuy}
                  decimalsLimit={6}
                  name={'starter_min_buy'}
                  onValueChange={(value: any) => {
                    setStartMinBuy(value.value);
                    setValue('starter_min_buy', value.value, { shouldValidate: true });
                  }}
                  className={`${classes.formInputBox1}`}
                  disabled={poolDetail?.state === STATUS_IDO.SNAPSHOTTED || validateTimeTicketOpen || isViewDetail}
                />
                {/* <CurrencyInput
                  placeholder="Please enter a number"
                  value={startMinBuy}
                  decimalsLimit={6}
                  name={'starter_min_buy'}
                  onValueChange={(value: any, name: any) => {
                    setStartMinBuy(value);
                    setValue('starter_min_buy', value, { shouldValidate: true });
                  }}
                  className={`${classes.formInputBox}`}
                  disabled={poolDetail?.state === STATUS_IDO.SNAPSHOTTED || validateTimeTicketOpen}
                /> */}

                <input
                  type="hidden"
                  name="starter_min_buy"
                  value={startMinBuy}
                  ref={register({
                    required: true,
                    // validate: {
                    //   minBuyGreaterMaxBuyStarter: (value: any) => {
                    //     const maxBuyValue = watch('starter_max_buy');
                    //     const maxBuyBigNumber = new BigNumber(maxBuyValue);
                    //     return maxBuyBigNumber.comparedTo(value) > 0;
                    //   },
                    // },
                  })}
                />
              </div>
              <p className={classes.formErrorMessage}>{renderError(errors, 'starter_min_buy')}</p>
              <p className={classes.formErrorMessage}>{renderErrStarter('max')}</p>
            </div>
            <div className={classes.formControlFlexBlock}>
              <div className={classes.formControlWrap} style={{ marginLeft: 16 }}>
                <label className={classes.formControlLabel} style={{ marginRight: 10 }}>
                  Max
                </label>
                <CurrencyFormat
                  thousandSeparator={true} 
                  placeholder="Please enter a number"
                  value={startMaxBuy}
                  decimalsLimit={6}
                  name={'starter_max_buy'}
                  onValueChange={(value: any) => {
                    setStartMaxBuy(value.value);
                    setValue('starter_max_buy', value.value, { shouldValidate: true });
                  }}
                  className={`${classes.formInputBox1}`}
                  disabled={poolDetail?.state === STATUS_IDO.SNAPSHOTTED || validateTimeTicketOpen || isViewDetail}
                />
                {/* <CurrencyInput
                  placeholder="Please enter a number"
                  value={startMaxBuy}
                  decimalsLimit={6}
                  name={'starter_max_buy'}
                  onValueChange={(value: any, name: any) => {
                    setStartMaxBuy(value);
                    setValue('starter_max_buy', value, { shouldValidate: true });
                  }}
                  className={`${classes.formInputBox}`}
                  disabled={poolDetail?.state === STATUS_IDO.SNAPSHOTTED || validateTimeTicketOpen}
                /> */}
                <input
                  type="hidden"
                  name="starter_max_buy"
                  value={startMaxBuy}
                  ref={register({
                    required: true,
                    validate: {
                      maxBuyGreaterThanZero: fieldMustBeGreaterThanZero,
                      // maxBuyLessersMaxBuyStarter: (value: any) => {
                      //   const minBuyValue = watch('starter_min_buy');
                      //   const minBuyBigNumber = new BigNumber(minBuyValue);
                      //   return minBuyBigNumber.comparedTo(value) < 0;
                      // },
                    },
                  })}
                />
              </div>
              <p className={`${classes.formErrorMessage} ${classes.formErrorMessageBuying}`}>
                {renderError(errors, 'starter_max_buy')}
              </p>
            </div>
          </div>
        </div>

        {/* BUY LIMIT FOR ROOKIES TIER  */}
        <div className={classes.formControlWrapBuying}>
          <Typography
            className={classes.exchangeRateTitle}
            style={{ marginRight: 16, marginLeft: 16 }}
          >
            Rookie{' '}
          </Typography>

          <div className={classes.blockBuyingLimit}>
            <div className={classes.formControlFlexBlock}>
              <div className={classes.formControlWrap}>
                <label className={classes.formControlLabel} style={{ marginRight: 10 }}>
                  Min
                </label>
                <CurrencyFormat 
                  thousandSeparator={true} 
                  placeholder="Please enter a number"
                  value={rookieMinBuy}
                  decimalsLimit={6}
                  name={'rookie_min_buy'}
                  onValueChange={(value: any) => {
                    setRookieMinBuy(value.value);
                    setValue('rookie_min_buy', value.value, { shouldValidate: true });
                  }}
                  className={`${classes.formInputBox1}`}
                  disabled={poolDetail?.state === STATUS_IDO.SNAPSHOTTED || validateTimeTicketOpen || isViewDetail}
                />
                {/* <CurrencyInput
                  placeholder="Please enter a number"
                  value={rookieMinBuy}
                  decimalsLimit={6}
                  name={'rookie_min_buy'}
                  onValueChange={(value: any, name: any) => {
                    setRookieMinBuy(value);
                    setValue('rookie_min_buy', value, { shouldValidate: true });
                  }}
                  className={`${classes.formInputBox}`}
                  disabled={poolDetail?.state === STATUS_IDO.SNAPSHOTTED || validateTimeTicketOpen}
                /> */}

                <input
                  type="hidden"
                  name="rookie_min_buy"
                  value={rookieMinBuy}
                  ref={register({
                    required: true,
                    // validate: {
                    //   minBuyGreaterMaxBuyRookie: (value: any) => {
                    //     const maxBuyValue = watch('rookie_max_buy');
                    //     const maxBuyBigNumber = new BigNumber(maxBuyValue);
                    //     return maxBuyBigNumber.comparedTo(value) > 0;
                    //   },
                    // },
                  })}
                />
              </div>
              <p className={classes.formErrorMessage}>{renderError(errors, 'rookie_min_buy')}</p>
              <p className={classes.formErrorMessage}>{renderErrRookie('max')}</p>
            </div>
            <div className={classes.formControlFlexBlock}>
              <div className={classes.formControlWrap} style={{ marginLeft: 16 }}>
                <label className={classes.formControlLabel} style={{ marginRight: 10 }}>
                  Max
                </label>
                <CurrencyFormat 
                  thousandSeparator={true} 
                  placeholder="Please enter a number"
                  value={rookieMaxBuy}
                  decimalsLimit={6}
                  name={'rookie_max_buy'}
                  onValueChange={(value: any) => {
                    setRookieMaxBuy(value.value);
                    setValue('rookie_max_buy', value.value, { shouldValidate: true });
                  }}
                  className={`${classes.formInputBox1}`}
                  disabled={poolDetail?.state === STATUS_IDO.SNAPSHOTTED || validateTimeTicketOpen || isViewDetail}
                />
                {/* <CurrencyInput
                  placeholder="Please enter a number"
                  value={rookieMaxBuy}
                  decimalsLimit={6}
                  name={'rookie_max_buy'}
                  onValueChange={(value: any, name: any) => {
                    setRookieMaxBuy(value);
                    setValue('rookie_max_buy', value, { shouldValidate: true });
                  }}
                  className={`${classes.formInputBox}`}
                  disabled={poolDetail?.state === STATUS_IDO.SNAPSHOTTED || validateTimeTicketOpen}
                /> */}
                <input
                  type="hidden"
                  name="rookie_max_buy"
                  value={rookieMaxBuy}
                  ref={register({
                    required: true,
                    validate: {
                      maxBuyGreaterThanZero: fieldMustBeGreaterThanZero,
                      // maxBuyLessersMaxBuyRookie: (value: any) => {
                      //   const minBuyValue = watch('rookie_min_buy');
                      //   const minBuyBigNumber = new BigNumber(minBuyValue);
                      //   return minBuyBigNumber.comparedTo(value) < 0;
                      // },
                    },
                  })}
                />
              </div>
              <p className={`${classes.formErrorMessage} ${classes.formErrorMessageBuying}`}>
                {renderError(errors, 'rookie_max_buy')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyingLimit;
