import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import editIcon from '../../../assets/images/edit.png';
import { CHAIN_ID_NAME_MAPPING, STATUS_IDO } from '../../../constants';
import { deletePool } from '../../../request/pool';
import { alertFailure, alertSuccess } from '../../../store/actions/alert';
import { getCampaigns } from '../../../store/actions/campaign';
import { adminRoute, formatPrecisionAmount } from '../../../utils';
import { trimMiddlePartAddress } from '../../../utils/accountAddress';
import { unixTime } from '../../../utils/convertDate';
import { convertFromJSON } from '../../../utils/convertTextJSON';
import useStyles from './style';

type CampaignsRecordProps = {
  campaign: any;
  currentPage?: any;
};

const CampaignsRecord: React.FC<CampaignsRecordProps> = (props: CampaignsRecordProps) => {
  const { campaign, currentPage } = props;
  const classes = useStyles();
  const today = unixTime(new Date());
  const dispatch = useDispatch();
  const { currentNetworkId } = useSelector((state: any) => state).userCurrentNetwork;

  const generateStatusIDO = () => {
    if (campaign.state === STATUS_IDO.DRAFT) {
      return STATUS_IDO.DRAFT;
    } else {
      switch (true) {
        case today < unixTime(campaign?.start_staking_snapshot):
          return 'Upcoming';
        case today >= unixTime(campaign?.start_staking_snapshot) &&
          today < unixTime(campaign?.start_ticket_open):
          return 'Calculating';
        case today >= unixTime(campaign?.start_ticket_open) &&
          today < unixTime(campaign?.start_ticket_close):
          return 'Buy ticket';
        case today >= unixTime(campaign?.start_ticket_close) &&
          today < unixTime(campaign?.start_pool_end):
          return 'Distribution';
        default:
          return 'Ended';
      }
    }
  };
  const checkNetworkAllowDeploy = () => {
    if (campaign) {
      return !CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]
        ?.toLowerCase()
        .includes(campaign.network);
    } else {
      return false;
    }
  };

  const handleDeleteIDO = async (id: any) => {
    if (checkNetworkAllowDeploy()) {
      return alert(`Wrong network. Please switch to ${campaign?.network} to continue`);
    }
    if (window.confirm('Are you sure you want to delete this IDO?')) {
      if (campaign?.is_deploy) {
        dispatch(alertFailure('This IDO already have deployed!'));
        return;
      }
      try {
        const response = await deletePool(id);
        if (response?.status === 200) {
          dispatch(getCampaigns(currentPage));
          dispatch(alertSuccess('Successful!'));
        } else {
          dispatch(alertFailure('Fail!'));
        }
      } catch {
        dispatch(alertFailure('Fail!'));
      }
    }
  };

  return (
    <TableRow className={classes.tableRow}>
      <TableCell style={{ minWidth: 250, border: 'none', paddingLeft: 30 }}>
        <Link
          className={classes.tableCellTitle}
          to={adminRoute(`/campaign-detail/${campaign?.id}`)}
        >
          <span className={classes.wordBreak}>
            {convertFromJSON(campaign?.project_name) || '--'}
          </span>
        </Link>
      </TableCell>
      <TableCell className={classes.tableCell} align="left" style={{ minWidth: 200 }}>
        <Tooltip title={campaign?.contract_address || ''} placement="top">
          <span>
            {campaign?.contract_address
              ? trimMiddlePartAddress(campaign?.contract_address, 6, 4)
              : '--'}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.tableCell} align="left" style={{ minWidth: 200 }}>
        <Tooltip title={campaign?.deployed_by || ''} placement="top">
          <span>
            {campaign?.deployed_by ? trimMiddlePartAddress(campaign?.deployed_by, 6, 4) : '--'}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.tableCell} align="left">
        {campaign?.network || '--'}
      </TableCell>
      <TableCell className={classes.tableCell} align="left">
        {campaign?.token_symbol || '--'}
      </TableCell>
      <TableCell className={classes.tableCell} align="left">
        {formatPrecisionAmount(campaign?.amount) || '--'}
      </TableCell>
      <TableCell className={classes.tableCell} align="left">
        {generateStatusIDO()}
      </TableCell>
      <TableCell className={classes.tableCell} align="left">
        <div className={classes.tableCellFlex}>
          {/* <Link
            className={`${classes.editDialogView} dialog-cta`}
            to={adminRoute(`/campaigns/edit/${campaign.id}`)}
          >
            <img src={editIcon} alt="edit" width={24} />
          </Link> */}
          <Button
            className={`${classes.editDialogView} dialog-cta remove`}
            onClick={() => handleDeleteIDO(campaign?.id)}
          >
            <DeleteIcon />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CampaignsRecord;
