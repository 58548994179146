import React from 'react';
import useStyles from '../../style';
import TwitterLink from './TwitterLink';
import TelegramLink from './TelegramLink';
import DiscordLink from './DiscordLink';
import DiscordBot from './DiscordBot';
import TelegramBot from './TelegramBot';
import DiscordLinkGroup from './DiscordLinkGroup';

function SocialSetting(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, watch, control, poolDetail, isViewDetail } = props;

  return (
    <div className={classes.exchangeRate}>
      <div>
        <label className={classes.exchangeRateTitle}>Social Setting</label>
      </div>

      <TwitterLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />

      <DiscordLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />

      <DiscordLinkGroup
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />
      
      <TelegramLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      /> 
      
      <DiscordBot
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />

      <TelegramBot
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />
    </div>
  );
}

export default SocialSetting;
