import { trimMiddlePartAddress } from '../../../utils/accountAddress';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import useStyles from '../StakingPoolReward.style';
import { etherscanRoute } from '../../../utils';
import { BigNumber } from 'bignumber.js';
import { CODE_RATE_USD, ROLE_ADMIN } from '../../../constants';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import { Button, styled, withStyles } from '@material-ui/core';

import { useSelector } from 'react-redux';
import usePermission from '../../../hooks/usePermission';
import { getRewardPoolAdress } from '../../../utils/contractAddress/getAddresses';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#464646',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


type ComponentProps = {
  stakingPools: any;
  stakingLPInfo: any;
  openEditModal?: (modal: string) => void;
  isPolygon?: boolean
};

const PoolLPDetail: React.FC<any> = ({
  stakingPools,
  stakingLPInfo,
  openEditModal,
  isPolygon
}: ComponentProps) => {
  const SP_ADDRESS = process.env.REACT_APP_POLYGON_REWARD_STAKING_POOL_ADDRESS as string;
  const classes = useStyles();
  
  const isPermission = usePermission([ROLE_ADMIN.ADMIN_POOL_REWARD, ROLE_ADMIN.SUPPER_ADMIN])
  const renderRow = (title: string, child: any, canEdit = true) => {
    // const key = title.replace(/\s/g, '').toLowerCase()
    return (
      <TableRow>
        <TableCell>{title}</TableCell>
        <TableCell align="left">{child}</TableCell>
        <TableCell align="center">
          {canEdit && (
            <Button
              variant="contained"
              color="primary"
              disabled={!isPermission || !isPolygon}
              onClick={() => openEditModal && openEditModal(title)}
            >
              <EditIcon />
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  };
  return (
    <section className={classes.section}>
      <TableContainer component={Paper} style={{maxWidth:1240}}>
        <Table aria-label="simple table">
        <TableHead>
          <TableRow>
              <StyledTableCell>Reward staking Info</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow(
              'Contract Address',
              <>
                <span>{trimMiddlePartAddress(SP_ADDRESS ?? '')}</span>{' '}
                <a href={etherscanRoute(SP_ADDRESS, { network: 'polygon'})} target="_blank" rel="noreferrer">
                  <OpenInNewIcon style={{ fontSize: '1rem' }} />
                </a>
              </>,
              false,
            )}
            {renderRow(
              'Participants',
              `${
                Number(stakingPools.legend_users) +
                Number(stakingPools.starter_users) +
                Number(stakingPools.rookie_users)
              } users`,
              false,
            )}
            {renderRow(
              'Total Staked amount',
              `${stakingLPInfo.total_staked?.toLocaleString('en')}  CODE ~ ${new BigNumber(
                stakingLPInfo.total_staked,
              )
                .multipliedBy(CODE_RATE_USD)
                .toString()}USD`,
              false,
            )}
            {renderRow('APR', `${stakingLPInfo.apr}%`)}

            {renderRow(
              'Pool capacity',
              `${Number(stakingLPInfo.pool_capacity)?.toLocaleString('en')} CODE`,
            )}
            {renderRow(
              'Individual min',
              Number(stakingLPInfo.individual_min)?.toLocaleString('en'),
            )}
            {renderRow(
              'Individual max',
              Number(stakingLPInfo.individual_max)?.toLocaleString('en'),
            )}
          </TableBody>
        </Table>
      </TableContainer>
          
      <TableContainer component={Paper} style={{marginTop:40,maxWidth:600}}>
        <Table aria-label="simple table">
          <TableHead>
          <TableRow>
              <StyledTableCell>Tier</StyledTableCell>
              <StyledTableCell align="center">Starter</StyledTableCell>
              <StyledTableCell align="center">Super Rookie</StyledTableCell>
              <StyledTableCell align="center">Legend</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          
            <TableRow>
              <TableCell>Users</TableCell>
              <TableCell align="center">
                {' '}
                {Number(stakingPools.starter_users)?.toLocaleString('en')}
              </TableCell>
              <TableCell align="center">
                {' '}
                {Number(stakingPools.rookie_users)?.toLocaleString('en')}
              </TableCell>
              <TableCell align="center">
                {Number(stakingPools.legend_users)?.toLocaleString('en')}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};

export default PoolLPDetail;
