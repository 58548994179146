import { Typography } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { STATUS_IDO } from '../../../constants';
import { renderErrorCreatePool } from '../../../utils/validate';
import useStyles from '../style';

function Distribution(props: any) {
  const classes = useStyles();
  const renderError = renderErrorCreatePool;
  const { register, setValue, errors, poolDetail, isViewDetail, totalTicket } = props;

  const [rateValue, setRateValue] = useState({
    legend: 0,
    rookie: 0,
    starter: 0,
  });

  useEffect(() => {
    if (poolDetail) {
      const editRateValue = {
        legend: poolDetail?.legend_distribution,
        rookie: poolDetail?.rookie_distribution,
        starter: poolDetail?.starter_distribution,
      };

      setRateValue(editRateValue);
      setValue('distribution', JSON.stringify(editRateValue), { shouldValidated: true });
    }
  }, [poolDetail]);

  useEffect(() => {
    if (totalTicket && poolDetail) {
      if (totalTicket != poolDetail.legend_distribution + poolDetail.rookie_distribution + poolDetail.starter_distribution) {
        setRateValue({ legend: 0, rookie: 0, starter: 0 });
      } else {
        const editRateValue = {
          legend: poolDetail.legend_distribution,
          rookie: poolDetail.rookie_distribution,
          starter: poolDetail.starter_distribution,
        };
        setRateValue(editRateValue);
      }
    }
  }, [totalTicket]);

  useEffect(() => {
    setValue('distribution', JSON.stringify(rateValue));
  }, [rateValue]);

  // const renderErrorMinMax = (errors: any, prop: string, min: number, max: number = 100) => {
  //   if (errors[prop]) {
  //     if (errors[prop].type === 'required') {
  //       return 'This field is required';
  //     } else if (errors[prop].type === 'min') {
  //       return `This field must be greater than ${min}`;
  //     } else if (errors[prop].type === 'max') {
  //       return `This field must be less than ${max}`;
  //     } else if (errors[prop].type === 'maxDecimals') {
  //       return `Decimals can not greater than 8`;
  //     }
  //   }
  // };

  const calculatorRatio = (value: any, field: any) => {
    let ratio = Number(totalTicket) - rateValue.legend - rateValue.rookie;
    if (ratio < 0) {
      ratio = Number(value.replaceAll(',', '')) + ratio;
      setRateValue((prev) => ({ ...prev, starter: 0 }));
    }
    setRateValue((prev) => ({ ...prev, [field]: ratio }));
  };

  const validateRatio = useMemo(() => {
    return Number(rateValue.legend) + Number(rateValue.rookie) > Number(totalTicket);
  }, [rateValue, totalTicket]);

  const validateRatioStarter = useMemo(() => {
    return Number(rateValue.legend) + Number(rateValue.rookie) <= Number(totalTicket);
  }, [rateValue, totalTicket]);

  const handleChangeRatio = (value: any, field: any) => {
    if (validateRatio) {
      return calculatorRatio(value, field);
    }
    if (validateRatioStarter) {
      return setRateValue((prev) => ({
        ...prev,
        starter: Number(totalTicket) - Number(rateValue.legend) - Number(rateValue.rookie),
      }));
    }
  };

  const validateTimeTicketOpen = useMemo(() => {
    if (poolDetail) {
      const poolOpen = moment(poolDetail?.start_ticket_open).unix();
      const currentDate = moment().unix();
      return poolOpen <= currentDate;
    } else return false;
  }, [poolDetail]);

  return (
    <>
      <Typography className={classes.exchangeRateTitle} style={{ marginTop: 16 }}>
        Distribution <span style={{ color: '#e51d1d' }}>*</span>{' '}
      </Typography>

      <div className={classes.formControlFlexBlock}>
        <Typography
          className={classes.exchangeRateTitle}
          style={{ marginRight: 16, marginLeft: 16, marginTop: 16, fontStyle: 'italic' }}
        >
          Legend : Rookie : Starter{' '}
        </Typography>
        <input
          type="hidden"
          name={'distribution'}
          value={
            rateValue.legend !== 0 || rateValue.rookie !== 0 || rateValue.starter !== 0
              ? JSON.stringify(rateValue)
              : ''
          }
          ref={register({
            required: true,
          })}
          disabled={poolDetail?.state === STATUS_IDO.SNAPSHOTTED}
        />

        {/* <div className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}>
          {renderErrorMinMax(errors, 'distribution', 0, 100)}
        </div> */}
        <div className={classes.formControlWrap} style={{ marginTop: 0, marginLeft: 16 }}>
          <div className={classes.formControlRate}>
            <CurrencyInput
              value={rateValue.legend}
              decimalsLimit={8}
              maxLength={25}
              onValueChange={(value: any, name: any) => {
                setRateValue({ ...rateValue, legend: value });
                setValue('distribution', JSON.stringify(rateValue), { shouldValidate: true });
              }}
              onBlur={(e: any) => handleChangeRatio(e.target.value, 'legend')}
              className={`${classes.formInputBox} ${classes.formInputBoxBS} ${classes.formInputBoxSM} ${classes.formInputBoxDistribution}`}
              disabled={
                poolDetail?.state === STATUS_IDO.SNAPSHOTTED ||
                totalTicket === 0 ||
                validateTimeTicketOpen ||
                isViewDetail
              }
            />
          </div>
          <span style={{ margin: '12px 10px 0', display: 'block' }}> : </span>
          <div className={classes.formControlRate}>
            <CurrencyInput
              value={rateValue.rookie}
              decimalsLimit={8}
              maxLength={25}
              onValueChange={(value: any, name: any) => {
                setRateValue({ ...rateValue, rookie: value });
                setValue('distribution', JSON.stringify(rateValue), { shouldValidate: true });
              }}
              onBlur={(e: any) => handleChangeRatio(e.target.value, 'rookie')}
              className={`${classes.formInputBox} ${classes.formInputBoxBS} ${classes.formInputBoxSM} ${classes.formInputBoxDistribution}`}
              disabled={
                poolDetail?.state === STATUS_IDO.SNAPSHOTTED ||
                totalTicket === 0 ||
                validateTimeTicketOpen ||
                isViewDetail
              }
            />
          </div>
          <span style={{ margin: '12px 10px 0', display: 'block' }}> : </span>
          <div className={classes.formControlRate}>
            <CurrencyInput
              value={rateValue.starter}
              decimalsLimit={8}
              maxLength={25}
              // onValueChange={(value: any, name: any) => {
              //   setRateValue({ ...rateValue, starter: value });
              // }}
              // onBlur={(e: any) => handleChangeRatio(e.target.value, 'starter')}
              className={`${classes.formInputBox} ${classes.formInputBoxBS} ${classes.formInputBoxSM} ${classes.formInputBoxDistribution}`}
              disabled
            />
          </div>
        </div>

        <div className={classes.formControlFlexBlock}>
          <p className={`${classes.formErrorMessage} ${classes.formErrorMessageBuying}`}>
            {renderError(errors, 'distribution')}
          </p>
        </div>

        <div className={classes.formControlFlexBlock}></div>
      </div>
    </>
  );
}

export default Distribution;
