import { apiRoute } from '../utils';
import { BaseRequest } from './Request';

export const getAdminList = async (queryParams: any) => {

  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admin/list-admin`);
  url += '?role=' + queryParams;

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getAdminDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admins/${id}`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateAdmin = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admins/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createAdmin = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admin`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteAdmin = async (walletAddress: any, role: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admin/${walletAddress}/${role}`);

  const response = (await baseRequest.delete(url, {})) as any;
  const resObject = await response.json();

  return resObject;
};
