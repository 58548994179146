import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { useState } from 'react';
import { STATUS_IDO } from '../../../../../constants';
import { formatPrecisionAmount } from '../../../../../utils';
import { trimMiddlePartAddress } from '../../../../../utils/accountAddress';
import { convertMomentDate } from '../../../../../utils/convertDate';
import ModalListTicket from '../../modules/ModalListTicket';
import useStyles from './styles';
import { headCells } from './utils';

interface IProps {
  data: any;
  poolDetail: any;
}

const ParticipantsTable = (props: IProps) => {
  const { data, poolDetail } = props;
  const classes = useStyles();
  const [user, setUser] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleShowModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell key={cell.id}>{cell.label}</TableCell>
              ))}
              {poolDetail.state !== STATUS_IDO.DEPLOYED && poolDetail.snapshot_root && (
                <TableCell key={7}>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((user: any, index: any) => (
              <TableRow key={index}>
                <TableCell>
                  <Tooltip title={user?.wallet_address} placement="top">
                    <span>{trimMiddlePartAddress(user?.wallet_address, 6, 4) || '--'}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{user?.socialRequirement || '--'}</TableCell>
                <TableCell>{user?.tier || '--'}</TableCell>
                <TableCell>{formatPrecisionAmount(user?.minQuantity) ?? '--'}</TableCell>
                <TableCell>{formatPrecisionAmount(user?.maxQuantity) ?? '--'}</TableCell>
                <TableCell>
                  {user?.updateTime ? convertMomentDate(user?.updateTime) : '--'}
                </TableCell>
                {poolDetail.state !== STATUS_IDO.DEPLOYED && poolDetail.snapshot_root && (
                  <TableCell>
                    <span
                      className={classes.viewDetail}
                      onClick={() => {
                        setUser(user);
                        handleShowModal();
                      }}
                    >
                      View detail
                    </span>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalListTicket open={openModal} handleClose={handleShowModal} user={user} />
    </Box>
  );
};

export default ParticipantsTable;
