export const headCells = [
  {
    id: 1,
    label: 'Ticket Index',
  },
  {
    id: 2,
    label: 'Wallet address',
  },
  {
    id: 3,
    label: 'Tier',
  },
  {
    id: 4 ,
    label: 'Purchase time',
  },
];
