import { Button, Typography } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { getTimeDuration } from '../../../utils/timeLock';
import { fieldMustBeGreaterThanZero, fieldMustBeLessThan100, renderErrorCreatePool } from '../../../utils/validate';
import useStyles from '../style';
import EditIcon from '@material-ui/icons/Edit';
import { editTGEvesting } from '../../../store/actions/campaign';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { ROLE_ADMIN } from '../../../constants';
import usePermission from '../../../hooks/usePermission';

function VestingPolicy(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const renderError = renderErrorCreatePool;
  const { register, watch, setValue, errors, poolDetail, isViewDetail } = props;
  const [linerVesting, setLinerVesting] = useState('');
  const [tge, setTge] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const isPermission = usePermission([ROLE_ADMIN.SUPPER_ADMIN, ROLE_ADMIN.ADMIN_VESTING]);
  useEffect(() => {
    if (poolDetail) {
      const convertDuration = getTimeDuration(poolDetail.linear_vesting);
      setTge(poolDetail.TGE);
      setLinerVesting(convertDuration);
      setValue('linear_vesting', poolDetail.linear_vesting, { shouldValidate: true });
      setValue('TGE', poolDetail.TGE, { shouldValidate: true });
    }
  }, [poolDetail]);

  const isDeployed = !!poolDetail?.is_deploy;
  const poolCloseTime = watch('start_pool_end');
  const poolTicketClose = watch('start_ticket_close');

  const linerVestingTime = useMemo(() => {
    if (!poolCloseTime || !poolTicketClose) return 0;
    return poolCloseTime.unix() - poolTicketClose.unix();
  }, [poolCloseTime, poolTicketClose]);

  useEffect(() => {
    const convertDuration = getTimeDuration(linerVestingTime);
    setLinerVesting(convertDuration);
  }, [linerVestingTime]);

  const handleEditTGE = async () => {
    if (!poolDetail || !poolDetail.contract_address) return;
    if (!isDeployed) return;
    const _start = poolTicketClose.unix();
    const _duration = linerVestingTime;
    const _poolAddress = poolDetail.contract_address;
    const _poolId = poolDetail.id;
    const _tge = new BigNumber(tge).multipliedBy(10 ** 2).toNumber();
    await dispatch(editTGEvesting(_start, 0, _duration, _tge, _poolId, _poolAddress, poolDetail.network));
    setIsEdit(false)
  };
  
  const validateTicketClose = useMemo(() => {
    if(poolDetail) {
      const ticketClose = moment(poolDetail?.start_ticket_close).unix();
      const currentDate = moment().unix();
      return ticketClose < currentDate;
    } else return false
  }, [poolDetail]);

  return (
    <>
      <Typography className={classes.exchangeRateTitle} style={{ marginTop: 16 }}>
        Vesting Policy <span style={{ color: '#e51d1d' }}>*</span>{' '}
      </Typography>

      <div className={classes.formControlWrap} style={{ justifyContent: 'space-between',flexWrap:'wrap' }}>
        <div className={classes.formControlWrap} style={{ marginTop: 0 }}>
          <label style={{ marginRight: 12, marginLeft: 12 }}>TGE</label>
          <div className={classes.formControlRate}>
            <CurrencyInput
              value={tge}
              decimalsLimit={8}
              maxLength={25}
              onValueChange={(value: any) => {
                setTge(value);
                setValue('TGE', value, { shouldValidate: true })
              }}
              className={`${classes.formInputBox} ${classes.formInputBoxBS} ${classes.formInputBoxSM}`}
              disabled={(isDeployed && !isEdit) || isViewDetail}
            />
          </div>
          <label style={{ marginLeft: 12 }}>% right after calculation</label>
          <input
            type="hidden"
            name="TGE"
            value={tge || ''}
            ref={register({
              required: true,
              validate: {
                greaterThanZero: fieldMustBeGreaterThanZero,
                largeThan100: fieldMustBeLessThan100
              },
            })}
            disabled={(isDeployed && !isEdit) || isViewDetail}
          />
        </div>
        {isDeployed && isPermission && (
          <div className={classes.vestingUpdateButton}>
            {!validateTicketClose && (
              <>
                <Button variant="contained" color="primary" disabled={isViewDetail} onClick={() => setIsEdit(true)}>
                  <EditIcon />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEditTGE}
                  style={{ marginLeft: 10 }}
                  disabled={!isEdit || tge > 100}
                >
                  Update
                </Button>
              </>
            )}
          </div>
        )}
      </div>
      <p className={`${classes.formErrorMessage} ${classes.formErrorMessageBuying}`}>
        {renderError(errors, 'TGE')}
      </p>
      <div className={classes.formControlWrap}>
        <label style={{ marginRight: 12, marginLeft: 12, fontSize: 20, fontWeight: 700 }}>
          Linear vesting in <span style={{ color: '#3f51b5' }}>{linerVesting}</span>
        </label>
      </div>
      <div className={classes.formControlWrap}>
        <label
          style={{
            marginRight: 12,
            marginLeft: 12,
            fontSize: 16,
            fontStyle: 'italic',
            color: '#9A9A9A',
            fontWeight: 700,
          }}
        >
          * Linear vesting = Pool End Time - Ticket Close Time
        </label>
      </div>
    </>
  );
}

export default VestingPolicy;
