import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  container: {
    maxWidth: 720,
    margin: '0 auto',
  },
  containerCreateEdit: {
    maxWidth: 720,
    // margin: '0 auto',
  },
  boldText: {
    fontWeight: 'bold',
  },
  form: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: '50px 25px',
    marginTop: 25,
  },
  formControl: {
    marginTop: 30,
    position: 'relative',
  },
  formControlFlex: {
    marginTop: 27,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formControlWrap: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
  },
  formCKEditor: {
    // minHeight: 300,
    // height: 300,
  },
  formControlLabel: {
    fontSize: 14,
    letterSpacing: '0.25px',
    color: '#363636',
  },
  formControlBlurLabel: {
    color: '#9A9A9A !important',
  },
  formControlInput: {
    display: 'block',
    border: '1px solid #DFDFDF',
    width: '100%',
    padding: '13px',
    borderRadius: 5,
    marginTop: 5,
    backgroundColor: 'white',
    transition: '.1s all ease-in',

    '&:focus': {
      borderColor: '#FFCC00',
      outline: 'none',
    },
  },
  formControlInputLoading: {
    position: 'relative',
  },
  formControlIcon: {
    display: 'inline-block',
    marginTop: 10,
  },
  formDatePicker: {
    maxWidth: 300,
    width: 300,
    marginTop: 5,
    border: '1px solid #DFDFDF',
    borderRadius: 5,
    position: 'relative',

    '& .react-date-picker__wrapper': {
      backgroundColor: 'white !important',
    },
  },
  formDatePickerBlock: {
    display: 'block',
  },
  formControlFlexBlock: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  // for exchange rate section
  exchangeRate: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: '20px 25px 30px 25px',
    marginBottom: 20,
  },
  exchangeRateTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: '#363636',
  },
  exchangeRateDesc: {
    marginTop: 30,
    color: '#9A9A9A',
    letterSpacing: '0.25px',
  },
  formControlRate: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginTop: 5,
    '&> input': {
      width: '100%',
    },
  },
  formInputBox: {
    border: '1px solid #DFDFDF',
    padding: '10px',
    maxWidth: 150,
    // width: 250,
    fontSize: 14,
    borderRadius: 5,
    'border-top-right-radius': 0,
    'border-bottom-right-radius': 0,

    '&:focus': {
      outline: 'none',
    },
  },
  formInputBox1: {
    border: '1px solid #DFDFDF',
    padding: '10px',
    // maxWidth: 150,
    // width: 250,
    width: '100%',
    fontSize: 14,
    borderRadius: 5,

    '&:focus': {
      outline: 'none',
    },
  },
  formInputBoxEther: {
    border: '1px solid #000000 !important',
  },
  formInputBoxBS: {
    backgroundColor: '#DFDFFF',
    color: '#3A39BB',
  },
  formInputBoxSM: {
    width: 100,
    'border-top-right-radius': 5,
    'border-bottom-right-radius': 5,
    textAlign: 'center',
  },
  formInputBoxMD: {
    width: 100,
  },
  box: {
    right: 0,
    top: 0,
    width: 50,
    height: 44,
    backgroundColor: '#000000',
    fontSize: 14,
    color: 'white',
    border: 'none',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    'border-top-right-radius': 5,
    'border-bottom-right-radius': 5,
    padding: 5,
  },
  boxEther: {
    backgroundColor: '#3A39BB',
  },
  formButton: {
    backgroundColor: '#FFCC00',
    boxShadow: '0px 0px 30px rgba(243, 203, 25, 0.15)',
    borderRadius: 10,
    padding: '14px 0px',
    border: 'none',
    display: 'inline-block',
    width: '100%',
    color: 'white',
    fontWeight: 600,
    fontSize: 14,
    marginTop: 25,
    marginBottom: 60,
    cursor: 'pointer',
    transition: '.2s all ease-in',

    '&:hover': {
      boxShadow: '0px 15px 20px rgba(0, 0, 0, .1)',
      transform: 'translateY(-7px)',
    },
    '&:focus': {
      outline: 'none',
    },
  },

  formButtonDeployed: {
    backgroundColor: '#9A9A9A',
    boxShadow: '0px 0px 30px rgba(243, 203, 25, 0.15)',
    borderRadius: 10,
    padding: '14px 0px',
    border: 'none',
    display: 'inline-block',
    width: '45%',
    color: 'white',
    fontWeight: 600,
    fontSize: 14,
    marginTop: 25,
    marginBottom: 60,
    marginLeft: 10,
    marginRight: 10,
    cursor: 'not-allowed',

    // transition: '.2s all ease-in',
    // '&:hover': {
    //   boxShadow: '0px 15px 20px rgba(0, 0, 0, .1)',
    //   transform: 'translateY(-7px)'
    // },
    // '&:focus': {
    //   outline: 'none'
    // }
    '&:disabled': {
      boxShadow: 'none',
    },
  },

  formButtonDeploy: {
    backgroundColor: '#FFCC00',
    boxShadow: '0px 0px 30px rgba(243, 203, 25, 0.15)',
    borderRadius: 10,
    padding: '14px 0px',
    border: 'none',
    display: 'inline-block',
    width: '45%',
    color: 'white',
    fontWeight: 600,
    fontSize: 14,
    marginTop: 25,
    marginBottom: 60,
    marginLeft: 10,
    marginRight: 10,
    cursor: 'pointer',
    transition: '.2s all ease-in',

    '&:hover': {
      boxShadow: '0px 15px 20px rgba(0, 0, 0, .1)',
      transform: 'translateY(-7px)',
    },
    '&:focus': {
      outline: 'none',
    },
  },

  formButtonUpdatePool: {
    backgroundColor: '#FFCC00',
    boxShadow: '0px 0px 30px rgba(243, 203, 25, 0.15)',
    borderRadius: 10,
    padding: '14px 0px',
    border: 'none',
    display: 'inline-block',
    width: '45%',
    color: 'white',
    fontWeight: 600,
    fontSize: 14,
    marginTop: 25,
    marginBottom: 60,
    marginLeft: 10,
    marginRight: 10,
    cursor: 'pointer',
    transition: '.2s all ease-in',

    '&:hover': {
      boxShadow: '0px 15px 20px rgba(0, 0, 0, .1)',
      transform: 'translateY(-7px)',
    },
    '&:focus': {
      outline: 'none',
    },
  },

  formErrorMessage: {
    marginBottom: 7,
    color: 'red',
  },
  formErrorMessageAbsolute: {
    // position: 'absolute',
    // bottom: '-27px',
  },
  tokenInfo: {
    marginTop: 15,
    padding: '20px 15px',
    backgroundColor: '#F0F0F0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,

    '& > .tokenInfoBlock': {
      color: '#363636',
      textAlign: 'left',
    },

    '& .tokenInfoLabel': {
      fontSize: 14,
      color: '#636363',
    },

    '& .wordBreak': {
      width: 150,
      maxWidth: 150,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    '& .tokenInfoContent': {
      marginTop: 7,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
    },

    '& .tokenInfoText': {
      marginLeft: 15,
    },
  },
  loadingTokenIcon: {
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  circularProgress: {
    width: 25,
    height: 25,
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  projectEventItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  projectCategoryItem: {
    // display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  SNS: {
    display: 'flex',
    flexWrap: 'wrap',
    // gridTemplateColumns:'1fr 1fr 1fr',
    // gridGap:16,

    '& > li img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  SNSItem: {
    position: 'relative',
    width: 200,
    height: 200,
    padding: 5,
    // '& img':{
    //   position:'absolute',
    //   top:0,
    //   right:0,
    //   zIndex:5,

    // }
  },
  SNSRemove: {
    width: 25,
    height: 25,
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    '& > svg': {
      fontSize: '1rem',
    },
  },
  circleButton: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    background: theme.custom.colors.primary,
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:disabled': {
      background: '#9A9A9A',
      transform: 'none',
    },
    '&:active': {
      transform: 'translateY(1px)',
    },
    '&.remove': {
      width: 25,
      height: 25,
      background: theme.custom.colors.tertiary,
      color: '#fff',
    },
    '&.remove > svg': {
      fontSize: '1rem',
    },
  },
  listCategory: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 10,
    '& > li': {
      padding: 2,
      marginBottom: '0 !important',
      '&:not(:last-child)': {
        marginRight: 10,
      },
    },
  },
  displayNone: {
    display: 'none',
  },
  totalTicket: {
    fontWeight: 'bold',
    marginLeft: 15,
  },
  createTab: {
    background: 'white',
    marginTop: 20,
    padding: '10px 20px 0',
    borderRadius: 10,

    '& .ant-tabs-tab': {
      fontSize: 20,
      fontWeight: 700,

      '& .ant-tabs-tab-btn': {
        padding: '5px 10px',
        borderRadius: 5,
        letterSpacing: 1,
      },
      '&.ant-tabs-tab-active .ant-tabs-tab-btn': {
        background: theme.custom.colors.primary,
        color: 'white',
      },
    },
  },
  formInputBoxDistribution: {
    '&:disabled': {
      background: '#b8b8b8',
    },
  },
  iconCloseToken: {
    cursor: 'pointer',
  },
  blockBuyingLimit: {
    display: 'flex',
    paddingLeft: 16,
  },
  formControlWrapBuying: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  formErrorMessageBuying: {
    marginLeft: 16,
  },
  chipProject: {
    maxWidth: 500,
  },

  [theme.breakpoints.down('lg')]: {
    vestingUpdateButton: {
      width: '100%',
      marginLeft: 12,
      marginTop: 12,
    },
    blockBuyingLimit: {
      flexWrap: 'wrap',
    },
  },
}));

export default useStyles;
