import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Web3 from 'web3';
import ButtonAction from '../../../components/Base/ButtonCustom';
import { CHAIN_ID_NAME_MAPPING } from '../../../constants';
import useStyles from './styles';

interface IProps {
  open: boolean;
  typeAdmin: string;
  handleSubmit: (address: string, role: number) => void;
  handleClose: () => void;
}
const permission = ['Reward Staking', 'Tier Staking', 'Factory Pool', 'Vesting', 'IDO Chainlink'];
const permissionKlaytn = ['Factory Pool', 'Vesting'];

const DialogAddAdmin = (props: IProps) => {
  const { currentNetworkId } = useSelector((state: any) => state.userCurrentNetwork);
  const { open, handleClose, handleSubmit, typeAdmin } = props;
  const classes = useStyles();

  const [role, setRole] = useState<any>(-1);
  const [addressData, setAddressData] = useState('');
  const [err, setErr] = useState('');
  const [errRole, setErrRole] = useState('');
  const handleSelectedRole = (event: any) => {
    setRole(event.target.value);

    if (event.target.value === -1) {
      setErrRole('Address is invalid!');
      return;
    }
    return setErrRole('');
  };

  const handleChangeAddress = debounce((value: any) => {
    setAddressData(value.target.value);
    const isAddress = Web3.utils.isAddress(value.target.value);

    if (!isAddress) {
      setErr('Address is invalid!');
      return;
    }
    return setErr('');
  }, 500);

  const handleAdd = () => {
    if (!addressData) {
      setErr('Address is required !');
    }
    if (role < 0) {
      setErrRole('Role is required !');
    }
    if (!addressData || role === -1 || err) return;
    if (handleSubmit) handleSubmit(addressData, role);
    resetState();
  };

  const resetState = () => {
    setRole(-1);
    setAddressData('');
    setErr('');
    setErrRole('');
  };
  const handleCloseModal = () => {
    resetState();
    if (handleClose) handleClose();
  };

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle className={classes.modalTitle}>
          Add Admin permission
          <CloseIcon className={classes.iconClose} width={20} onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          <Box className={classes.modalDescription}>
            <Typography>Wallet address</Typography>
            <TextField
              placeholder="Enter a wallet address"
              className={classes.inputWalletAddress}
              onChange={handleChangeAddress}
              defaultValue={addressData}
              helperText={err}
              error={!!err}
            />
          </Box>
          <Box className={classes.modalDescription}>
            <FormControl error={!!errRole}>
              <Select name="minTier" value={role} onChange={handleSelectedRole}>
                <MenuItem value={-1}>Role</MenuItem>
                {CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes('klaytn')
                  ? permissionKlaytn.map((value: string, index: number) => {
                      return (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      );
                    })
                  : permission.map((value: string, index: number) => {
                      return (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      );
                    })}
              </Select>
              {errRole && (
                <FormHelperText id="demo-simple-select-error-label">{errRole}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <DialogContentText className={classes.modalDescription}>
            <Typography>The owner of this wallet will have access to the selected role</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalAction}>
          <ButtonAction className={classes.btnCancel} onClick={handleCloseModal}>
            Cancel
          </ButtonAction>
          <ButtonAction className={classes.btnConfirm} onClick={handleAdd}>
            Add
          </ButtonAction>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogAddAdmin;
