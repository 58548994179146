import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import useStyles, { UpdateButton } from './StakingPoolReward.style';
import { getRewardPoolList } from '../../request/pool';
import { alertFailure } from '../../store/actions/alert';
import { useDispatch, useSelector } from 'react-redux';

import PoolLPForm from './RewardPoolLP/PoolLPForm';
import PoolLPDetail from './RewardPoolLP/PoolLPDetail';
import PoolFormDialog from './shared/Dialog';
import useRewardPoolLP from './hook/useRewardPoolLP';
import { updateRewardPool, updateRewardPoolWithdrawalFee } from '../../store/actions/staking-pool';
import { CHAIN_ID_NAME_MAPPING, REWARD_POOL_KEY } from '../../constants';
import useRewardPoolBSC from './hook/useRewardPoolLPBSC';
import WithDrawFeeSetting from './RewardPoolLP/WithDrawFeeSetting';
import useRewardWithdrawFee from './hook/useRewardWithdrawFee';
import WithdrawalFreeForm from './RewardPoolLP/WithdrawalFreeForm';
import WithdrawalPeriodLimitForm from './RewardPoolLP/WithdrawalPeriodLimitForm';

interface withdrawalFee {
  aNumber: number;
  bNumber: number;
  cNumber: number;
}

interface withdrawalPeriodLimit {
  withDrawFeeTime: number;
}

const StakingPoolReward: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [stakingPools, setStakingPools] = useState<any>({});
  const [stakingLPInfo, setStakingLPInfo] = useState<any>();
  const [rewardPoolwithdrawFee, setRewardPoolwithdrawFee] = useState<any>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalKey, setModalKey] = useState<string>('');
  const [errorMessageList, setErrorMessageList] = useState<any>({});
  const [dataForm, setDataForm] = useState<any>({});
  const [validateData, setValidateData] = useState<any>(0);

  const [showWithDrawFeeModal, setShowWithDrawFeeModal] = useState<boolean>(false);
  const [showWithDrawPeriodLimitModal, setShowWithDrawPeriodLimitModal] = useState<boolean>(false);

  const { currentNetworkId } = useSelector((state: any) => {
    return {
      ...state,
      currentNetworkId: state.userCurrentNetwork.currentNetworkId,
      wallet_address: state.user.data.wallet_address,
    };
  });

  const { loading: loadingFetchStakingLPInfo, fetchStakingLPInfo } = useRewardPoolBSC();
  const { loading: loadingFetchRewardWithdrawFee, fetchRewardWithdrawFee } = useRewardWithdrawFee();

  const fetchData = async () => {
    const poolLPInfo = await fetchStakingLPInfo();
    const { currentTotalStaked, APR, maxTotalStake, minStake, maxStake } = poolLPInfo as any;

    setStakingLPInfo({
      ...stakingLPInfo,
      total_staked: currentTotalStaked,
      apr: APR,
      pool_capacity: maxTotalStake,
      individual_min: minStake,
      individual_max: maxStake,
    });
  };

  const fetchWithdrawalFee = async () => {
    try {
      const rewardWithdrawFee = await fetchRewardWithdrawFee();
      setRewardPoolwithdrawFee(rewardWithdrawFee);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    fetchWithdrawalFee();
  }, [currentNetworkId]);

  const openEditModal = (modal: string) => {
    const key = modal.toLocaleLowerCase().replaceAll(' ', '_');
    console.log('key', key);

    switch (key) {
      case REWARD_POOL_KEY.APR:
        setModalTitle(modal);
        setErrorMessageList({
          apr: 'Must be a number larger than 0',
          aprlessThan100: 'Must be a number less than 100',
        });
        setDataForm({ apr: stakingLPInfo?.apr });
        setModalKey(key);
        break;
      case REWARD_POOL_KEY.CAPACITY:
        setModalTitle(modal);
        setErrorMessageList({
          pool_capacity: 'Must be a number larger than Individual max',
          pool_capacity_gt0: 'Must be a number larger than 0',
        });
        setDataForm({ pool_capacity: stakingLPInfo?.pool_capacity });
        setModalKey(key);
        setValidateData({ individual_max: stakingLPInfo?.individual_max });
        break;
      case REWARD_POOL_KEY.MAX:
      case REWARD_POOL_KEY.MIN:
        setModalTitle('Individual');
        setErrorMessageList({
          individual_min: 'Must be a number at least 0 and not larger than Individual max',
          individual_max: 'Must be a number at least 0 and not larger than Pool capacity',
        });
        setDataForm({
          individual_min: stakingLPInfo?.individual_min,
          individual_max: stakingLPInfo?.individual_max,
        });
        setModalKey(key);
        setValidateData({ pool_capacity: stakingLPInfo?.pool_capacity });
        break;
    }

    setShowModal(true);
  };

  const closeEditModal = () => {
    setShowModal(false);
  };

  const editPoolLP = (form: any) => {
    switch (modalKey) {
      case REWARD_POOL_KEY.APR:
        dispatch(updateRewardPool(form?.apr, fetchData, modalKey));
        break;
      case REWARD_POOL_KEY.CAPACITY:
        dispatch(updateRewardPool(form?.pool_capacity, fetchData, modalKey));
        break;
      case REWARD_POOL_KEY.MIN:
      case REWARD_POOL_KEY.MAX:
        const data = { max: form?.individual_max, min: form?.individual_min };
        // console.log('data', data, modalKey);

        dispatch(updateRewardPool(data, fetchData,  modalKey));
        break;
    }
    closeEditModal();
  };

  const getPoolLPList = async () => {
    setLoading(true);
    try {
      const resObject = await getRewardPoolList();
      const { data } = resObject;
      setStakingPools(data);
    } catch (err) {
      dispatch(alertFailure('Fetch failed!!'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPoolLPList();
  }, []);

  const handleUpdateWithdrawalFee = (data: withdrawalFee) => {
    dispatch(updateRewardPoolWithdrawalFee(data, fetchWithdrawalFee));
  };

  const handleUpdateWithdrawalPeriodLimit = (data: withdrawalPeriodLimit) => {
    dispatch(updateRewardPoolWithdrawalFee(data, fetchWithdrawalFee, false));
  };

  const checkIsPolygon = useMemo(() => {
    return CHAIN_ID_NAME_MAPPING[currentNetworkId]?.toLowerCase()?.includes('polygon');
  }, [currentNetworkId]);

  return (
    <DefaultLayout>
      <div className={classes.sectionTitle}>
        <h2>Reward staking</h2>
      </div>
      {loadingFetchStakingLPInfo && loading ? (
        [...Array(10)].map((_, index) => (
          <div key={index}>
            <Skeleton className={classes.skeleton} width={'100%'} />
          </div>
        ))
      ) : (
        <>
          {stakingLPInfo && stakingPools && (
            <PoolLPDetail
              stakingPools={stakingPools}
              stakingLPInfo={stakingLPInfo}
              openEditModal={openEditModal}
              isPolygon={checkIsPolygon}
            />
          )}
        </>
      )}
      {loadingFetchRewardWithdrawFee && loading
        ? [...Array(3)].map((_, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={'100%'} />
            </div>
          ))
        : rewardPoolwithdrawFee && (
            <WithDrawFeeSetting
              withdrawFee={rewardPoolwithdrawFee}
              openEditModal={() => setShowWithDrawFeeModal(true)}
              openEditDrawPeriodLimitModal={() => setShowWithDrawPeriodLimitModal(true)}
              isPolygon={checkIsPolygon}
            />
          )}
      <PoolFormDialog
        title={modalTitle}
        showModal={showModal}
        closeModalHandler={closeEditModal}
        style={{ dialog: classes.dialog, modalTitle: classes.modalTitle }}
      >
        <PoolLPForm
          errorMessageList={errorMessageList}
          onClose={closeEditModal}
          onSubmit={editPoolLP}
          form={dataForm}
          name={modalKey}
          validateData={validateData}
        />
      </PoolFormDialog>

      <PoolFormDialog
        title={'Update withdrawal fee formula'}
        showModal={showWithDrawFeeModal}
        closeModalHandler={() => setShowWithDrawFeeModal(false)}
        style={{ dialog: classes.dialog, modalTitle: classes.modalTitle }}
      >
        <WithdrawalFreeForm
          rewardPoolwithdrawFee={rewardPoolwithdrawFee}
          onClose={() => setShowWithDrawFeeModal(false)}
          onSubmit={handleUpdateWithdrawalFee}
        />
      </PoolFormDialog>

      <PoolFormDialog
        title={'Update withdrawal fee period limit'}
        showModal={showWithDrawPeriodLimitModal}
        closeModalHandler={() => setShowWithDrawPeriodLimitModal(false)}
        style={{ dialog: classes.dialog, modalTitle: classes.modalTitle }}
      >
        <WithdrawalPeriodLimitForm
          withdrawFee={rewardPoolwithdrawFee}
          onClose={() => setShowWithDrawPeriodLimitModal(false)}
          onSubmit={handleUpdateWithdrawalPeriodLimit}
        />
      </PoolFormDialog>
    </DefaultLayout>
  );
};

export default StakingPoolReward;
