import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonAction from '../../../components/Base/ButtonCustom';
import { CHAIN_ID_NAME_MAPPING, ROLE_ADMIN } from '../../../constants';
import {
  addAdminFactoryPool,
  addAdminIDOChainlink,
  addAdminRewardStakePool,
  addAdminTierStakePool,
  addAdminVesting,
} from '../../../store/actions/setting-owner';
import DialogAddAdmin from './DialogAddAdmin';
import ListAdminPermission from './ListAdminPermission';
import useStyles from './styles';

interface ComponentProps {
  adminList: any[] | null;
  submitAdminData: (data: any) => void;
  isCollectData: boolean;
  isEditting: boolean;
  setAdmin: (role: string, isAdd: boolean, address: string) => void;
  setCurrentRole?: (tabs: string) => void;
  fetchListAdmin: () => void;
}

const ListAdminTable: React.FC<any> = ({
  adminList,
  isEditting,
  setAdmin,
  setCurrentRole,
  fetchListAdmin,
}: ComponentProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentNetworkId } = useSelector((state: any) => state.userCurrentNetwork);
  const checkIsKlaytn = () => {
    return CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes('klaytn');
  };
  const [tab, setTab] = useState<string>(
    checkIsKlaytn() ? ROLE_ADMIN.ADMIN_FACTORY_POOL : ROLE_ADMIN.ADMIN_POOL_REWARD,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

  const handleChangeTab = (e: any, newTab: any) => {
    setTab(newTab);
    if (setCurrentRole) setCurrentRole(newTab);
  };

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleShowModal = () => {
    setOpenModalConfirm(!openModalConfirm);
  };

  const handleSubmitModal = async (address: string, role: any) => {
    setOpenModalConfirm(false);
    switch (role) {
      case 'Reward Staking':
        await dispatch(addAdminRewardStakePool(address, true, ROLE_ADMIN.ADMIN_POOL_REWARD));
        fetchListAdmin();
        break;
      case 'Tier Staking':
        await dispatch(addAdminTierStakePool(address, true, ROLE_ADMIN.ADMIN_POOL_NO_REWARD));
        fetchListAdmin();
        break;
      case 'Factory Pool':
        await dispatch(addAdminFactoryPool(address, true, ROLE_ADMIN.ADMIN_FACTORY_POOL));
        fetchListAdmin();
        break;
      case 'Vesting':
        await dispatch(addAdminVesting(address, true, ROLE_ADMIN.ADMIN_VESTING));
        fetchListAdmin();
        break;
      case 'IDO Chainlink':
        await dispatch(addAdminIDOChainlink(address, true, ROLE_ADMIN.ADMIN_IDO_CHAINLINK));
        fetchListAdmin();
        break;
    }
    // setOpenModalConfirm(false);
  };
  const handleRemoveRole = (address: string) => {
    if (!window.confirm('Are you sure ?')) return;
    if (setAdmin) setAdmin(tab, false, address);
  };

  return (
    <Grid item xs={12}>
      <Box className={classes.tabTableTicket} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {checkIsKlaytn() ? (
          <Tabs value={tab} onChange={handleChangeTab}>
            <Tab
              className={classes.titleTicketTab}
              label="Factory Pool"
              value={ROLE_ADMIN.ADMIN_FACTORY_POOL}
            />
            <Tab
              className={classes.titleTicketTab}
              label="Vesting"
              value={ROLE_ADMIN.ADMIN_VESTING}
            />
          </Tabs>
        ) : (
          <Tabs value={tab} onChange={handleChangeTab}>
            <Tab
              className={classes.titleTicketTab}
              label="Reward Staking"
              value={ROLE_ADMIN.ADMIN_POOL_REWARD}
            />
            <Tab
              className={classes.titleTicketTab}
              label="Tier Staking"
              value={ROLE_ADMIN.ADMIN_POOL_NO_REWARD}
            />
            <Tab
              className={classes.titleTicketTab}
              label="Factory Pool"
              value={ROLE_ADMIN.ADMIN_FACTORY_POOL}
            />
            <Tab
              className={classes.titleTicketTab}
              label="Vesting"
              value={ROLE_ADMIN.ADMIN_VESTING}
            />
            <Tab
              className={classes.titleTicketTab}
              label="IDO Chainlink"
              value={ROLE_ADMIN.ADMIN_IDO_CHAINLINK}
            />
          </Tabs>
        )}
      </Box>
      {adminList && adminList.length ? (
        <Box>
          <Box className={classes.bodyTable}>
            <ListAdminPermission data={adminList} handleRemoveRole={handleRemoveRole} />
          </Box>
          {lastPage > 1 && (
            <Pagination
              page={currentPage}
              className={classes.pagination}
              count={lastPage}
              onChange={handlePaginationChange}
            />
          )}
        </Box>
      ) : (
        <Box className={classes.noItemTicket}>
          <Typography>There is no admin to show</Typography>
        </Box>
      )}
      <Box>
        <ButtonAction onClick={handleShowModal}>Add new admin</ButtonAction>
      </Box>
      <DialogAddAdmin
        open={openModalConfirm}
        typeAdmin={tab}
        handleClose={handleShowModal}
        handleSubmit={handleSubmitModal}
      />
    </Grid>
  );
};

export default ListAdminTable;
