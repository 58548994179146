export const headCells = [
  {
    id: 1,
    label: 'Admin address',
  },
  {
    id: 2,
    label: 'Added by',
  },
  {
    id: 3,
    label: 'Added at',
  },
  {
    id: 4 ,
    label: 'Tx Hash',
  },
];