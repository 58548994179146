import React from 'react';
import useStyles from '../../style';
import { renderErrorCreatePool } from '../../../../utils/validate';

function DiscordLink(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, watch, getValues, needValidate, poolDetail, isViewDetail } =
    props;
  const renderError = renderErrorCreatePool;

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Self Discord</label>
        <input
          type="text"
          name="platform_discord"
          defaultValue={poolDetail?.platform_discord}
          ref={register({
            // required: true
          })}
          className={classes.formControlInput}
          disabled={isViewDetail}
        />
        <p className={classes.formErrorMessage}>{renderError(errors, 'platform_discord')}</p>
      </div>
    </>
  );
}

export default DiscordLink;
