import _clone from 'lodash/clone';
import _toPair from 'lodash/toPairs';
import _values from 'lodash/values';
import _flatten from 'lodash/flatten';

import { FormEvent, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from './StakeLPForm.style';
import { getTokenInfo } from '../../../utils/token';

interface StakeLP {
  tokenAddress: string;
  tokenSymbol: string;
  conversionRate: number | undefined;
  maximumDecimal: number | undefined;
  icon: string;
}

interface ErrorMgs {
  tokenAddress: string;
  tokenSymbol: string;
  conversionRate: string;
  maximumDecimal: string;
  icon: string;
}

interface ComponentProps {
  form?: StakeLP | undefined;
  onSubmit: (data: StakeLP) => void;
  onClose: () => void;
  errorMessageList?: ErrorMgs;
  existTokens: any;
}

const defaultForm = {
  tokenAddress: '',
  tokenSymbol: '',
  conversionRate: undefined,
  maximumDecimal: undefined,
  icon: '',
};

const defaultErrorState = {
  tokenAddress: false,
  tokenSymbol: false,
  conversionRate: false,
  maximumDecimal: false,
  icon: false,
};

const defaultErrorMessages = {
  tokenAddress: ' ',
  tokenSymbol: ' ',
  conversionRate: ' ',
  maximumDecimal: ' ',
  icon: ' ',
};

const StakeLPForm: React.FC<ComponentProps> = ({
  form,
  onSubmit,
  errorMessageList,
  onClose,
  existTokens,
}: ComponentProps) => {
  const classes = useStyles();
  const [formFields, setFormFields] = useState<StakeLP>(form ? _clone(form) : _clone(defaultForm));
  const [errors, setErrors] = useState(defaultErrorState);
  const [helperMessages, setHelperMessages] = useState<any>(errorMessageList);
  const [tokenIcon, setTokenIcon] = useState<null | string>(null);

  const validators: {
    [key: string]: ((n: any) => Promise<void>) | any;
  } = {
    tokenAddress: {
      onChange: async (value: any) => {
        if (!value) {
          return Promise.reject({ key: 'tokenAddress', message: errorMessageList?.tokenAddress });
        }

        return Promise.resolve();
      },
      onBlur: async (tokenValue: string) => {
        try {
          const tokenAddress = tokenValue;
          // Kiểm tra token address đã được tạo trên SC chưa
          if (!!existTokens.find((token: any) => token === tokenAddress)) {
            throw new Error('Token address is already existed');
          }

          const erc20Token = await getTokenInfo(tokenAddress);
          if (erc20Token) {
            const { symbol,decimals } = erc20Token;
            setFormFields({ ...formFields, tokenSymbol: symbol,maximumDecimal:decimals });
            return Promise.resolve(' ');
          }
        } catch (err: any) {
          return Promise.reject({ key: 'tokenAddress', message: err.message });
        }
      },
    },
    tokenSymbol: async (value: any) => {
      if (!value) {
        return Promise.reject({ key: 'tokenSymbol', message: errorMessageList?.tokenSymbol });
      }

      return Promise.resolve();
    },
    maximumDecimal: async (value: any) => {
      if (!value || value < 0) {
        return Promise.reject({ key: 'maximumDecimal', message: errorMessageList?.maximumDecimal });
      }

      return Promise.resolve();
    },
    conversionRate: async (value: any) => {
      if (!value || value < 0) {
        return Promise.reject({ key: 'conversionRate', message: errorMessageList?.conversionRate });
      }

      return Promise.resolve();
    },
    icon: {
      onChange: async (value: any) => {
        if (!value) {
          return Promise.reject({ key: 'icon', message: errorMessageList?.icon });
        }

        return Promise.resolve();
      },
      onBlur: async () => {
        const iconURL = formFields.icon;
        setTokenIcon(iconURL);
        try {
          // Check image validity
          const checkImage = (imageURL: string) => {
            return new Promise((success, error) => {
              const testImg = new Image();
              testImg.onload = success;
              testImg.onerror = () => error('Invalid image URL');
              testImg.src = imageURL;
            });
          };

          await checkImage(iconURL);

          return Promise.resolve(' ');
        } catch (error: any) {
          console.dir(error);
          return Promise.reject({ key: 'icon', message: 'Invalid image URL' });
        }
      },
    },
  };

  const formSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const validationStates = await Promise.allSettled(
        _flatten(
          _toPair(formFields).map((entries: [string, any]) => {
            if (typeof validators[entries[0]] !== 'function') {
              return _values(validators[entries[0]]).map((validator) => validator(entries[1]));
            }

            return validators[entries[0]](entries[1]);
          }),
        ),
      );

      const errorKeys = validationStates
        .filter((state) => state.status === 'rejected')
        .map((state: any) => state.reason);
      if (errorKeys.length > 0) {
        throw errorKeys;
      }

      setErrors(_clone(defaultErrorState));
      setHelperMessages(_clone(defaultErrorMessages));

      onSubmit(formFields);
    } catch (errorKeys: any) {
      const errorKeyList: {
        [key: string]: boolean;
      } = {};
      const errorValueList: {
        [key: string]: any;
      } = {};

      errorKeys.forEach((error: { key: string; message: string }) => {
        errorKeyList[error.key] = true;
        errorValueList[error.key] = error.message;
      });

      setErrors({ ...errors, ...errorKeyList });
      setHelperMessages({ ...helperMessages, ...errorValueList });
    }
  };

  const onBlurTokenAddress = async () => {
    const tokenAddress = formFields.tokenAddress;
    try {
      setHelperMessages({ ...helperMessages, tokenAddress: 'Validating...' });
      setErrors({ ...errors, tokenAddress: false });
      await validators.tokenAddress.onBlur(tokenAddress);

      setHelperMessages({ ...helperMessages, tokenAddress: ' ' });
    } catch (error: any) {
      setErrors({ ...errors, tokenAddress: true });
      setHelperMessages({ ...helperMessages, tokenAddress: error.message });
    }
  };

  const onEnterTokenAddress = async (event: any) => {
    const value = event.target.value;
    setFormFields({ ...formFields, tokenAddress: value });
    try {
      await validators.tokenAddress.onChange(value);
      setErrors({ ...errors, tokenAddress: false });
    } catch (error) {
      setErrors({ ...errors, tokenAddress: true });
      setHelperMessages({
        ...helperMessages,
        tokenAddressHelperMessage: errorMessageList?.tokenAddress,
      });
    }
  };

  const onEnterTokenSymbol = async (event: any) => {
    const value = event.target.value;
    setFormFields({ ...formFields, tokenSymbol: value });
    try {
      await validators.tokenSymbol(value);
      setErrors({ ...errors, tokenSymbol: false });
      setHelperMessages({ ...helperMessages, tokenSymbol: ' ' });
    } catch (error) {
      setErrors({ ...errors, tokenSymbol: true });
      setHelperMessages({ ...helperMessages, tokenSymbol: errorMessageList?.tokenSymbol });
    }
  };

  const onEnterTokenIcon = async (event: any) => {
    const value = event.target.value;
    setFormFields({ ...formFields, icon: value });
    try {
      await validators.icon.onChange(value);
      setErrors({ ...errors, icon: false });
      setHelperMessages({ ...helperMessages, icon: ' ' });
    } catch (error) {
      setErrors({ ...errors, icon: true });
      setHelperMessages({ ...helperMessages, icon: errorMessageList?.icon });
    }
  };

  const onBlurTokenIcon = async () => {
    const iconImageURL = formFields.icon;
    try {
      setHelperMessages({ ...helperMessages, icon: 'Validating...' });
      setErrors({ ...errors, icon: false });
      await validators.icon.onBlur(iconImageURL);

      setHelperMessages({ ...helperMessages, icon: ' ' });
    } catch (error: any) {
      setErrors({ ...errors, icon: true });
      setHelperMessages({ ...helperMessages, icon: error.message });
    }
  };

  const onEnterMaximumDecimals = async (event: any) => {
    const value = event.target.value;
    setFormFields({ ...formFields, maximumDecimal: value });
    try {
      await validators.maximumDecimal(Number(value));
      setErrors({ ...errors, maximumDecimal: false });
      setHelperMessages({ ...helperMessages, maximumDecimal: ' ' });
    } catch (error) {
      setErrors({ ...errors, maximumDecimal: true });
      setHelperMessages({ ...helperMessages, maximumDecimal: errorMessageList?.maximumDecimal });
    }
  };

  const onEnterConversionRate = async (event: any) => {
    const value = event.target.value;
    setFormFields({ ...formFields, conversionRate: value });
    try {
      await validators.conversionRate(Number(value));
      setErrors({ ...errors, conversionRate: false });
      setHelperMessages({ ...helperMessages, conversionRate: ' ' });
    } catch (error) {
      setErrors({ ...errors, conversionRate: true });
      setHelperMessages({ ...helperMessages, conversionRate: errorMessageList?.conversionRate });
    }
  };

  return (
    <form onSubmit={formSubmitHandler}>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>Token address </td>
            <td>
              <TextField
                variant="outlined"
                id="starter-input"
                error={errors.tokenAddress}
                helperText={helperMessages.tokenAddress}
                value={formFields.tokenAddress}
                onChange={onEnterTokenAddress}
                size="small"
                classes={{
                  root: classes.formInput,
                }}
                fullWidth
                onBlur={onBlurTokenAddress}
              />
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>Token symbol </td>
            <td>
              <TextField
                variant="outlined"
                id="starter-input"
                error={errors.tokenSymbol}
                helperText={helperMessages.tokenSymbol}
                value={formFields.tokenSymbol}
                onChange={onEnterTokenSymbol}
                size="small"
                classes={{
                  root: classes.formInput,
                }}
                fullWidth
              />
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>Maximum decimals </td>
            <td>
              <TextField
                variant="outlined"
                id="starter-input"
                error={errors.maximumDecimal}
                helperText={helperMessages.maximumDecimal}
                value={formFields.maximumDecimal}
                onChange={onEnterMaximumDecimals}
                disabled={true}
                size="small"
                classes={{
                  root: classes.formInput,
                }}
                fullWidth
              />
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>Icon</td>
            <td className={classes.conversionInput}>
              <TextField
                variant="outlined"
                id="starter-input"
                error={errors.icon}
                helperText={helperMessages.icon}
                value={formFields.icon}
                onChange={onEnterTokenIcon}
                onBlur={onBlurTokenIcon}
                size="small"
                classes={{
                  root: classes.formInput,
                }}
                fullWidth
              />
            </td>
          </tr>
          {tokenIcon && (
            <tr>
              <td></td>
              <td>
                <img src={tokenIcon} alt="" className={classes.iconImage} />
              </td>
            </tr>
          )}
          <tr>
            <td className={classes.tableTitle}>Conversion rate to CODE</td>
            <td className={classes.conversionInput}>
              <span>1 CODE =</span>
              <TextField
                variant="outlined"
                id="starter-input"
                error={errors.conversionRate}
                helperText={helperMessages.conversionRate}
                value={formFields.conversionRate}
                onChange={onEnterConversionRate}
                size="small"
                classes={{
                  root: classes.formInput,
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className={classes.btns}>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Add
        </Button>
      </div>
    </form>
  );
};

export default StakeLPForm;
