import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import BigNumber from 'bignumber.js';
//@ts-ignore
import { campaignActions } from '../constants/campaign';
import { alertActions } from '../constants/alert';
import { BaseRequest } from '../../request/Request';
import IDOPoolABI from '../../abi/IDOPoolABI.json';
import IDOChainlinkABI from '../../abi/IDOChainlinkABI.json';
import PoolFactoryABI from '../../abi/PoolFactory.json';

import { getContractInstance, getWeb3Instance } from '../../services/web3';
import { getTokenInfo } from '../../utils/token';
import { updateDeploySuccess } from '../../request/pool';
import {
  NETWORK_AVAILABLE,
  MAPPING_CURRENCY_ADDRESS,
  NATIVE_TOKEN_ADDRESS,
  TXH_TYPE,
  PAGE_SIZE_X2,
  TRANSACTION_TIMEOUT,
  POLYGON_CHAIN_ID,
} from '../../constants';
import { alertSuccess, alertWarning } from './alert';
import { getGasFeePolygon } from '../../utils/blockchain';
import Web3 from 'web3';
const queryString = require('query-string');

// get list IDO - CodeDAO
export const getCampaigns = (
  currentPage: number = 1,
  query: string = '',
  pageSize: number = PAGE_SIZE_X2,
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    const baseRequest = new BaseRequest();

    dispatch({ type: campaignActions.CAMPAIGNS_REQUEST });

    let url = `/api/v1/ido`;

    const queryParams = {
      page_number: currentPage,
      page_size: pageSize,
      searchValue: query,
    };

    url += '?' + queryString.stringify(queryParams);

    try {
      const response = (await baseRequest.get(url)) as any;
      const resObject = await response.json();

      if (response?.status === 200) {
        const { count, page_number, total_page, data } = resObject;

        dispatch({
          type: campaignActions.CAMPAIGNS_SUCCESS,
          payload: {
            total: count,
            page: page_number,
            lastPage: total_page,
            data,
            searchValue: query,
          },
        });
      }
    } catch (err: any) {
      dispatch({
        type: campaignActions.CAMPAIGNS_FAIL,
        payload: err.message,
      });
    }
  };
};

export const deployPool = (
  campaign: any,
  history: any,
  setLoadingDeploy: (isDeploy: any) => void,
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      dispatch({ type: campaignActions.MY_CAMPAIGN_CREATE_REQUEST });
      const {
        token_address,
        amount,
        price,
        start_staking_snapshot,
        start_ticket_open,
        start_ticket_close,
        network,
        token_uri,
        TGE,
        linear_vesting,
        id,
      } = campaign;
      const durationTime = start_ticket_close - start_staking_snapshot;
      console.log('linear_vesting', linear_vesting);

      // native token
      let paidTokenAddress = MAPPING_CURRENCY_ADDRESS[network]?.usdt;
      // const paidTokenInfo = await getTokenInfo(paidTokenAddress);
      if (!paidTokenAddress) {
        paidTokenAddress = NATIVE_TOKEN_ADDRESS;
      }

      const tokenIDOInfo = await getTokenInfo(token_address);
      if (!tokenIDOInfo) return;

      let factorySmartContract;

      switch (network) {
        case NETWORK_AVAILABLE.POLYGON:
          const poolPolygonAddress = process.env.REACT_APP_SMART_CONTRACT_POLYGON_FACTORY_ADDRESS;
          factorySmartContract = getContractInstance(PoolFactoryABI, poolPolygonAddress || '', true, 'write');
          break;
        case NETWORK_AVAILABLE.KLAYTN:
          const poolKlaytnAddress = process.env.REACT_APP_SMART_CONTRACT_KLAYTN_FACTORY_ADDRESS;
          factorySmartContract = getContractInstance(
            PoolFactoryABI,
            poolKlaytnAddress || '',
            true,
            'write'
          );
          break;
        default:
        // default is init value above
      }

      if (factorySmartContract) {
        let createdCampaign;
        const userWalletAddress = getState().user.data.wallet_address;
        const web3Instance = getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const obj =
          network === 'klaytn'
            ? {
                maxFeePerGas: gasPrice,
                maxPriorityFeePerGas: gasPrice,
              }
            : {};
        
        let payload;  
        const currentNetworkId = localStorage.getItem("NETWORK_ID");
        if (currentNetworkId === POLYGON_CHAIN_ID) {
            const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
            const web3Instance = await getWeb3Instance();
            const gasPrice = await web3Instance?.eth.getGasPrice();
            if (gasPrice) {
              payload = {
                from: userWalletAddress,
                maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice)))
            }
            } else {
              payload = {
                from: userWalletAddress,
                gas: 8000000,
            }
            }
        } else {
            payload = {
                from: userWalletAddress,
                gas: 8000000,
            }
        }  

        createdCampaign = await factorySmartContract.methods
          .registerPoolAndTicket(
            token_address,
            new BigNumber(amount).multipliedBy(Math.pow(10, tokenIDOInfo.decimals)).toString(),
            start_ticket_open,
            durationTime,
            start_ticket_close,
            new BigNumber(price).multipliedBy(Math.pow(10, 6)).toString(),
            0,
            linear_vesting,
            new BigNumber(TGE).multipliedBy(10 ** 2).toString(),
            id,
            token_uri ? token_uri : 'token uri',
          )
          .send({
            ...payload,
            ...obj,
          })
          .on('transactionHash', function (hash: string) {
            updateDeploySuccess({ tx_hash: hash, type: TXH_TYPE.DEPLOY_TXHASH }, campaign.id);
            dispatch({ type: alertActions.WARNING_MESSAGE, payload: 'Deploying Pool...' });
            setLoadingDeploy(true);
          });

        console.log('Deploy Response: ', createdCampaign);
        if (createdCampaign) {
          dispatch({ type: alertActions.SUCCESS_MESSAGE, payload: 'Deploy Pool Successful!' });

          let campaignHash = '';
          if (createdCampaign?.events && createdCampaign?.events && createdCampaign?.events[0]) {
            campaignHash = createdCampaign?.events[0].address;
          }
          console.log('createdCampaign', createdCampaign);
        }
      }
      console.log("RUNNN")
      setLoadingDeploy(true);
    } catch (err: any) {
      console.log('ERROR: ', err);

      dispatch({
        type: campaignActions.MY_CAMPAIGN_CREATE_FAIL,
        payload: err.message,
      });

      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });
      setLoadingDeploy(false);
    }
  };
};

export const writeResultRandomTicket = (
  idoID: any,
  ticketWinRoot: any,
  IDOContractAddress: any,
  network: any,
  fetchPoolDetail: () => void,
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      let IDOPoolSmartContract = getContractInstance(IDOPoolABI, IDOContractAddress || '', true, 'write');

      if (IDOPoolSmartContract) {
        let writeResultTicketWin;
        const userWalletAddress = getState().user.data.wallet_address;
        const web3Instance = getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const obj =
          network === 'klaytn'
            ? {
                maxFeePerGas: gasPrice,
                maxPriorityFeePerGas: gasPrice,
              }
            : {};

        let payload;  
        const currentNetworkId = localStorage.getItem("NETWORK_ID");
        if (currentNetworkId === POLYGON_CHAIN_ID) {
          const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
          const web3Instance = await getWeb3Instance();
          const gasPrice = await web3Instance?.eth.getGasPrice();
            if (gasPrice) {
              payload = {
                from: userWalletAddress,
                maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice)))
            }
            } else {
              payload = {
                from: userWalletAddress,
                gas: 8000000,
            }
            }
        } else {
            payload = {
                from: userWalletAddress,
                gas: 8000000,
            }
          }  

        writeResultTicketWin = await IDOPoolSmartContract.methods
          .updateBuyRoot(`0x${ticketWinRoot}`)
          .send({
            ...obj,
            ...payload,
          })
          .on('transactionHash', function (hash: string) {
            updateDeploySuccess({ tx_hash: hash, type: TXH_TYPE.TICKET_TXHASH }, idoID);
            dispatch({
              type: alertActions.WARNING_MESSAGE,
              payload: 'Writing Result On Blockchain...',
            });
            fetchPoolDetail();
          });
        if (writeResultTicketWin) {
          dispatch({ type: alertActions.SUCCESS_MESSAGE, payload: 'Write Result Successfully!' });
          fetchPoolDetail();
        }
      }
    } catch (err: any) {
      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });
      fetchPoolDetail();
    }
  };
};

export const writeSnapshotOnBlockchain = (
  idoID: any,
  snapshotRoot: any,
  IDOContractAddress: any,
  network: any,
  fetchPoolDetail: () => void,
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      let IDOPoolSmartContract = getContractInstance(IDOPoolABI, IDOContractAddress || '', true, 'write');

      if (IDOPoolSmartContract) {
        let writeSnapshot;
        const userWalletAddress = getState().user.data.wallet_address;
        const web3Instance = getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const obj =
          network === 'klaytn'
            ? {
                maxFeePerGas: gasPrice,
                maxPriorityFeePerGas: gasPrice,
              }
            : {};

        let payload;  
        const currentNetworkId = localStorage.getItem("NETWORK_ID");
        if (currentNetworkId === POLYGON_CHAIN_ID) {
          const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
          const web3Instance = await getWeb3Instance();
          const gasPrice = await web3Instance?.eth.getGasPrice();
            if (gasPrice) {
              payload = {
                maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                from: userWalletAddress,
            }
            } else {
              payload = {
                from: userWalletAddress,
                gas: 8000000,
            }
            }
        } else {
            payload = {
                from: userWalletAddress,
                gas: 8000000,
            }
          }

        writeSnapshot = await IDOPoolSmartContract.methods
          .updateSnapShotRoot(`0x${snapshotRoot}`)
          .send({
            ...obj,
            ...payload
          })
          .on('transactionHash', function (hash: string) {
            updateDeploySuccess({ tx_hash: hash, type: TXH_TYPE.SNAPSHOT_TXHASH }, idoID);
            dispatch({
              type: alertActions.WARNING_MESSAGE,
              payload: 'Writing Snapshot On Blockchain...',
            });
            fetchPoolDetail();
          });
        if (writeSnapshot) {
          fetchPoolDetail();
          dispatch({ type: alertActions.SUCCESS_MESSAGE, payload: 'Write Snapshot Successfully!' });
        }
      }
    } catch (err: any) {
      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });
      fetchPoolDetail();
    }
  };
};

const handleGetRandomNumber = async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => any,
  idoID: any,
  fetchPoolDetail: () => void,
): Promise<{ time_out: boolean }> => {
  return new Promise(async (resolve, reject) => {
    try {
      let timeOut;
      const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_RANDOM_NUMBER_POLYGON as string;
      let IDOPoolSmartContract = getContractInstance(IDOChainlinkABI, CONTRACT_ADDRESS || '', true, 'write');
      if (IDOPoolSmartContract) {
        const userWalletAddress = getState().user.data.wallet_address;
        const web3Instance = await getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
        let payload;
        if (gasPrice) {
          payload = {
            from: userWalletAddress,
            maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice)))
        }
        } else {
          payload = {
            from: userWalletAddress,
          }
        }

        let randomTicketResult = await IDOPoolSmartContract.methods
          .getIDOPoolRandomNumber(idoID)
          .send({
            ...payload
          })
          .on('transactionHash', function (hash: string) {
            updateDeploySuccess({ tx_hash: hash, type: TXH_TYPE.GET_RANDOM_NUMBER_TXHASH }, idoID);
            timeOut = setTimeout(() => {
              dispatch(alertWarning('Random ticket pending...'));
              fetchPoolDetail();
              return {
                time_out: true,
              };
            }, TRANSACTION_TIMEOUT);
          });
        clearTimeout(timeOut);
        resolve({
          time_out: false,
        });
        console.log('randomTicketResult', randomTicketResult);
        if (randomTicketResult) {
          dispatch(alertSuccess('Random ticket successfully!'));
          fetchPoolDetail();
        }
      }
    } catch (err: any) {
      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });
      reject({
        time_out: false,
      });
      fetchPoolDetail();
    }
  });
};

export const getRandomChainLink = (idoID: any, fetchPoolDetail: () => void) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      await handleGetRandomNumber(dispatch, getState, idoID, fetchPoolDetail);
    } catch (err: any) {
      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });
      fetchPoolDetail();
    }
  };
};

export const editTGEvesting = (
  _start: number,
  _cliff = 0,
  _duration: number,
  _tge: number,
  poolId: number,
  poolAddress: string,
  network: string,
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      let IDOPoolSmartContract = getContractInstance(IDOPoolABI, poolAddress || '', true, 'write');

      if (IDOPoolSmartContract) {
        let updateVesting;
        const userWalletAddress = getState().user.data.wallet_address;
        const web3Instance = getWeb3Instance();
        const gasPrice = await web3Instance?.eth.getGasPrice();
        const obj =
          network === 'klaytn'
            ? {
                maxFeePerGas: gasPrice,
                maxPriorityFeePerGas: gasPrice,
              }
            : {};
        let payload;  
        const currentNetworkId = localStorage.getItem("NETWORK_ID");
        if (currentNetworkId === POLYGON_CHAIN_ID) {
          const web3Instance = await getWeb3Instance();
          const gasPrice = await web3Instance?.eth.getGasPrice();
          const gas_multiplier = process.env.GAS_MULTIPLIER ? parseFloat(process.env.GAS_MULTIPLIER) : 1.1;
            if(gasPrice) {
                payload = {
                  maxPriorityFeePerGas: Web3.utils.toHex(Math.floor(gas_multiplier * Number(gasPrice))),
                  from: userWalletAddress,
              }
            } else {
                payload = {
                  from: userWalletAddress,
                  gas: 8000000,
              }
            }
        } else {
            payload = {
                from: userWalletAddress,
                gas: 8000000,
            }
          }
        
        updateVesting = await IDOPoolSmartContract.methods
          .updateVesting(_start, _cliff, _duration, _tge)
          .send({
            ...obj,
            ...payload
          })
          .on('transactionHash', function (hash: string) {
            // updateDeploySuccess({ tx_hash: hash, type: TXH_TYPE.SNAPSHOT_TXHASH }, poolId)
            dispatch({ type: alertActions.WARNING_MESSAGE, payload: 'Updating...' });
          });
        if (updateVesting) {
          dispatch({ type: alertActions.SUCCESS_MESSAGE, payload: 'Update Successfully!' });
        }
      }
    } catch (err: any) {
      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });
    }
  };
};
