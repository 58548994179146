import Button from '@material-ui/core/Button';
import CurrencyFormat from 'react-currency-format';
import { SubmitHandler, useForm } from 'react-hook-form';
import useStyles from './PoolLPForm.style';

interface FormValues {
  aNumber: number;
  bNumber: number;
  cNumber: number;
}

type ComponentProps = {
  onClose: () => void;
  onSubmit: (data : FormValues) => void;
  rewardPoolwithdrawFee: any;
};
const WithdrawalFreeForm: React.FC<ComponentProps> = ({
  onClose,
  onSubmit,
  rewardPoolwithdrawFee,
}: ComponentProps) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const { aNumber, bNumber, cNumber } = rewardPoolwithdrawFee;
  const onSubmitForm: SubmitHandler<FormValues> = (data) => {
    if(data && onSubmit){
      onSubmit(data)
      if(onClose) onClose()
    }

  };


  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className={classes.formControl}>
        <div className={classes.formControlFlex}>
          <label className={classes.formLabel}>
            Coefficient a <span style={{ color: '#e51d1d' }}>*</span>
          </label>
          <div style={{ flex: 1 }}>
            <CurrencyFormat 
               name="aNumber"
               value={aNumber / 10**6}
               className={classes.formInputBox}
               onValueChange={(e: any)=> setValue('aNumber',e.value)}
               placeholder="aNumber"/>
            <input
              type="hidden"
              name="aNumber"
              value={aNumber / 10**6}
              ref={register({
                required: true,
              })}
            />
            {/* <input
              ref={register({ required: true })}
              name="aNumber"
              defaultValue={aNumber}
              type="number"
              className={classes.formInputBox}
              placeholder="aNumber"
            /> */}
            {errors?.aNumber && <p className={classes.formErrorMessage}> aNumber is required</p>}
          </div>
        </div>
      </div>

      <div className={classes.formControl}>
        <div className={classes.formControlFlex}>
          <label className={classes.formLabel}>
            Coefficient b <span style={{ color: '#e51d1d' }}>*</span>
          </label>
          <div style={{ flex: 1 }}>
            <input
              type="hidden"
              name="bNumber"
              value={bNumber / 10**6}
              ref={register({
                required: true,
              })}
            />
            <CurrencyFormat
              name="bNumber"
              value={bNumber / 10**6}
              className={classes.formInputBox}
              onValueChange={(e: any)=> setValue('bNumber',e.value)}
              placeholder="bNumber"/>
            {errors?.bNumber && <p className={classes.formErrorMessage}>bNumber is required</p>}
          </div>
        </div>
      </div>

      <div className={classes.formControl}>
        <div className={classes.formControlFlex}>
          <label className={classes.formLabel}>
            Coefficient c <span style={{ color: '#e51d1d' }}>*</span>
          </label>
          <div style={{ flex: 1 }}>
             <input
                type="hidden"
                name="cNumber"
                value={cNumber / 10**6}
                ref={register({
                  required: true,
                })}
              />
            <CurrencyFormat 
              name="cNumber"
              value={cNumber / 10**6}
              className={classes.formInputBox}
              onValueChange={(e: any)=> setValue('cNumber',e.value)}
              placeholder="cNumber"/>
            {/* <input
              ref={register({ required: true })}
              name="cNumber"
              defaultValue={cNumber}
              type="number"
              className={classes.formInputBox}
              placeholder="cNumber"
            /> */}
            {errors?.cNumber && <p className={classes.formErrorMessage}>cNumber is required</p>}
          </div>
        </div>
      </div>

      <div className={classes.formControl}>
        *Fee is computed based on this formula:
        <br/>
          y = <b> a</b>*x^2 - <b>b</b>*x + <b>c </b>
        <br/>
          (<b> x </b> = user stake period)
      </div>

      <div className={classes.btns}>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};

export default WithdrawalFreeForm;
