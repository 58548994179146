import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import DownloadIcon from '../../../../../assets/images/download.svg';
import { getDetailsParticipants } from '../../../../../request/pool';
import exportFileTxt, { splitStringMaxLength } from '../../../../../utils/exportFile';

import useStyles from './styles';
interface IProps {
  open: boolean;
  handleClose: () => void;
  user: any;
}
const ModalListTicket = (props: IProps) => {
  const { open, handleClose, user } = props;
  const classes = useStyles();
  const { id }: any = useParams();
  const [infoTicket, setInfoTicket] = useState<any>({});

  useEffect(() => {
    if (open) {
      getDetailsParticipants(id, user?.wallet_address)
        .then((res) => {
          setInfoTicket(res);
        })
        .catch(() => {});
    }
  }, [open, id, user]);

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle className={classes.modalTitle}>
          Information
          <CloseIcon className={classes.iconClose} width={20} onClick={handleClose} />
        </DialogTitle>
        <DialogContent className={classes.contentDetail}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>Wallet address</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>{user?.wallet_address || '--'}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>Tier at Snapshot</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>{infoTicket?.tier || '--'}</Typography>
            </Grid>
          </Grid>
          {infoTicket?.tier === 'Legend' && (
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography>Guaranteed tickets</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography>{infoTicket?.GuaranteedTicket ?? '--'}</Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>Tickets bought</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>{infoTicket?.ticketBought ?? '--'}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>Ticket IDs</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.blockFieldTicket}>
                <span>{splitStringMaxLength(infoTicket?.ticketIDs?.join(', '), 50)}</span>
                {infoTicket?.ticketIDs?.length !== 0 ? (
                  <div
                    onClick={() => exportFileTxt('Ticket IDs', infoTicket?.ticketIDs?.join(', '))}
                  >
                    <img src={DownloadIcon} alt="download icon" width={22} />
                  </div>
                ) : (
                  '--'
                )}
              </Typography>
            </Grid>
          </Grid>
          {infoTicket?.winningTicket !== 0 && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography>Winning tickets</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>{infoTicket?.winningTicket ?? '--'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography> Winning ticket IDs</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.blockFieldTicket}>
                    <span>
                      {splitStringMaxLength(infoTicket?.winningTicketIDs?.join(', '), 50)}
                    </span>
                    <div
                      onClick={() =>
                        exportFileTxt(
                          'Winning ticket IDs',
                          infoTicket?.winningTicketIDs?.join(', '),
                        )
                      }
                    >
                      <img src={DownloadIcon} alt="download icon" width={22} />
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalListTicket;
