import EditIcon from '@material-ui/icons/Edit';
import useStyles from './style';

import { useState } from 'react';
import SetupTierForm from './SetupForm';
import PoolFormDialog from '../shared/Dialog';
import { Button } from '@material-ui/core';

type StakeData = {
  [key: string]: {
    label: string;
    min: number;
  };
};

type ComponentProps = {
  stakes: StakeData;
  tierIdList: any;
  onEdit?: (data: StakeData) => void;
  isPermission?:boolean
  isPolygon?: boolean
};

const SetupTier: React.FC<any> = ({ stakes, onEdit, tierIdList,isPermission, isPolygon }: ComponentProps) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState<boolean>(false);

  const openEditModal = () => {
    setShowModal(true);
  };

  const closeEditModal = () => {
    setShowModal(false);
  };

  const editTier = (form: any) => {
    onEdit?.(form);
  };

  return (
    <section className={classes.section}>
      <div className={classes.flexBetween}>
        <h4 className={classes.sectionTitle}>Set up tier minimum</h4>
      </div>
      <div className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isPermission || !isPolygon}
          onClick={openEditModal}
          className={classes.buttonEditTier}
        >
          <EditIcon />
        </Button>
        <table>
          <tbody>
            {Object.values(stakes).map(({ label, min }) => {
              return (
                <tr key={min}>
                  <th className={classes.tableTitle}>{label}</th>
                  <td>{min.toLocaleString('en')} CODE or higher</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <PoolFormDialog
          title="Edit tier minimum stake value"
          showModal={showModal}
          closeModalHandler={closeEditModal}
          style={{ dialog: classes.dialog, modalTitle: classes.modalTitle }}
        >
          <SetupTierForm
            errorMessageList={{
              starter: 'Must be a number',
              rookie: 'Must be a number bigger than Starter stake amount',
              legend: 'Must be a number bigger than Rookie stake amount',
            }}
            onClose={closeEditModal}
            onSubmit={editTier}
            form={{
              starter: stakes[tierIdList.starter]?.min,
              rookie: stakes[tierIdList.rookie]?.min,
              legend: stakes[tierIdList.legend]?.min,
            }}
          />
        </PoolFormDialog>
      </div>
    </section>
  );
};

export default SetupTier;
