import React, { useEffect, useState } from 'react';
import useStyles from '../style';
import { renderErrorCreatePool } from '../../../utils/validate';
import CloseIcon from '@material-ui/icons/Close';

interface Event {
  text: string;
  url: string;
  name: string;
}
function ProjectEvent(props: any) {
  const classes = useStyles();
  const { register, errors, poolDetail, setValue, isViewDetail } = props;
  const renderError = renderErrorCreatePool;
  const [rows, setRows] = useState<any[]>([]);
  const [event, setEvent] = useState<Event>({
    text: '',
    url: '',
    name: '',
  });

  useEffect(() => {
    if (poolDetail) {
      setRows(poolDetail.events);
      if (poolDetail.events) {
        setValue('IDOEvent', JSON.stringify(poolDetail.events), { shouldValidate: true });
      } else {
        setValue('IDOEvent', null, { shouldValidate: true });
      }
    }
  }, [poolDetail]);

  const handleAddRow = (e: any) => {
    e.preventDefault();
    if (!event.text || !event.url) return;
    setRows((oldRow) => [...oldRow, event]);
    setValue('IDOEvent', JSON.stringify(rows));
    setEvent({
      text: '',
      url: '',
      name: '',
    });
  };

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setEvent((values) => ({ ...values, [name]: value }));
  };

  const handleRemoveRow = (indexRow: number) => {
    if (!window.confirm('Do you want delete this record ?')) {
      return false;
    }
    const newRows = rows.filter((item: any, index: number) => index !== indexRow);

    setRows(newRows);
    if (newRows && newRows.length > 0) {
      setValue('IDOEvent', JSON.stringify(newRows), { shouldValidate: true });
    } else {
      setValue('IDOEvent', null, { shouldValidate: true });
    }
  };

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.exchangeRateTitle}>
          Event <span style={{ color: '#e51d1d' }}>*</span>
        </label>
        <input
          type="hidden"
          name="IDOEvent"
          value={rows && rows.length > 0 ? JSON.stringify(rows) : ''}
          ref={register({
            required: (value: any) => {
              console.log(value, rows);
            },
          })}
        />
        <ul>
          {rows &&
            rows.length > 0 &&
            rows.map((row, index) => {
              if (!row.name) {
                return '';
              } else {
                return (
                  <>
                    <li key={`${row.url}-${index}`} className={classes.projectEventItem}>
                      <a href={row.url} target="blank">{`${index + 1}. ${row.name}`}</a>
                      <button
                        className={`${classes.circleButton} remove ${
                          isViewDetail && classes.displayNone
                        }`}
                        style={{ marginRight: 16 }}
                        onClick={() => handleRemoveRow(index)}
                      >
                        <CloseIcon />
                      </button>
                    </li>
                    <p style={{ marginLeft: 10 }}>{row.text}</p>
                  </>
                );
              }
            })}
        </ul>

        <form onSubmit={handleAddRow} className={isViewDetail ? classes.displayNone : ''}>
          <div
            className={classes.formControlWrap}
            style={{ justifyContent: 'space-between', width: '100%', marginBottom: 10 }}
          >
            <div
              className={classes.formControlFlexBlock}
              style={{ width: '100%', marginRight: 10 }}
            >
              <label className={`${classes.formControlLabel} ${classes.formControlBlurLabel}`}>
                Name
              </label>
              <div className={classes.formControlRate}>
                <input
                  type="name"
                  name="name"
                  value={event.name}
                  onChange={handleChange}
                  maxLength={255}
                  className={classes.formControlInput}
                />
              </div>
            </div>

            <div className={classes.formControlFlexBlock} style={{ width: '100%', marginLeft: 10 }}>
              <label className={`${classes.formControlLabel} ${classes.formControlBlurLabel}`}>
                URL
              </label>
              <div className={classes.formControlRate}>
                <input
                  type="text"
                  name="url"
                  value={event.url}
                  maxLength={255}
                  className={classes.formControlInput}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div
            className={classes.formControlFlexBlock}
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '89%' }}>
              <label className={`${classes.formControlLabel} ${classes.formControlBlurLabel}`}>
                Description
              </label>
              <div className={classes.formControlRate}>
                <textarea
                  name="text"
                  value={event.text}
                  onChange={handleChange}
                  maxLength={255}
                  className={classes.formControlInput}
                />
              </div>
            </div>
            <div className={classes.formControlFlexBlock}>
              <button
                className={classes.circleButton}
                style={{ marginRight: 16, marginTop: 26 }}
                type="submit"
              >
                <img src="/images/icon_plus.svg" />
              </button>
            </div>
          </div>
          <div className={classes.formControlWrap}>
            {!rows || rows.length === 0 ? (
              <p className={classes.formErrorMessage}>{renderError(errors, 'IDOEvent')}</p>
            ) : null}
          </div>
        </form>
      </div>
    </>
  );
}

export default ProjectEvent;
