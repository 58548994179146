import { BaseRequest } from './Request';
import { apiRoute } from '../utils';
import { PAGE_SIZE } from '../constants';

export const getPoolList = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute('/admin/pools/no-reward/lps');
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const getRewardPoolList = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute('/admin/pools/reward');
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const refreshPoolList = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute('/admin/pools/lps/sync');
  const response = (await baseRequest.post(url, {})) as any;
  const resObject = await response.json();
  return resObject;
};

export const createPoolLP = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute('/admin/pools/no-reward/lps');

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();
  return resObject;
};

export const createPool = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute('/ido');

  const response = (await baseRequest.post(url, data)) as any;
  const resData = {
    status: response.status,
    data: await response.json(),
  };
  return resData;
};

export const updatePool = async (data: any, id: any, isDeploy = false) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/ido/update/${id}`);
  if(isDeploy) url = apiRoute(`/ido/update-offchain/${id}`);
  
  const response = (await baseRequest.put(url, data)) as any;
  const resData = {
    status:response.status,
    data:await response.json()
  }
  return resData;
};

export const deletePool = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/ido/${id}`);

  const response = await baseRequest.deleteNoBody(url) as any;
  console.log('response',response);
  const resData = {
    status:response.status,
    data:await response.json()
  }
  return resData;
};

export const getPoolDetail = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/ido/${id}/admin-detail`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const updateDeploySuccess = async (data: any, id: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/ido/${id}/update-flag`);

  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();
  return resObject;
};

export const changeDisplayStatus = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/pool/${data.poolId}/change-display`);

  const response = (await baseRequest.post(url, {
    is_display: data.isDisplay,
  })) as any;

  const resObject = await response.json();
  return resObject;
};


export const getListTicket = async (id: any, type: string, page_number: number, page_size = 10) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(
    `/ticket/${id}?is_win=${type}&page_number=${page_number}&page_size=${page_size}`,
  );

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const getListParticipants = async (id: number, page_number: number, page_size = PAGE_SIZE) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(
    `/whitelist/participants/${id}?page_size=${page_size}&page_number=${page_number}`,
  );

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const getDetailsParticipants = async (id: number, walletAddress: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/ticket/${id}/${walletAddress}`,
  );

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const randomTicketWin = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/ticket/lottery`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();
  return resObject;
};

export const reCalculateBuyLimits = async (id: any, data: any = {}) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/ido/snapshot/${id}`);

  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();
  return resObject;
};
