import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import { useEffect } from 'react';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { trimMiddlePartAddress } from '../../../utils/accountAddress';
import { convertMomentDate } from '../../../utils/convertDate';
import useStyles from './style';

interface IPropsKYCUser {
  user: any;
  currentOpen: string;
  setCurrentOpen: (data: any) => void;
}

const KycUserRow = (props: IPropsKYCUser) => {
  const { user, currentOpen, setCurrentOpen } = props;
  const classes = useStyles();
  const { ref, isVisible, setIsVisible } = useComponentVisible();

  useEffect(() => {
    currentOpen && setCurrentOpen('');
  }, [user]);

  useEffect(() => {
    setIsVisible(user.id === currentOpen);
  }, [currentOpen, setIsVisible, user.id]);

  const getActiveStatus = (user: any) => {
    switch (user.is_kyc) {
      case 0:
        return 'Not completed';
      case 1:
        return 'Completed';
      default:
        return '';
    }
  };

  return (
    <TableRow
      ref={ref}
      className={classes.tableRow}
      key={user.id}
      // component={Link}
      // to={adminRoute(`/kyc-user-detail/${user.id}`)}
    >
      <TableCell className={classes.tableCell} align="left">
        <Tooltip title={user.wallet_address} placement="top">
          <span>{trimMiddlePartAddress(user.wallet_address, 6, 4) || '--'}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.tableCell} align="left">
        <span className={classes.wordBreak}>{user.email || '--'}</span>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <span className={`${classes.wordBreak} ${classes.capitalTier}`}>{user.tier}</span>
      </TableCell>
      <TableCell className={classes.tableCell} align="left">
        <div className={classes.tableCellFlex}>
          <div className="left">
            <span className={`admin-status admin-${user.is_kyc}`}></span>
            {getActiveStatus(user)}
          </div>
        </div>
      </TableCell>

      <TableCell className={classes.tableCell} align="left">
        <span className={classes.wordBreak}>{convertMomentDate(user.joined_at) || '--'}</span>
      </TableCell>
    </TableRow>
  );
};

export default KycUserRow;
