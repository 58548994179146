import { Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      maxWidth: 900,
      padding: 0,
      borderRadius: 20,
    },
    title: {
      font: 'inherit',
    },
    closeButton: {
      position: 'absolute',
      right: 25,
      top: 25,
      color: 'black',
      opacity: 0.5,
      padding: 8,

      '&:hover': {
        backgroundColor: '#262530',
      },
    },
    svgIcon: {
      fontSize: 5,
    },
  });

export default styles;
