import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SelectTierStatus from '../../components/Base/SelectTierStatus';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { PAGE_SIZE } from '../../constants';
import useDebounce from '../../hooks/useDebounce';
import { getKycUserList } from '../../request/kyc-user';
import { alertFailure } from '../../store/actions/alert';
import { formatPrecisionAmount } from '../../utils';
import KycUserRow from './KycUserRow/KycUserRow';
import useStyles from './style';

const tableHeaders = ['Wallet Address', 'Email', 'Tier', 'KYC Status', 'Joined Date'];

const KycUserList: React.FC<any> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [currentOpen, setCurrentOpen] = useState('');
  const [query, setQuery] = useState<any>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const debounceSearch = useDebounce(query, 1000);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [tierStatus, setTierStatus] = useState<any>('');
  const [totalUser, setTotalUser] = useState<any>(0);

  const getUserListInfo = useCallback(() => {
    try {
      const queryParams = {
        page_number: currentPage,
        searchValue: debounceSearch,
        page_size: PAGE_SIZE,
        tier: tierStatus,
      };
      getKycUserList(queryParams).then(async (res: any) => {
        const { count } = res.meta;
        const { data } = res;
        setLastPage(Math.ceil(count / PAGE_SIZE));
        setUsers(data);
        setTotalUser(count);
      });
    } catch (err) {
      dispatch(alertFailure('User load fail !!!'));
      setFailure(true);
    } finally {
      setLoading(false);
    }
  }, [currentPage, debounceSearch, tierStatus, dispatch]);

  useLayoutEffect(() => {
    getUserListInfo();
  }, [getUserListInfo]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page - 1);
  };

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const handleChangeSelectTier = (e: any) => {
    setTierStatus(e.target.value);
    setCurrentPage(0);
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueSearch = event.target.value;
    if (!valueSearch.startsWith(' ')) {
      setLoading(true);
      setQuery(valueSearch);
      setCurrentPage(0);
    }
  };

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          {/* <Button
            to={adminRoute("/kyc-user-create")}
            text={"Create New Kyc User"}
            icon={"icon_plus.svg"}
          /> */}
          <span className={classes.titleSelect}>Tier</span>
          <SelectTierStatus value={tierStatus} onChange={handleChangeSelectTier} />
        </div>
        <div className={classes.headerRight}>
          <div className={classes.boxSearch}>
            <input
              value={query}
              className={classes.inputSearch}
              onChange={onChangeSearch}
              placeholder="Search"
            />
            <img className={classes.iconSearch} src="/images/icon-search.svg" alt="" />
          </div>
        </div>
      </div>
      <span>Total: {formatPrecisionAmount(totalUser) ?? '--'}</span>

      <TableContainer component={Paper} className={classes.tableContainer}>
        {loading ? (
          [...Array(10)].map((num, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={'100%'} />
            </div>
          ))
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {tableHeaders.map((tableHeader: string, index: number) => (
                  <TableCell key={index} className={classes.tableHeader}>
                    {tableHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {users &&
                users.length > 0 &&
                users.map((admin: any, index: number) => (
                  <KycUserRow
                    key={index}
                    currentOpen={currentOpen}
                    setCurrentOpen={handleCurrentOpenSet}
                    user={admin}
                  />
                ))}
            </TableBody>
          </Table>
        )}
        {failure ? (
          <p className={classes.errorMessage}>{failure}</p>
        ) : (!users || users.length === 0) && !loading ? (
          <p className={classes.noDataMessage}>There is no data</p>
        ) : (
          <>
            {users && lastPage > 1 && (
              <Pagination
                page={currentPage + 1}
                className={classes.pagination}
                count={lastPage}
                onChange={handlePaginationChange}
              />
            )}
          </>
        )}
      </TableContainer>
    </DefaultLayout>
  );
};

export default KycUserList;
