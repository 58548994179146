import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function usePermission(roleArr:Array<string>) {
  const [isPermission, setIsPermission] = useState(true);
  const store = useSelector((state: any) => state);
  const { roles } = store.user.data
  useEffect(() => {
    if(roles && roles.length > 0){
      setIsPermission(roleArr.some(item => roles.includes(item)))
    }else{
      setIsPermission(false)
    }

  }, [roleArr]);

  return isPermission;
}

export default usePermission;
