import React, { memo } from 'react';
import useStyles from './styles';

const NetworkWarningBanner: React.FC<any> = (props: any) => {
  const { poolDetail } = props;
  const styles = useStyles();

  return (
    <>
      <div className={styles.loginErrorBanner}>
        <img src="/images/red-warning.svg" alt="red-warning icon" />
        <span className={styles.loginErrorBannerText}>
          Wrong network. Please switch to{' '}
          <span style={{ textTransform: 'capitalize' }}>{poolDetail?.network}</span> to continue
        </span>
      </div>
    </>
  );
};

export default memo(NetworkWarningBanner);
