import React, { useEffect, useMemo } from 'react';
import useStyles from '../../style';
import { renderErrorCreatePool } from '../../../../utils/validate';
import { MESSAGE_INVALID_URL, REGEX_URL } from '../../../../constants';

function WhitelistSocialField(props: any) {
  const classes = useStyles();
  const {
    register,
    setValue,
    errors,
    watch,
    getValues,
    needValidate,
    poolDetail,
    fieldName,
    placeholder,
    isViewDetail,
  } = props;
  const renderError = renderErrorCreatePool;
  // const isDeployed = !!poolDetail?.is_deploy;
  const watchIDODiscordBot = watch('ido_discord_bot');
  const watchPlatformDiscordBot = watch('platform_discord_bot');

  const renderErrorDiscordBot = useMemo(() => {
    if (watchIDODiscordBot && watchPlatformDiscordBot) {
      if (watchIDODiscordBot === watchPlatformDiscordBot) {
        return true;
      }
    } else return false;
  }, [watchIDODiscordBot, watchPlatformDiscordBot]);

  const watchIDOTelegramBot = watch('ido_telegram_bot');
  const watchPlatformTelegramBot = watch('platform_telegram_bot');

  const renderErrorTelegramBot = useMemo(() => {
    if (watchIDOTelegramBot && watchPlatformTelegramBot) {
      if (watchIDOTelegramBot === watchPlatformTelegramBot) {
        return true;
      }
    } else return false;
  }, [watchIDOTelegramBot, watchPlatformTelegramBot]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel} style={{ textTransform: 'capitalize' }}>
          {fieldName.replaceAll('_', ' ')}
        </label>
        <input
          type="text"
          placeholder={placeholder ? placeholder : ''}
          name={fieldName}
          defaultValue={poolDetail?.[fieldName]}
          ref={
            fieldName === 'ido_discord_link' || fieldName === 'ido_website'
              ? register({
                  pattern: {
                    value: REGEX_URL,
                    message: MESSAGE_INVALID_URL,
                  },
                })
              : register({
                  // required: true
                })
          }
          maxLength={255}
          className={classes.formControlInput}
          disabled={isViewDetail}
        />
        <p className={classes.formErrorMessage}>{renderError(errors, fieldName)}</p>
        {fieldName === 'ido_discord_bot' && renderErrorDiscordBot && (
          <p className={classes.formErrorMessage}>Duplicate Discord Bot</p>
        )}
        {fieldName === 'ido_telegram_bot' && renderErrorTelegramBot && (
          <p className={classes.formErrorMessage}>Duplicate Telegram Bot</p>
        )}
      </div>
    </>
  );
}

export default WhitelistSocialField;
