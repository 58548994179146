import { DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { BUY_TYPE, DATETIME_FORMAT } from '../../../constants';
import { useCommonStyle } from '../../../styles';
import { renderErrorCreatePool } from '../../../utils/validate';
import useStyles from '../style';
import { values } from 'lodash';

function DurationTime(props: any) {
  const classes = useStyles();
  const commonStyle = useCommonStyle();
  const {
    register,
    setValue,
    getValues,
    errors,
    control,
    watch,
    poolDetail,
    needValidate,
    isViewDetail,
  } = props;
  const renderError = renderErrorCreatePool;
  
// eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'day');
  };
  
  useEffect(() => {
    if (poolDetail) {
      // Join Times
      if (poolDetail.start_staking_snapshot) {
        setValue(
          'start_staking_snapshot',
          moment(poolDetail?.start_staking_snapshot, DATETIME_FORMAT) || '',
        );
      }
      if (poolDetail.start_ticket_open) {
        setValue('start_ticket_open', moment(poolDetail?.start_ticket_open, DATETIME_FORMAT) || '');
      }
      if (poolDetail.start_ticket_close) {
        setValue(
          'start_ticket_close',
          moment(poolDetail?.start_ticket_close, DATETIME_FORMAT) || '',
        );
      }
      if (poolDetail.start_ticket_close) {
        setValue('start_pool_end', moment(poolDetail.start_pool_end, DATETIME_FORMAT) || "");
      }
    }
  }, [poolDetail, setValue]);

  const isDeployed = !!poolDetail?.is_deploy;

  // Convert and format campaignClaimConfig table

  return (
    <>
   
        <div className={classes.formControlFlexBlock}>
          <label className={classes.formControlLabel}>Staking Snapshot <span style={{ color: '#e51d1d' }}>*</span></label>
          <div style={{marginBottom: 15}}>
            <Controller
              control={control}
              rules={{
                required: true,
                validate: {
                  greaterOrEqualToday: (value) => {
                    if (isDeployed) return true;
                    return new Date(value) >= new Date();
                  },
                }
              }}
              name="start_staking_snapshot"
              render={(field) => {
                return (
                  <DatePicker
                    {...field}
                    format="MM-DD-YYYY HH:mm"
                    showTime={{
                      defaultValue: moment('00:00', 'HH:mm'),
                      format: 'HH:mm',
                    }}
                    onSelect={(datetimeSelected: any) => {
                      setValue(field.name, datetimeSelected, { shouldValidate: true });
                    }}
                    minuteStep={10}
                    className={`${commonStyle.DateTimePicker} ${classes.formDatePicker}`}
                    disabled={isDeployed || isViewDetail}
                    disabledDate={disabledDate}
                  />
                );
              }}
            />
          </div>
          <div className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}>
            {renderError(errors, 'start_staking_snapshot')}
          </div>
        </div>

        <div className={classes.formControlFlexBlock}>
          <label className={classes.formControlLabel}>Ticket Open <span style={{ color: '#e51d1d' }}>*</span></label>
          <div style={{marginBottom: 15}}>
            <Controller
              control={control}
              rules={{
                required: true,
                validate: {
                  greateOrEqualStakingSnapshot: value => {
                    // if (!needValidate) return true;
                    if (isDeployed) return true;
                    const stakingSnapshotTime = getValues('start_staking_snapshot');
                    const valueUnix = moment(value).unix();
                    const stakingSnapshotTimeUnix = moment(stakingSnapshotTime).unix();
                  

                    return stakingSnapshotTime
                      ? valueUnix > stakingSnapshotTimeUnix
                      : valueUnix > moment().unix();
                  },
                },
              }}
              name="start_ticket_open"
              render={(field) => {
                return (
                  <DatePicker
                    {...field}
                    format="MM-DD-YYYY HH:mm"
                    showTime={{
                      defaultValue: moment('00:00', 'HH:mm'),
                      format: 'HH:mm',
                    }}
                    onSelect={(datetimeSelected: any) => {
                      setValue(field.name, datetimeSelected, { shouldValidate: true });
                    }}
                    minuteStep={10}
                    className={`${commonStyle.DateTimePicker} ${classes.formDatePicker}`}
                    disabled={isDeployed || isViewDetail}
                    disabledDate={disabledDate}
                  />
                );
              }}
            />
          </div>
          <div className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}>
            {renderError(errors, 'start_ticket_open')}
          </div>
        </div>


        <div className={classes.formControlFlexBlock}>
          <label className={classes.formControlLabel}>
            Ticket Close <span style={{ color: '#e51d1d' }}>*</span>
          </label>
          <div style={{ marginBottom: 15 }}>
            <Controller
              control={control}
              rules={{
                required: true,
                validate: {
                  greateOrEqualOpenTicket: value => {
                    if (!needValidate && !value) return true;
                    const startTicketOpenTime = getValues('start_ticket_open');
                    const valueUnix = moment(value).unix();
                    const startTicketOpenTimeUnix = moment(startTicketOpenTime).unix();
                    

                    return startTicketOpenTime ? valueUnix > startTicketOpenTimeUnix : valueUnix > moment().unix();
                  }
                }
              }}
              name="start_ticket_close"
              render={(field) => {
                return (
                  <DatePicker
                    {...field}
                    format="MM-DD-YYYY HH:mm"
                    showTime={{
                      defaultValue: moment('00:00', 'HH:mm'),
                      format: 'HH:mm',
                    }}
                    onSelect={(datetimeSelected: any) => {
                      setValue(field.name, datetimeSelected, { shouldValidate: true });
                    }}
                    minuteStep={10}
                    className={`${commonStyle.DateTimePicker} ${classes.formDatePicker}`}
                    disabled={isDeployed || isViewDetail}
                    disabledDate={disabledDate}

                  />
                );
              }}
            />
          </div>
          <div className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}>
            {renderError(errors, 'start_ticket_close')}
          </div>
        </div>

        <div className={classes.formControlFlexBlock}>
          <label className={classes.formControlLabel}>Pool End <span style={{ color: '#e51d1d' }}>*</span></label>
          <div style={{ marginBottom: 15 }}>
            <Controller
              control={control}
              rules={{
                required: true,
                validate: {
                  greateOrEqualCloseTicket: value => {
                    if (!needValidate && !value) return true;
                    const startTicketCloseTime = getValues('start_ticket_close');
                    const valueUnix = moment(value).unix();
                    const startTicketCloseTimeUnix = moment(startTicketCloseTime).unix();
                    

                    return startTicketCloseTime ? valueUnix > startTicketCloseTimeUnix : valueUnix > moment().unix();
                  }
                }
              }}
              name="start_pool_end"
              render={(field) => {
                return (
                  <DatePicker
                    {...field}
                    format="MM-DD-YYYY HH:mm"
                    showTime={{
                      defaultValue: moment("00:00", "HH:mm"),
                      format: "HH:mm"
                    }}
                    onSelect={(datetimeSelected: any) => {
                      setValue(field.name, datetimeSelected, { shouldValidate: true });
                    }}
                    minuteStep={10}
                    className={`${commonStyle.DateTimePicker} ${classes.formDatePicker}`}
                    disabled={isDeployed || isViewDetail}
                    disabledDate={disabledDate}

                  />
                );
              }}
            />
          </div>

          <div className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}>
            {renderError(errors, 'start_pool_end')}
          </div>
        </div>
    </>
  );
}

export default DurationTime;
