import React from 'react';
import WhitelistSocialField from './WhitelistSocialField';
import useStyles from '../../style';

function WhitelistSocialRequirement(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, watch, control, poolDetail, isViewDetail } = props;

  return (
    <>
      <div>
        <label className={classes.exchangeRateTitle}>Whitelist Social Requirements</label>
      </div>
      <WhitelistSocialField
        fieldName={'ido_twitter'}
        placeholder={'E.g: CodeDAO'}
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />

      <WhitelistSocialField
        fieldName={'ido_discord'}
        placeholder={'E.g: CodeDAO'}
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />
      
      <WhitelistSocialField
        fieldName={'ido_discord_link'}
        placeholder={'E.g: https://discord.com'}
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />

      <WhitelistSocialField
        fieldName={'ido_telegram'}
        placeholder={'E.g: CodeDAO'}
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />

      <WhitelistSocialField
        fieldName={'ido_website'}
        placeholder={'E.g: CodeDAO'}
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />
      
      <WhitelistSocialField
        fieldName={'ido_discord_bot'}
        placeholder={'E.g: CodeDAO'}
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />
      
       <WhitelistSocialField
        fieldName={'ido_telegram_bot'}
        placeholder={'E.g: CodeDAO'}
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isViewDetail={isViewDetail}
      />
    </>
  );
}

export default WhitelistSocialRequirement;
