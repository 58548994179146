import Button from '@material-ui/core/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import BigNumber from 'bignumber.js';
import useStyles from './PoolLPForm.style';
import CurrencyFormat from 'react-currency-format';

interface FormValues {
  withDrawFeeTime: number;
}

type ComponentProps = {
  onClose: () => void;
  onSubmit: (data: FormValues) => void;
  withdrawFee: any;
};
const WithdrawalPeriodLimitForm: React.FC<ComponentProps> = ({
  onClose,
  onSubmit,
  withdrawFee,
}: ComponentProps) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const { withDrawFeeTime } = withdrawFee;
  const convertDataWithDrawFeeTime = new BigNumber(withDrawFeeTime).dividedBy(86400).toNumber();
  const onSubmitForm: SubmitHandler<FormValues> = (data) => {
    if (data && onSubmit) {
      onSubmit(data);
      if (onClose) onClose();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className={classes.formControl}>
        <div>
          <label className={classes.formLabel}>
            The user will not be charged withdrawal fee if they stake more than
          </label>
          <div style={{ flex: 1 }}>
            <input
              ref={register({
                required: true,
                validate: {
                  min: (value) => value >= 1,
                },
              })}
              name="withDrawFeeTime"
              defaultValue={convertDataWithDrawFeeTime}
              type="hidden"
              className={classes.formInputBox}
              placeholder="days"
            />
            <CurrencyFormat
              name="withDrawFeeTime"
              value={convertDataWithDrawFeeTime}
              className={classes.formInputBox}
              onValueChange={(e: any) => setValue('withDrawFeeTime', e.value)}
              placeholder="days"
            />
            {errors?.withDrawFeeTime && errors?.withDrawFeeTime?.type === 'min' && (
              <p className={classes.formErrorMessage}> Days must be greater than or equal to 1</p>
            )}
            {errors?.withDrawFeeTime && errors?.withDrawFeeTime?.type !== 'min' && (
              <p className={classes.formErrorMessage}> Days is required</p>
            )}
          </div>
          day(s)
        </div>
      </div>

      <div className={classes.btns}>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};

export default WithdrawalPeriodLimitForm;
