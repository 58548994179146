import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  tableTitle: {
    paddingTop: '0.5rem',
    paddingRight: '2.5rem',
    textAlign: 'left',
    fontSize: '1rem',
    verticalAlign: 'top',
  },

  table: {
    '& tr': {
      padding: '0.75rem 0',
    },
  },

  formInput: {
    '& > p': {
      margin: '0.75rem 0',
    },
  },

  iconImage: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    marginBottom: '1rem',
  },

  conversionInput: {
    display: 'flex',
    alignItems: 'flex-start',

    '& > span': {
      marginTop: '0.5rem',
      marginRight: '1rem',
    },
  },

  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '75%',
    margin: '0 auto',
  },
}));

export default styles;
