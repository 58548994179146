import React, { useMemo } from 'react';
import useStyles from '../../style';
import { renderErrorCreatePool } from '../../../../utils/validate';

function TelegramBot(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, watch, getValues, needValidate, poolDetail, isViewDetail } =
    props;
  const renderError = renderErrorCreatePool;

  const watchIDOTelegramBot = watch('ido_telegram_bot');
  const watchPlatformTelegramBot = watch('platform_telegram_bot');
  const renderErrorTelegramBot = useMemo(() => {
    if (watchIDOTelegramBot && watchPlatformTelegramBot) {
      if (watchIDOTelegramBot === watchPlatformTelegramBot) {
        return true;
      }
    } else return false;
  }, [watchIDOTelegramBot, watchPlatformTelegramBot]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Self Telegram Bot</label>
        <input
          type="text"
          name="platform_telegram_bot"
          defaultValue={poolDetail?.platform_telegram_bot}
          ref={register({
            // required: true
          })}
          className={classes.formControlInput}
          disabled={isViewDetail}
        />
        <p className={classes.formErrorMessage}>{renderError(errors, 'platform_telegram_bot')}</p>
        {renderErrorTelegramBot && (
          <p className={classes.formErrorMessage}>Duplicate Telegram Bot</p>
        )}
      </div>
    </>
  );
}

export default TelegramBot;
