import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { formatPrecisionAmount } from '../../../utils';
import { calculatorTicket } from '../../../utils/formatNumber';
import useStyles from './styles';

const Ticketing = (props: any) => {
  const { poolDetail } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.spacingBlock}>
      <Grid item xs={3}>
        <Typography className={classes.titleField}>Ticketing</Typography>
      </Grid>
      <Grid item xs={9}>
        <Box className={classes.ticketingContent}>
          <Typography>1 Ticket = {formatPrecisionAmount(poolDetail.price) ?? '--'} USDC</Typography>
        </Box>
        <Box className={classes.ticketingContent}>
          <Typography>Distribution</Typography>
          <Grid container className={classes.subContent}>
            <Grid item xs={12}>
              <Typography>
                Total{' '}
                {formatPrecisionAmount(
                  calculatorTicket(poolDetail.price, poolDetail.conversion_rate, poolDetail.amount),
                ) || '--'}{' '}
                tickets
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Legend : Rookie : Starter</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {formatPrecisionAmount(poolDetail.legend_distribution) ?? '--'} :{' '}
                {formatPrecisionAmount(poolDetail.rookie_distribution) ?? '--'} :{' '}
                {formatPrecisionAmount(poolDetail.starter_distribution) ?? '--'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.ticketingContent}>
          <Typography>Buying limit (tickets)</Typography>
          <Box className={classes.subContent}>
            <TableContainer>
              <Table style={{ width: 400 }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">Min</TableCell>
                    <TableCell align="center">Max</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Starter</TableCell>
                    <TableCell align="center">
                      {formatPrecisionAmount(poolDetail.starter_min_buy) ?? '--'}
                    </TableCell>
                    <TableCell align="center">
                      {formatPrecisionAmount(poolDetail.starter_max_buy) ?? '--'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Rookie</TableCell>
                    <TableCell align="center">
                      {formatPrecisionAmount(poolDetail.rookie_min_buy) ?? '--'}
                    </TableCell>
                    <TableCell align="center">
                      {formatPrecisionAmount(poolDetail.rookie_max_buy) ?? '--'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Ticketing;
