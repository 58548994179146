import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 20,
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
  iconClose: {
    position: 'absolute',
    top: 25,
    right: 25,
    opacity: 0.5,
    cursor: 'pointer',
  },
  contentDetail: {
    paddingBottom: 50,
    paddingLeft: 50,
  },
  blockFieldTicket: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginLeft: 5,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
