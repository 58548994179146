import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonStyle } from '../../styles';
import useStyles from './style';

import { Box, CircularProgress, Grid } from '@material-ui/core';
import { Tabs } from 'antd';
import { withRouter } from 'react-router-dom';
import { CHAIN_IDS, CHAIN_ID_NAME_MAPPING, POOL_IS_PRIVATE, ROLE_ADMIN } from '../../constants';
import { createPool, updatePool } from '../../request/pool';
import { alertFailure, alertSuccess } from '../../store/actions/alert';
import { deployPool } from '../../store/actions/campaign';
import { adminRoute, formatPrecisionAmount } from '../../utils';
import { getTokenInfo, TokenType } from '../../utils/token';

import { Typography } from '@material-ui/core';
import AcceptCurrency from './Components/AcceptCurrency';
import DisplayPoolSwitch from './Components/DisplayPoolSwitch';
import DurationTime from './Components/DurationTimes';
import ExchangeRateETH from './Components/ExchangeRateETH';
import NetworkAvailable from './Components/NetworkAvailable';
import PoolBanner from './Components/PoolBanner';
import PoolDescription from './Components/PoolDescription';
import PoolHash from './Components/PoolHash';
import PoolName from './Components/PoolName';
import ProjectCategory from './Components/ProjectCategory';
import ProjectEvent from './Components/ProjectEvent';
import SocialSetting from './Components/SocialSetting/SocialSetting';
import TokenAddress from './Components/TokenAddress';
import TokenLogo from './Components/TokenLogo';
import TokenSymbol from './Components/TokenSymbol';
import TotalCoinSold from './Components/TotalCoinSold';
import WhitelistSocialRequirement from './Components/WhitelistSocialRequirement';
import GleamRequirement from './Components/WhitelistSocialRequirement/GleamRequirement';

import Bignumber from 'bignumber.js';
import { calculatorTicket } from '../../utils/formatNumber';
import CompetitorBenchmarkTable from './Components/Benchmark/CompetitorBenchmarkTable';
import BuyingLimit from './Components/BuyingLimit';
import Distribution from './Components/Distribution';
import ExchangeTicket from './Components/ExchangeTicket';
import IDOSNS from './Components/IDOSNS';
import ProjectRatingTable from './Components/ProjectRating/ProjectRatingTable';
import VestingPolicy from './Components/VestingPolicy';
import usePermission from '../../hooks/usePermission';
import NetworkWarningBanner from '../../components/Base/RightDefaultLayout/NetworkWarningBanner';
import moment from 'moment';

function PoolForm(props: any) {
  const classes = useStyles();
  const commonStyle = useCommonStyle();
  const dispatch = useDispatch();
  const history = props.history;

  const { data: loginUser } = useSelector((state: any) => state.user);

  const { isEdit, poolDetail, isViewDetail = false, fetchPoolDetailData, poolId } = props;
  const isPermission = usePermission([ROLE_ADMIN.ADMIN_FACTORY_POOL, ROLE_ADMIN.SUPPER_ADMIN]);

  const [loading, setLoading] = useState(false);
  const [loadingDeploy, setLoadingDeploy] = useState(false);
  const [deployed, setDeployed] = useState(false);
  const [token, setToken] = useState<TokenType | null>(null);
  const [needValidate, setNeedValidate] = useState(false);
  const { currentNetworkId } = useSelector((state: any) => state).userCurrentNetwork;
  const { register, setValue, getValues, errors, handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: poolDetail,
    reValidateMode: 'onChange',
  });

  const watchIDODiscordBot = watch('ido_discord_bot');
  const watchPlatformDiscordBot = watch('platform_discord_bot');

  const renderErrorDiscordBot = useMemo(() => {
    if (watchIDODiscordBot && watchPlatformDiscordBot) {
      if (watchIDODiscordBot === watchPlatformDiscordBot) {
        return true;
      }
    } else return false;
  }, [watchIDODiscordBot, watchPlatformDiscordBot]);

  const watchIDOTelegramBot = watch('ido_telegram_bot');
  const watchPlatformTelegramBot = watch('platform_telegram_bot');

  const renderErrorTelegramBot = useMemo(() => {
    if (watchIDOTelegramBot && watchPlatformTelegramBot) {
      if (watchIDOTelegramBot === watchPlatformTelegramBot) {
        return true;
      }
    } else return false;
  }, [watchIDOTelegramBot, watchPlatformTelegramBot]);

  const createUpdatePool = async (data: any) => {
    let tokenInfo: any = {
      symbol: data?.token_symbol,
    };

    if (data.token) {
      tokenInfo = await getTokenInforDetail(data.token);
      if (!tokenInfo?.symbol) {
        throw Error('Token Information has not been loaded !!!');
      }

      tokenInfo.symbol = data?.token_symbol;
    }
    const distribution = JSON.parse(data.distribution) || {};

    const IDORating = JSON.parse(data.project_rating);
    const IDOSNSparse = JSON.parse(data.IDOSNS);
    const submitData = {
      ...data,
      IDOEvent: JSON.parse(data.IDOEvent),
      IDOCompetitorBenmark: JSON.parse(data.competitor_benchmark),
      registed_by: loginUser?.wallet_address,

      amount: new Bignumber(data.totalSoldCoin).toNumber(),
      price: new Bignumber(data.priceTickerPerCurrencyToken).toNumber(),
      conversion_rate: new Bignumber(data.tokenRate).toNumber(),

      starter_distribution: new Bignumber(distribution.starter).toNumber(),
      rookie_distribution: new Bignumber(distribution.rookie).toNumber(),
      legend_distribution: new Bignumber(distribution.legend).toNumber(),
      TGE: new Bignumber(data.TGE).toNumber(),
      linear_vesting: data.start_pool_end.unix() - data.start_ticket_close.unix(),

      start_staking_snapshot: data.start_staking_snapshot
        ? data.start_staking_snapshot.utc().format()
        : null,
      start_ticket_close: data.start_ticket_close ? data.start_ticket_close.utc().format() : null,
      start_ticket_open: data.start_ticket_open ? data.start_ticket_open.utc().format() : null,
      start_pool_end: data.start_pool_end ? data.start_pool_end.utc().format() : null,

      IDORating: IDORating,
      IDOSNS: IDOSNSparse,
      is_display: data.is_display || false,
      category: data.project_category,
      starter_min_buy: new Bignumber(data.starter_min_buy).toNumber(),
      starter_max_buy: new Bignumber(data.starter_max_buy).toNumber(),
      rookie_min_buy: new Bignumber(data.rookie_min_buy).toNumber(),
      rookie_max_buy: new Bignumber(data.rookie_max_buy).toNumber(),
      
      token_address: data.token,

      social_discord:data.platform_discord,
      social_telegram:data.platform_telegram,
      social_twitter:data.platform_twitter,
      token_uri:'hello'
    };

    console.log('[createUpdatePool] - Submit with data: ', submitData);

    let response = {};
    if (isEdit) {
      response = await updatePool(submitData, poolDetail.id);
    } else {
      response = await createPool(submitData);
    }

    return response;
  };

  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response: any = await createUpdatePool(data);
      if (response.status === 201 || response.status === 200) {
        dispatch(alertSuccess('Successful!'));
        if (isEdit) {
          // window.location.reload();
          fetchPoolDetailData && fetchPoolDetailData(poolDetail.id);
        } else {
          history.push(adminRoute('/campaigns'));
        }
      } else if(response.status === 400) {
        console.log('response',response);
        const {error} = response.data

        dispatch(alertFailure(error?.details?.message ? error?.details?.message : 'Fail!'));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('ERROR: ', e);
    }
  };

  // Update After Deploy
  const updatePoolAfterDeloy = async (data: any) => {
    // Format Claim Config
    console.log('data', data);


    const IDORating = JSON.parse(data.project_rating) || {};
    const IDOSNSparse = JSON.parse(data.IDOSNS);
    const submitData = {
      IDOEvent: JSON.parse(data.IDOEvent),
      IDOCompetitorBenmark: JSON.parse(data.competitor_benchmark),
      category: data.project_category,
      IDORating: IDORating,
      IDOSNS: IDOSNSparse,

      token_address: data.token,

      is_display: data.is_display,
      project_name: data.project_name,
      cover_photo: data.cover_photo,
      project_description: data.project_description,
      differentiation_from_competitors: data.differentiation_from_competitors,
      FAQ: data.FAQ,

      platform_discord: data.platform_discord,
      platform_telegram: data.platform_telegram,
      platform_twitter: data.platform_twitter,
      platform_discord_bot: data.platform_discord_bot,
      platform_telegram_bot: data.platform_telegram_bot,
      ido_twitter: data.ido_twitter,
      ido_discord: data.ido_discord,
      ido_telegram: data.ido_telegram,
      ido_website: data.ido_website,
      ido_discord_bot: data.ido_discord_bot,
      ido_telegram_bot: data.ido_telegram_bot,
      

      starter_distribution: poolDetail.starter_distribution,
      rookie_distribution: poolDetail.rookie_distribution,
      legend_distribution: poolDetail.legend_distribution,
      
      starter_max_buy: Number(data.starter_max_buy),
      starter_min_buy: Number(data.starter_min_buy),
      rookie_max_buy:  Number(data.rookie_max_buy),
      rookie_min_buy:  Number(data.rookie_min_buy)

    };

    console.log('[updatePoolAfterDeloy] - Submit with data: ', submitData);

    let response = await updatePool(submitData, poolDetail.id, poolDetail.is_deploy);

    return response;
  };

  const [priceToken, setPriceToken] = useState<any>({
    price: 0,
    amount: 0,
    conversion_rate: 0,
  });

  const handleUpdateAfterDeloy = async (data: any) => {
    setLoading(true);
    try {
      const response: any = await updatePoolAfterDeloy(data);
      if (response?.status === 200) {
        dispatch(alertSuccess('Successful!'));
        fetchPoolDetailData && fetchPoolDetailData(poolDetail.id);
        // history.push(adminRoute('/campaigns'));
        // window.location.reload();
      } else {
        dispatch(alertFailure('Fail!'));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('ERROR: ', e);
    }
  };

  // Create / Update Pool (Before Pool Deployed to Smart Contract)
  const handleCampaignCreateUpdate = () => {
    if(renderErrorDiscordBot || renderErrorTelegramBot) return
    // setNeedValidate(false);
    setTimeout(() => {
      if (poolDetail?.is_deploy) {
        handleSubmit(handleUpdateAfterDeloy)();
      } else {
        handleSubmit(handleFormSubmit)();
      }
    }, 100);
  };

  const getTokenInforDetail = async (token: string) => {
    const erc20Token = await getTokenInfo(token);
    let tokenInfo: any = {};
    if (erc20Token) {
      const { name, symbol, decimals, address } = erc20Token;
      tokenInfo = { name, symbol, decimals, address };
    }
    return tokenInfo;
  };

  // Deploy Pool And Update
  const handleDeloySubmit = async (data: any) => {
    if (poolDetail.is_deploy || deployed) {
      alert('Pool is deployed !!!');
      return false;
    }
    // eslint-disable-next-line no-restricted-globals
    if (
      !window.confirm(
        'The system will store the latest pool information.\n' + 'Are you sure you want to deploy?',
      )
    ) {
      setNeedValidate(false);
      return false;
    }

    setLoadingDeploy(true);
    try {
      // Save data before deploy
      await createUpdatePool(data);
      // const tokenInfo = await getTokenInforDetail(data.token);

      const history = props.history;

      const submitData = {
        ...data,
        IDOEvent: JSON.parse(data.IDOEvent),
        IDOCompetitorBenmark: JSON.parse(data.competitor_benchmark),
        registed_by: loginUser?.wallet_address,

        amount: new Bignumber(data.totalSoldCoin).toNumber(),
        price: new Bignumber(data.priceTickerPerCurrencyToken).toNumber(),
        conversion_rate: new Bignumber(data.tokenRate).toNumber(),

        start_staking_snapshot: data.start_staking_snapshot
          ? data.start_staking_snapshot.unix()
          : null,
        start_ticket_close: data.start_ticket_close ? data.start_ticket_close.unix() : null,
        start_ticket_open: data.start_ticket_open ? data.start_ticket_open.unix() : null,
        start_pool_end: data.start_pool_end ? data.start_pool_end.unix() : null,

        token_address: data.token,
        linear_vesting: poolDetail.linear_vesting,
        id: poolDetail.id,
      };

      console.log('[handleDeloySubmit] - Submit with data: ', submitData);

      dispatch(deployPool(submitData, history, (isDeploy: any) => {
        setLoadingDeploy(false);
        setDeployed(isDeploy);
        if(isDeploy) history.push(adminRoute('/campaigns'));
      }))
    } catch (e) {
      setDeployed(false);
      setLoadingDeploy(false);
      console.log('ERROR: ', e);
    }
  };

  const handlerDeploy = () => {
    console.log('isPermission', isPermission);

    if (!isPermission) {
      dispatch(alertFailure('Your are not permission to deploy IDO pool'));
      return;
    }
    setNeedValidate(true);
    setTimeout(() => {
      handleSubmit(handleDeloySubmit)();
    }, 100);
  };

  const watchBuyType = watch('buyType');
  const watchIsPrivate = watch('isPrivate');
  const isDeployed = !!poolDetail?.is_deploy;

  const totalTicket = useMemo(() => {
    return calculatorTicket(priceToken?.price, priceToken?.conversion_rate, priceToken?.amount);
  }, [priceToken]);

  const handleChangePrice = (value: any, field: any) => {
    setPriceToken((prev: any) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    if (poolDetail) {
      setPriceToken({
        price: poolDetail.price,
        amount: poolDetail.amount,
        conversion_rate: poolDetail.conversion_rate,
      });
    }
  }, [poolDetail]);

  const { TabPane } = Tabs;
  function handleChangeTab(key: any) {
    console.log(key);
  }
  
  const checkNetworkAllowDeploy = () => {
    if(isEdit && poolDetail) {
      return !CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes(poolDetail.network)
    } else {
      return false
    }
  }

  const validateTimeSnapshot = useMemo(() => {
    if(isEdit && poolDetail) {
      const poolStartSnapshot = moment(poolDetail?.start_staking_snapshot).unix();
      const currentDate = moment().unix();
      return poolStartSnapshot <= currentDate;
    } else {
      return false
    }
  }, [poolDetail, isEdit]);

  return (
    <>
      {isEdit && poolDetail && checkNetworkAllowDeploy() && <NetworkWarningBanner poolDetail={poolDetail}/>}
      <div className="contentPage">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.createTab}>
              <Tabs
                defaultActiveKey="en"
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <TabPane tab="English" key="en" />
                <TabPane tab="Korean" key="kr" />
              </Tabs>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="">
              <div className={classes.exchangeRate}>
                <Typography className={classes.exchangeRateTitle}>Pool info</Typography>
                <div className={classes.exchangeRate}>
                  {isDeployed && (
                    <DisplayPoolSwitch
                      poolDetail={poolDetail}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      control={control}
                      isViewDetail={checkNetworkAllowDeploy()}
                    />
                  )}
                  <NetworkAvailable
                    poolDetail={poolDetail}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    needValidate={needValidate}
                    isViewDetail={true}
                  />

                  <AcceptCurrency
                    poolDetail={poolDetail}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    watch={watch}
                    isViewDetail={checkNetworkAllowDeploy()}
                  />

                  {/* <KycRequired
           poolDetail={poolDetail}
           setValue={setValue}
           errors={errors}
           control={control}
           watch={watch}
         /> */}
                </div>

                <PoolName
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  isViewDetail={checkNetworkAllowDeploy()}
                />
                <PoolBanner
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  isViewDetail={checkNetworkAllowDeploy()}
                />

                <PoolDescription
                  title="Summary"
                  name="project_description"
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  isViewDetail={checkNetworkAllowDeploy()}
                />
                {/* <PoolDescription
                  title="Differentiation from competitors"
                  name="differentiation_from_competitors"
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  isViewDetail={isViewDetail}
                /> */}
                <PoolDescription
                  title="FAQ"
                  name="FAQ"
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  isViewDetail={checkNetworkAllowDeploy()}
                />
                <PoolHash poolDetail={poolDetail} />

                <ProjectEvent
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  isViewDetail={checkNetworkAllowDeploy()}
                />

                <ProjectCategory
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  isViewDetail={checkNetworkAllowDeploy()}
                />

                <CompetitorBenchmarkTable
                  poolDetail={poolDetail}
                  setValue={setValue}
                  register={register}
                  watch={watch}
                  errors={errors}
                  control={control}
                  isViewDetail={checkNetworkAllowDeploy()}
                />

                <ProjectRatingTable
                  poolDetail={poolDetail}
                  setValue={setValue}
                  register={register}
                  watch={watch}
                  errors={errors}
                  control={control}
                  isViewDetail={checkNetworkAllowDeploy()}
                />

                {/* <UploadFiles isViewDetail={isViewDetail} /> */}

                <IDOSNS
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  isViewDetail={checkNetworkAllowDeploy()}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.exchangeRate}>
              <Typography className={classes.exchangeRateTitle}>Token info</Typography>
              <TokenAddress
                poolDetail={poolDetail}
                register={register}
                token={token}
                setToken={setToken}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                watch={watch}
                needValidate={needValidate}
                isViewDetail={checkNetworkAllowDeploy()}
              />

              <TokenSymbol
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                getTokenInforDetail={getTokenInforDetail}
                isViewDetail={checkNetworkAllowDeploy()}
              />

              <TotalCoinSold
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                isViewDetail={checkNetworkAllowDeploy()}
                setPrice={handleChangePrice}
              />

              <ExchangeRateETH
                poolDetail={poolDetail}
                register={register}
                token={token}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                isViewDetail={checkNetworkAllowDeploy()}
                setPrice={handleChangePrice}
              />

              <ExchangeTicket
                poolDetail={poolDetail}
                register={register}
                token={token}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                isViewDetail={checkNetworkAllowDeploy()}
                setPrice={handleChangePrice}
              />

              <Distribution
                poolDetail={poolDetail}
                register={register}
                token={token}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                isViewDetail={checkNetworkAllowDeploy()}
                totalTicket={totalTicket}
              />

              {priceToken.price !== 0 &&
                priceToken.amount !== 0 &&
                priceToken.conversion_rate !== 0 && (
                  <Typography className={classes.totalTicket}>
                    Total {formatPrecisionAmount(totalTicket) || '--'} tickets
                  </Typography>
                )}

              <BuyingLimit
                poolDetail={poolDetail}
                register={register}
                token={token}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                isViewDetail={checkNetworkAllowDeploy()}
              />
              <div className={classes.exchangeRate} style={{marginTop:30,marginBottom:0}}>
                <VestingPolicy
                  poolDetail={poolDetail}
                  register={register}
                  token={token}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  watch={watch}
                  isViewDetail={checkNetworkAllowDeploy()}
                />
              </div>
            </div>

            <div className={classes.exchangeRate}>
              <DurationTime
                poolDetail={poolDetail}
                register={register}
                token={token}
                setToken={setToken}
                setValue={setValue}
                errors={errors}
                control={control}
                getValues={getValues}
                watch={watch}
                needValidate={needValidate}
                isViewDetail={checkNetworkAllowDeploy()}
              />
            </div>
            <SocialSetting
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              register={register}
              isViewDetail={checkNetworkAllowDeploy()}
            />

            {(watchIsPrivate ? Number(watchIsPrivate) : 0) !== POOL_IS_PRIVATE.COMMUNITY && (
              <div className={classes.exchangeRate}>
                <WhitelistSocialRequirement
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  watch={watch}
                  isViewDetail={checkNetworkAllowDeploy()}
                />
              </div>
            )}
            {(watchIsPrivate ? Number(watchIsPrivate) : 0) === POOL_IS_PRIVATE.COMMUNITY && (
              <div className={classes.exchangeRate}>
                <GleamRequirement
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  watch={watch}
                  isViewDetail={checkNetworkAllowDeploy()}
                />
              </div>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>

            {isEdit && !poolDetail?.is_deploy && !validateTimeSnapshot && (
              <>
                <p className={classes.formErrorMessage}>
                  {!isPermission && ('Bạn không có quyền deploy!')}
                </p>
              </>
            )}
            <Box className={checkNetworkAllowDeploy() ? classes.displayNone : ''}>
              {!poolDetail?.deploy_transaction_hash && !poolDetail?.is_deploy && !validateTimeSnapshot && (
                <button
                  disabled={
                    !isEdit ||
                    !poolDetail?.id ||
                    poolDetail?.is_deploy ||
                    loading ||
                    loadingDeploy ||
                    deployed ||
                    !isPermission
                  }
                  className={
                    !isEdit || poolDetail?.is_deploy || deployed || !isPermission
                      ? classes.formButtonDeployed
                      : classes.formButtonDeploy
                  }
                  onClick={handlerDeploy}
                >
                  {loadingDeploy && <CircularProgress size={25} />}
                  {!loadingDeploy && 'Deploy'}
                </button>
              )}

              <button
                disabled={loading || loadingDeploy}
                className={classes.formButtonUpdatePool}
                onClick={handleCampaignCreateUpdate}
              >
                {loading || loadingDeploy ? (
                  <CircularProgress size={25} />
                ) : isEdit ? (
                  'Update'
                ) : (
                  'Create'
                )}
              </button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default withRouter(PoolForm);
