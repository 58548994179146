import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useCommonStyle } from '../../styles';
import useStyles from './style';

import { Tabs } from 'antd';
import { withRouter } from 'react-router-dom';
import { POOL_IS_PRIVATE } from '../../constants';
import { formatPrecisionAmount } from '../../utils';
import { getTokenInfo, TokenType } from '../../utils/token';

import { Typography } from '@material-ui/core';
import AcceptCurrency from './Components/AcceptCurrency';
import DisplayPoolSwitch from './Components/DisplayPoolSwitch';
import DurationTime from './Components/DurationTimes';
import ExchangeRateETH from './Components/ExchangeRateETH';
import NetworkAvailable from './Components/NetworkAvailable';
import PoolHash from './Components/PoolHash';
import SocialSetting from './Components/SocialSetting/SocialSetting';
import TokenAddress from './Components/TokenAddress';
import TokenLogo from './Components/TokenLogo';
import TokenSymbol from './Components/TokenSymbol';
import TotalCoinSold from './Components/TotalCoinSold';
import WhitelistSocialRequirement from './Components/WhitelistSocialRequirement';
import GleamRequirement from './Components/WhitelistSocialRequirement/GleamRequirement';

import { calculatorTicket } from '../../utils/formatNumber';
import BuyingLimit from './Components/BuyingLimit';
import Distribution from './Components/Distribution';
import ExchangeTicket from './Components/ExchangeTicket';
import IDOSNS from './Components/IDOSNS';
import VestingPolicy from './Components/VestingPolicy';
import PoolBanner from './Components/PoolBanner';

function PoolFormFixed(props: any) {
  const classes = useStyles();
  const commonStyle = useCommonStyle();
  const dispatch = useDispatch();


  const { isEdit, poolDetail, isViewDetail = false, refForm, poolId,needValidate, setNotAllowCreate } = props;


  const [token, setToken] = useState<TokenType | null>(null);


  const form =  useForm({
    mode: 'onChange',
    defaultValues: poolDetail,
    reValidateMode: 'onChange',
  });
  const { register, setValue, errors, control, watch,getValues } = form
   

  useEffect(() => {
    if(refForm) refForm(form);
  },[])

  const [priceToken, setPriceToken] = useState<any>({
    price: 0,
    amount: 0,
    conversion_rate: 0,
  });

  const getTokenInforDetail = async (token: string) => {
    const erc20Token = await getTokenInfo(token);
    let tokenInfo: any = {};
    if (erc20Token) {
      const { name, symbol, decimals, address } = erc20Token;
      tokenInfo = { name, symbol, decimals, address };
    }
    return tokenInfo;
  };

  const watchBuyType = watch('buyType');
  const watchIsPrivate = watch('isPrivate');
  const isDeployed = !!poolDetail?.is_deploy;

  const totalTicket = useMemo(() => {
    return calculatorTicket(priceToken?.price, priceToken?.conversion_rate, priceToken?.amount);
  }, [priceToken]);

  const handleChangePrice = (value: any, field: any) => {
    setPriceToken((prev: any) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    if (poolDetail) {
      setPriceToken({
        price: poolDetail.price,
        amount: poolDetail.amount,
        conversion_rate: poolDetail.conversion_rate,
      });
    }
  }, [poolDetail]);

  const watchIDODiscordBot = watch('ido_discord_bot');
  const watchPlatformDiscordBot = watch('platform_discord_bot');

  const renderErrorDiscordBot = useMemo(() => {
    if (watchIDODiscordBot && watchPlatformDiscordBot) {
      if (watchIDODiscordBot === watchPlatformDiscordBot) {
        return true;
      }
    } else {
      setNotAllowCreate(false)
      return false;
    }
  }, [watchIDODiscordBot, watchPlatformDiscordBot, setNotAllowCreate]);

  const watchIDOTelegramBot = watch('ido_telegram_bot');
  const watchPlatformTelegramBot = watch('platform_telegram_bot');

  const renderErrorTelegramBot = useMemo(() => {
    if (watchIDOTelegramBot && watchPlatformTelegramBot) {
      if (watchIDOTelegramBot === watchPlatformTelegramBot) {
        return true;
      }
    } else {
      return false
    }
  }, [watchIDOTelegramBot, watchPlatformTelegramBot]);

  useEffect(()=> {
    if(renderErrorDiscordBot || renderErrorTelegramBot) {
      return setNotAllowCreate(true)
    } else return setNotAllowCreate(false)
  }, [renderErrorTelegramBot, renderErrorDiscordBot, setNotAllowCreate])

  return (
    <div>
      <div className={classes.exchangeRate}>
        <Typography className={classes.exchangeRateTitle}>Pool info</Typography>
        <div className={classes.exchangeRate}>
          {isDeployed && (
            <DisplayPoolSwitch
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              isViewDetail={isViewDetail}
            />
          )}
          <NetworkAvailable
            poolDetail={poolDetail}
            setValue={setValue}
            errors={errors}
            control={control}
            needValidate={needValidate}
            isViewDetail={true}
          />

          <AcceptCurrency
            poolDetail={poolDetail}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
            isViewDetail={isViewDetail}
          />
        </div>
        <PoolHash poolDetail={poolDetail} />
        <PoolBanner
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          errors={errors}
          isViewDetail={isViewDetail}
        />

        {/* <IDOSNS
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          errors={errors}
          isViewDetail={isViewDetail}
        /> */}
      </div>
      <div className={classes.exchangeRate}>
        <Typography className={classes.exchangeRateTitle}>Token info</Typography>
        <TokenAddress
          poolDetail={poolDetail}
          register={register}
          token={token}
          setToken={setToken}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          watch={watch}
          needValidate={needValidate}
          isViewDetail={isViewDetail}
        />

        <TokenSymbol
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          watch={watch}
          errors={errors}
          getTokenInforDetail={getTokenInforDetail}
          isViewDetail={isViewDetail}
        />

        <TotalCoinSold
          poolDetail={poolDetail}
          register={register}
          setValue={setValue}
          errors={errors}
          isViewDetail={isViewDetail}
          setPrice={handleChangePrice}
        />

        <ExchangeRateETH
          poolDetail={poolDetail}
          register={register}
          token={token}
          setValue={setValue}
          errors={errors}
          control={control}
          watch={watch}
          isViewDetail={isViewDetail}
          setPrice={handleChangePrice}
        />

        <ExchangeTicket
          poolDetail={poolDetail}
          register={register}
          token={token}
          setValue={setValue}
          errors={errors}
          control={control}
          watch={watch}
          isViewDetail={isViewDetail}
          setPrice={handleChangePrice}
        />

        <Distribution
          poolDetail={poolDetail}
          register={register}
          token={token}
          setValue={setValue}
          errors={errors}
          control={control}
          watch={watch}
          isViewDetail={isViewDetail}
          totalTicket={totalTicket}
        />

        {priceToken.price !== 0 && priceToken.amount !== 0 && priceToken.conversion_rate !== 0 && (
          <Typography className={classes.totalTicket}>
            Total {formatPrecisionAmount(totalTicket) || '--'} tickets
          </Typography>
        )}

        <BuyingLimit
          poolDetail={poolDetail}
          register={register}
          token={token}
          setValue={setValue}
          errors={errors}
          control={control}
          watch={watch}
          isViewDetail={isViewDetail}
          setNotAllowCreate={setNotAllowCreate}
        />

        <VestingPolicy
          poolDetail={poolDetail}
          register={register}
          token={token}
          setValue={setValue}
          errors={errors}
          control={control}
          watch={watch}
          isViewDetail={isViewDetail}
        />
      </div>

      <div className={classes.exchangeRate}>
        <DurationTime
          poolDetail={poolDetail}
          register={register}
          token={token}
          setToken={setToken}
          setValue={setValue}
          errors={errors}
          control={control}
          getValues={getValues}
          watch={watch}
          needValidate={needValidate}
          isViewDetail={isViewDetail}
        />
      </div>
      <SocialSetting
        poolDetail={poolDetail}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        register={register}
        isViewDetail={isViewDetail}
      />

      {(watchIsPrivate ? Number(watchIsPrivate) : 0) !== POOL_IS_PRIVATE.COMMUNITY && (
        <div className={classes.exchangeRate}>
          <WhitelistSocialRequirement
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
            isViewDetail={isViewDetail}
          />
        </div>
      )}
      {(watchIsPrivate ? Number(watchIsPrivate) : 0) === POOL_IS_PRIVATE.COMMUNITY && (
        <div className={classes.exchangeRate}>
          <GleamRequirement
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
            isViewDetail={isViewDetail}
          />
        </div>
      )}
    </div>
  );
}

export default withRouter(PoolFormFixed);
