import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  tableContainer: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: '20px 25px 30px 25px',
    marginTop: 20,
  },
  tabTableTicket: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#FFCC00',
    },
  },
  titleTicketTab: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    textTransform: 'capitalize',
  },
  bodyTable: {
    minHeight: 300,
  },
  pagination: {
    marginTop: 30,
    fontSize: 16,
    fontWeight: 400,
    '& .MuiPagination-ul': {
      justifyContent: 'center',
      display: 'flex',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#FFCC00',
    },
  },
  blockBtnAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noItemTicket: {
    display: 'flex',
    justifyContent: 'center',
    padding: 50,
  },
  editDialogView: {
    fontSize: 14,
    display: 'flex',
    width: '100%',
    color: '#636363',
    transition: '.2s all ease-in',
    fontFamily: 'Roboto, sans-serif',

    '&:hover': {
      color: 'black',
    },

    '&.remove': {
      marginLeft: 5,
      border: 'none',
      background: 'transparent',
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.custom.colors.tertiary,
    },
  },
  tableCellFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .left': {
      display: 'flex',
      alignItems: 'center',

      '& > span': {
        marginLeft: 5,
      },
      '& > .check': {
        color: '#00AF12',
      },
      '& > .cancel': {
        color: '#B9B9B9',
      },
    },

    '& .right': {
      cursor: 'pointer',
      position: 'relative',
    },
  },
  btnCancel: {
    backgroundColor: '#cdcdcd',
    '&:hover': {
      background: '#cdcdcd',
      opacity: 0.8,
    },
  },
  modalTitle: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 20,
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
  iconClose: {
    position: 'absolute',
    top: 25,
    right: 25,
    opacity: 0.5,
    cursor: 'pointer',
  },
  modalDescription: {
    width: 400,
    display: 'block',
    margin: 'auto',
    paddingBottom: 20,
    fontSize: 16,
    fontWeight: 'bold',
  },
  modalAction: {
    display: 'flex',
    margin: '20px auto',
  },
  btnConfirm: {
    marginRight: 0,
  },
  inputWalletAddress: {
    width: '100%',
  },
}));

export default useStyles;
