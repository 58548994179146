export default function exportFileTxt(name: string = 'document', data: any) {
  const element = document.createElement('a');
  const file = new Blob([data?.toString()], {
    type: 'text/plain',
  });
  element.href = URL.createObjectURL(file);
  element.download = `${name}.txt`;
  document.body.appendChild(element);
  element.click();
}

export const splitStringMaxLength = (value: string, max: number) => {
  if (value && value.length > max) {
    return `${value.substring(0, max)}...`;
  }
  return value;
};
