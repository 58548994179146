import { getContractInstance } from '../../../services/web3';
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import stakeNoRewardABI from '../../../abi/Staking/PoolNoRewardABI.json';

const SP_ADDRESS = process.env.REACT_APP_POLYGON_NO_REWARD_STAKING_POOL_ADDRESS as string;

const useTierPooldWithdrawFee =  () => {

  const [loading, setLoading] = useState<boolean>(false);


  const fetchTierPooldWithdrawFee = useCallback(async () => {
    try {
      setLoading(true)
      const contract = getContractInstance(stakeNoRewardABI,SP_ADDRESS)
      if (!contract) {
        throw new Error('Invalid contract');
      }
      const [aNumber, bNumber, cNumber, withDrawFeeTime,] = await Promise.all([
        contract.methods.aNumber().call(),
        contract.methods.bNumber().call(),
        contract.methods.cNumber().call(),
        contract.methods.noWithdrawFeeTime().call(),
      ]);

      setLoading(false)
      
      return {
        aNumber:aNumber,
        bNumber:bNumber,
        cNumber:cNumber,
        withDrawFeeTime:withDrawFeeTime,
      }
    } catch (err: any) {
      console.log('ERROR: ', err);
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    fetchTierPooldWithdrawFee()
  }, [fetchTierPooldWithdrawFee])

  return {
    loading,
    fetchTierPooldWithdrawFee,
  }
}

export default useTierPooldWithdrawFee;