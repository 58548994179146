import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  tableTitle: {
    paddingTop: '0.5rem',
    paddingRight: '2.5rem',
    textAlign: 'left',
    fontSize: '1rem',
    verticalAlign: 'top',
  },

  formInput: {
    '& > p': {
      margin: '0.75rem 0',
    },
  },

  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '75%',
    margin: '0 auto',
  },
}));

export default styles;
