import { Tooltip, Typography } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useCommonStyle } from '../../../styles';
import { getIconCurrencyUsdt } from '../../../utils/usdt';
import useStyles from '../style';

function ExchangeTicket(props: any) {
  const classes = useStyles();
  const commonStyle = useCommonStyle();
  const { register, setValue, errors, control, watch, poolDetail, token, isViewDetail, setPrice } =
    props;
  const isDisploy = !!poolDetail?.is_deploy;
  const [rateValue, setRateValue] = useState('0');
  const acceptCurrency = watch('accept_currency');
  const networkAvailable = watch('network');
  let { currencyName } = getIconCurrencyUsdt({
    purchasableCurrency: acceptCurrency,
    networkAvailable: networkAvailable,
  });

  useEffect(() => {
    if (poolDetail) {
      const price_token = new BigNumber(poolDetail?.price || 0).toFixed();
      setValue('priceTickerPerCurrencyToken', price_token);
      setRateValue(price_token);
    }
  }, [poolDetail]);

  const renderErrorMinMax = (errors: any, prop: string, min: number, max: number = 100) => {
    if (errors[prop]) {
      if (errors[prop].type === 'required') {
        return 'This field is required';
      } else if (errors[prop].type === 'min') {
        return `This field must be greater than ${min}`;
      } else if (errors[prop].type === 'max') {
        return `This field must be less than ${max}`;
      } else if (errors[prop].type === 'maxDecimals') {
        return `Decimals can not greater than 8`;
      }
    }
  };

  // const isDeployed = !!poolDetail?.is_deploy;

  return (
    <div
      style={{
        paddingTop: 30,
      }}
    >
      <Typography className={classes.exchangeRateTitle}>
        Ticketing <span style={{ color: '#e51d1d' }}>*</span>{' '}
      </Typography>
      <div className={classes.formControlWrap}>
        <div className={classes.formControlFlexBlock}>
          <label className={`${classes.formControlLabel} ${classes.formControlBlurLabel}`}>
            You have
          </label>

          <div className={classes.formControlRate}>
            <input
              type="number"
              name="ticketFor"
              disabled={true}
              value={1}
              className={`${classes.formInputBox} ${classes.formInputBoxEther}`}
            />
            <button className={classes.box}>Ticket </button>
          </div>
        </div>

        <span style={{ margin: '22px 10px 0' }}> = </span>
        <div className={classes.formControlFlexBlock}>
          <label className={`${classes.formControlLabel} ${classes.formControlBlurLabel}`}>
            You get*
          </label>
          <div className={classes.formControlRate}>
            <CurrencyInput
              value={rateValue || 0}
              decimalsLimit={6}
              maxLength={25}
              onValueChange={(value: any, name: any) => {
                setRateValue(value);
                setPrice(value, 'price')
              }}
              className={`${classes.formInputBox} ${classes.formInputBoxBS}`}
              disabled={isDisploy || isViewDetail}
            />

            <input
              type="hidden"
              name={'priceTickerPerCurrencyToken'}
              value={rateValue || 0}
              ref={register({
                // required: true,
                validate: {
                  min: (value: any) => new BigNumber(value).comparedTo(0) > 0,
                },
              })}
              // disabled={isDeployed}
            />

            <Tooltip title={currencyName}>
              <button className={`${classes.box} ${classes.boxEther}`}>{currencyName}</button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}>
        {renderErrorMinMax(errors, 'priceTickerPerCurrencyToken', 0, 100000000)}
      </div>
    </div>
  );
}

export default ExchangeTicket;
