import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formCKEditor: {
    minHeight: 150,
    height: 200,
  },
  formControlLabel: {
    fontSize: 14,
    letterSpacing: '0.25px',
    color: '#363636',
  },
  customQuill: {
    marginTop: 7,
    height: 130,
    borderRadius: 10,
    '& .ql-toolbar.ql-snow': {
      borderTopLeftRadius: '5px !important',
      borderTopRightRadius: '5px !important',
    },
    '& .ql-container.ql-snow': {
      borderBottomLeftRadius: '5px !important',
      borderBottomRightRadius: '5px !important',
    },
  },
}));

export default useStyles;
