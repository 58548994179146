import { Box, CircularProgress, Grid } from '@material-ui/core';
import { Tabs } from 'antd';
import Bignumber from 'bignumber.js';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CHAIN_ID_NAME_MAPPING, ROLE_ADMIN } from '../../constants';
import useAddress from '../../hooks/useAddress';
import usePermission from '../../hooks/usePermission';
import { createPool, updatePool } from '../../request/pool';
import { alertFailure, alertSuccess } from '../../store/actions/alert';
import { deployPool } from '../../store/actions/campaign';
import { adminRoute } from '../../utils';
import PoolFormDynamic from './PoolFormDynamic';
import PoolFormFixed from './PoolFormFixed';
import useStyles from './style';

const initLanguages = [
  { lang: 'en', name: 'English' },
  { lang: 'kr', name: 'Korean' },
];
const langs = [...initLanguages];

const WrapPools = (props: any) => {
  const classes = useStyles();
  const { data: loginUser } = useSelector((state: any) => state.user);

  const { isEdit, poolDetail, isViewDetail = false, fetchPoolDetailData, poolId } = props;
  const [loading, setLoading] = useState(false);
  const [loadingDeploy, setLoadingDeploy] = useState(false);
  const [deployed, setDeployed] = useState(false);
  const [needValidate, setNeedValidate] = useState(false);
  const isPermission = usePermission([ROLE_ADMIN.ADMIN_FACTORY_POOL, ROLE_ADMIN.SUPPER_ADMIN]);
  const { currentNetworkId } = useSelector((state: any) => state).userCurrentNetwork;
  const langFormRefs: React.MutableRefObject<any> = React.useRef(langs);
  const fixedFormRef: React.MutableRefObject<any> = React.useRef();
  const validateStatusRef: React.MutableRefObject<any> = React.useRef(langs.map(() => false));
  const validateStatusfixedFormRef: React.MutableRefObject<any> = React.useRef();
  
  const [notAllowCreate, setNotAllowCreate] = useState<boolean>(false)
  const walletAddress = useAddress();
  const dispatch = useDispatch();
  const history = props.history;
  const [tab, setTab] = useState<number>(0);
  const { TabPane } = Tabs;

  function handleChangeTab(key: any) {
    setTab(Number(key));
  }
  
  const transformData = (eng: any, kor: any) => {
    return JSON.stringify({
      en: eng,
      kr: kor,
    });
  };
  const transformDataArray = (eng: any, kor: any, keyData: Array<string>) => {
    if (!eng && !kor) return '';

    const parseEnData = JSON.parse(eng);
    const parseKrData = JSON.parse(kor);
    const initData = parseEnData && parseEnData.length > 0 ? parseEnData : parseKrData;
    if ((parseEnData && parseEnData.length > 0) || (parseKrData && parseKrData.length > 0)) {
      const data =
        initData &&
        initData.map((item: any, index: number) => {
          let value = {} as any;
          keyData?.map((key: string) => {
            value[key] = JSON.stringify({
              en: parseEnData[index]?.[key],
              kr: parseKrData[index]?.[key],
            });
          });
          return {
            ...item,
            ...value,
          };
        });
      return data;
    }
    return '';
  };

  const createUpdatePool = async (deploying = false) => {
    const en = langFormRefs.current[0]?.getValues();
    const kr = langFormRefs.current[1]?.getValues();
    const fixedData = fixedFormRef.current?.getValues();

    const distribution = JSON.parse(fixedData.distribution) || {};
    const IDOSNSparse = fixedData.IDOSNS ? JSON.parse(fixedData.IDOSNS) : [];
    const submitData = {
      ...fixedData,
      project_name: transformData(en.project_name, kr.project_name),
      FAQ: transformData(en.FAQ, kr.FAQ),
      IDOEvent: transformDataArray(en.IDOEvent, kr.IDOEvent, ['name', 'text']),
      IDOCompetitorBenmark: transformDataArray(en.competitor_benchmark, kr.competitor_benchmark, [
        'name',
      ]),

      category: transformData(en.project_category, kr.project_category),
      project_description: transformData(en.project_description, kr.project_description),
      IDORating: transformDataArray(en.project_rating, kr.project_rating, ['name']),
      IDOSNS: IDOSNSparse,

      starter_distribution: new Bignumber(distribution.starter).toNumber(),
      rookie_distribution: new Bignumber(distribution.rookie).toNumber(),
      legend_distribution: new Bignumber(distribution.legend).toNumber(),

      starter_min_buy: new Bignumber(fixedData.starter_min_buy).toNumber(),
      starter_max_buy: new Bignumber(fixedData.starter_max_buy).toNumber(),
      rookie_min_buy: new Bignumber(fixedData.rookie_min_buy).toNumber(),
      rookie_max_buy: new Bignumber(fixedData.rookie_max_buy).toNumber(),
      TGE: new Bignumber(fixedData.TGE).toNumber(),
      linear_vesting: fixedData.start_pool_end.unix() - fixedData.start_ticket_close.unix(),
      token_symbol: fixedData.token_symbol,
      token_address: fixedData.token,
      amount: new Bignumber(fixedData.totalSoldCoin).toNumber(),
      price: new Bignumber(fixedData.priceTickerPerCurrencyToken).toNumber(),
      conversion_rate: new Bignumber(fixedData.tokenRate).toNumber(),
      is_display: Boolean(fixedData.is_display),

      start_staking_snapshot: fixedData.start_staking_snapshot
        ? fixedData.start_staking_snapshot.utc()
        : null,
      start_ticket_close: fixedData.start_ticket_close
        ? fixedData.start_ticket_close.utc().format()
        : null,
      start_ticket_open: fixedData.start_ticket_open
        ? fixedData.start_ticket_open.utc().format()
        : null,
      start_pool_end: fixedData.start_pool_end ? fixedData.start_pool_end.utc().format() : null,
    };

    let response = {} as any;
    try {
      if (isEdit) {
        response = await updatePool(submitData, poolId);
      } else {
        response = await createPool(submitData);
      }

      if (deploying) return;

      if (response?.status === 200 || response?.status === 201) {
        dispatch(alertSuccess('Successful!'));
        if (isEdit) {
          // window.location.reload();
          fetchPoolDetailData(poolId);
        } else {
          history.push(adminRoute('/campaigns'));
        }
      } else if (response?.status === 400){
        const { error } = response?.data;
        dispatch(alertFailure(error.message ? error.message : 'Fail!'));
      } else {
        dispatch(alertFailure('Fail!'));
      }
    } catch (err) {
      setLoading(false);
      console.log('ERROR: ', err);
    }
  };

  const handleSubmit = (index: number) => {
    return async (values: any) => {
      if (index <= validateStatusRef.current.length - 1) {
        validateStatusRef.current[index] = true;
      }
      if (index === -1) {
        validateStatusfixedFormRef.current = true;
      }

      if (validateStatusRef.current.includes(false) || !validateStatusfixedFormRef.current) return;

      await createUpdatePool();
      setLoading(false);
    };
  };

    // Update After Deploy
    const updatePoolAfterDeloy = async () => {
      const en = langFormRefs.current[0]?.getValues();
      const kr = langFormRefs.current[1]?.getValues();
      const fixedData = fixedFormRef.current?.getValues();


      const distribution = JSON.parse(fixedData.distribution) || {};
      const IDOSNSparse = fixedData.IDOSNS ? JSON.parse(fixedData.IDOSNS) : [];
      const submitData = {
        project_name: transformData(en.project_name, kr.project_name),
        FAQ: transformData(en.FAQ, kr.FAQ),
        IDOEvent: transformDataArray(en.IDOEvent, kr.IDOEvent, ['name','text']),
        IDOCompetitorBenmark: transformDataArray(en.competitor_benchmark, kr.competitor_benchmark, [
          'name',
        ]),
        differentiation_from_competitors: transformData(
          en.differentiation_from_competitors,
          kr.differentiation_from_competitors,
        ),
  
        category: transformData(en.project_category, kr.project_category),
        project_description: transformData(en.project_description, kr.project_description),
        IDORating: transformDataArray(en.project_rating, kr.project_rating, ['name']),
        IDOSNS: IDOSNSparse,

        is_display: fixedData.is_display,
        cover_photo: fixedData.cover_photo,
  
        social_discord:fixedData.platform_discord,
        social_telegram:fixedData.platform_telegram,
        social_twitter:fixedData.platform_twitter,

        platform_twitter:fixedData.platform_twitter,
        platform_discord:fixedData.platform_discord,
        platform_discord_bot:fixedData.platform_discord_bot,
        platform_discord_link:fixedData.platform_discord_link,
        platform_telegram_bot:fixedData.platform_telegram_bot,
        platform_telegram:fixedData.platform_telegram,

        ido_twitter: fixedData.ido_twitter,
        ido_discord: fixedData.ido_discord,
        ido_discord_bot: fixedData.ido_discord_bot,
        ido_discord_link: fixedData.ido_discord_link,
        ido_telegram_bot: fixedData.ido_telegram_bot,
        ido_telegram: fixedData.ido_telegram,
        ido_website: fixedData.ido_website,
        
        token_symbol: fixedData.token_symbol,
  
        starter_distribution: new Bignumber(distribution.starter).toNumber(),
        rookie_distribution: new Bignumber(distribution.rookie).toNumber(),
        legend_distribution: new Bignumber(distribution.legend).toNumber(),
        
        starter_min_buy: new Bignumber(fixedData.starter_min_buy).toNumber(),
        starter_max_buy: new Bignumber(fixedData.starter_max_buy).toNumber(),
        rookie_min_buy: new Bignumber(fixedData.rookie_min_buy).toNumber(),
        rookie_max_buy: new Bignumber(fixedData.rookie_max_buy).toNumber(),
  
      };
  
      console.log('[updatePoolAfterDeloy] - Submit with data: ', submitData);
  
      let response = await updatePool(submitData, poolDetail.id, poolDetail.is_deploy);
  
      setLoading(false);
      if (response?.status === 200) {
        dispatch(alertSuccess('Successful!'));
        fetchPoolDetailData && fetchPoolDetailData(poolDetail.id);
      } else if (response?.status === 400){
        const { error } = response?.data;
        dispatch(alertFailure(error.message ? error.message : 'Fail!'));
      } else {
        dispatch(alertFailure('Fail!'));
      }
    };


    const handleSubmitAfterDeploy = (index: number) => {
      return async (values: any) => {
        if (index <= validateStatusRef.current.length - 1) {
          validateStatusRef.current[index] = true;
        }
        if (index === -1) {
          validateStatusfixedFormRef.current = true;
        }
  
        if (validateStatusRef.current.includes(false) || !validateStatusfixedFormRef.current) return;
  
        await updatePoolAfterDeloy();
        setLoading(false);
      };
    };


    const handleCampaignCreateUpdate = () => {
      if(notAllowCreate) return
      setLoading(true);
      validateStatusRef.current.fill(false);
      validateStatusfixedFormRef.current = false;
      if(poolDetail?.is_deploy){
        langFormRefs.current.map((item: any, index: number) => {
          item?.handleSubmit(handleSubmitAfterDeploy(index), (err: any) => {
            console.log(`errorForm${index}`, err);
            console.log('item', item)
            setLoading(false);
            index === 1 && tab === 0 && window.scrollTo(0, 0);
            dispatch(alertFailure(`Please enter full information of tab ${index === 0 ? 'English' : 'Korean'}`));
          })();
        });
    
        fixedFormRef.current.handleSubmit(handleSubmitAfterDeploy(-1), (err: any) => {
          console.log(`errorFixedForm`, err);
          setLoading(false);
          dispatch(alertFailure(`Please enter full information`));
        })();

      }else{
        langFormRefs.current.map((item: any, index: number) => {
          item?.handleSubmit(handleSubmit(index), (err: any) => {
            console.log('item 1', item)
            console.log(`errorForm${index}`, err);
            setLoading(false);
            index === 1 && tab === 0 && window.scrollTo(0, 0);
            dispatch(alertFailure(`Please enter full information of tab ${index === 0 ? 'English' : 'Korean'}`));
          })();
        });
    
        fixedFormRef.current.handleSubmit(handleSubmit(-1), (err: any) => {
          console.log(`errorFixedForm`, err);
          setLoading(false);
          dispatch(alertFailure(`Please enter full information`));
        })();
      }
    
    };

  // Deploy Pool And Update
  const handleDeloySubmit = (index: number) => {
    return async (data: any) => {
      if (index <= validateStatusRef.current.length - 1) {
        validateStatusRef.current[index] = true;
      }
      if (index === -1) {
        validateStatusfixedFormRef.current = true;
      }

      if (validateStatusRef.current.includes(false) || !validateStatusfixedFormRef.current) return;

      console.log('pass valid');

      if (poolDetail.is_deploy || deployed) {
        alert('Pool is deployed !!!');
        return false;
      }
      // eslint-disable-next-line no-restricted-globals
      if (
        !window.confirm(
          'The system will store the latest pool information.\n' +
            'Are you sure you want to deploy?',
        )
      ) {
        setNeedValidate(false);
        return false;
      }

      setLoadingDeploy(true);
      try {
        // const tokenInfo = await getTokenInforDetail(data.token);

        await createUpdatePool(true);

        const history = props.history;
        const data = fixedFormRef.current?.getValues();

        const submitData = {
          ...data,
          registed_by: loginUser?.wallet_address,

          amount: new Bignumber(data.totalSoldCoin).toNumber(),
          price: new Bignumber(data.priceTickerPerCurrencyToken).toNumber(),
          conversion_rate: new Bignumber(data.tokenRate).toNumber(),

          start_staking_snapshot: data.start_staking_snapshot
            ? data.start_staking_snapshot.unix()
            : null,
          start_ticket_close: data.start_ticket_close ? data.start_ticket_close.unix() : null,
          start_ticket_open: data.start_ticket_open ? data.start_ticket_open.unix() : null,
          start_pool_end: data.start_pool_end ? data.start_pool_end.unix() : null,

          token_address: data.token,
          linear_vesting: poolDetail.linear_vesting,
          id: poolDetail.id,
        };

        dispatch(
          deployPool(submitData, history, (isDeploy: any) => {
            setLoadingDeploy(false);
            setDeployed(isDeploy);
            if (isDeploy) history.push(adminRoute('/campaigns'));
          }),
        );
      } catch (e) {
        setLoadingDeploy(false);
        console.log('ERROR: ', e);
      }
    };
  };

  const handlerDeploy = () => {
    if (!isPermission) {
      return dispatch(alertFailure('You are not permission to deploy IDO pool'));
    }
    setNeedValidate(true);
    // console.log('langFormRefs.current',langFormRefs.current);
    // console.log('langs',initLanguages);
    validateStatusRef.current.fill(false);
    validateStatusfixedFormRef.current = false;
    langFormRefs.current.map((item: any, index: number) => {
      item?.handleSubmit(handleDeloySubmit(index), (err: any) =>
        console.log(`errorForm${index}`, err),
      )();
    });
    fixedFormRef.current.handleSubmit(handleDeloySubmit(-1), (err: any) =>
      console.log(`errorFixedForm`, err),
    )();
  };

  const validateTimeSnapshot = useMemo(() => {
    if(isEdit && poolDetail) {
      const poolStartSnapshot = moment(poolDetail?.start_staking_snapshot).unix();
      const currentDate = moment().unix();
      return poolStartSnapshot <= currentDate;
    } else {
      return true;
    }
  }, [poolDetail, isEdit]);

  const checkNetworkAllowDeploy = () => {
    if(isEdit && poolDetail) {
      return !CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]?.toLowerCase().includes(poolDetail.network);
    } else {
      return false;
    }
  }  
  const checkIsDeployBy = () => {
    if(isEdit && poolDetail?.deployed_by) {
      return poolDetail?.deployed_by?.toLowerCase() !== walletAddress?.toLowerCase();
    } else {
      return false;
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className={classes.createTab}>
            <Tabs defaultActiveKey="en" onChange={handleChangeTab}>
              {initLanguages &&
                initLanguages.length > 0 &&
                initLanguages.map((lang, index) => {
                  return (
                    <TabPane tab={lang.name} key={index} forceRender>
                      <PoolFormDynamic
                        key={index}
                        isEdit={isEdit}
                        refForm={(ref: any) => (langFormRefs.current[index] = ref)}
                        lang={lang.lang}
                        poolDetail={poolDetail && poolDetail[lang.lang]}
                        needValidate={needValidate}
                        isViewDetail={checkNetworkAllowDeploy() || checkIsDeployBy()}
                      />
                    </TabPane>
                  );
                })}
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={6} style={{ marginTop: 20 }}>
          <PoolFormFixed
            isEdit={isEdit}
            refForm={(ref: any) => (fixedFormRef.current = ref)}
            poolDetail={poolDetail}
            needValidate={needValidate}
            isViewDetail={checkNetworkAllowDeploy() || checkIsDeployBy()}
            setNotAllowCreate={setNotAllowCreate}
          />
        </Grid>
      </Grid>
      {isEdit && !poolDetail?.is_deploy && !validateTimeSnapshot && (
        <>
          <p className={classes.formErrorMessage}>
            {!isPermission && ('You are not permission to deploy IDO pool')}
          </p>
        </>
      )}

      <Box className={checkNetworkAllowDeploy() || checkIsDeployBy() ? classes.displayNone : ''}>
        {!poolDetail?.is_deploy && (
          <button
            disabled={!isEdit || poolDetail?.is_deploy || loading || loadingDeploy || deployed || !isPermission || validateTimeSnapshot || poolDetail?.is_deploying}
            className={
              !isEdit || poolDetail?.is_deploy || deployed || !isPermission || validateTimeSnapshot || poolDetail?.is_deploying
                ? classes.formButtonDeployed
                : classes.formButtonDeploy
            }
            onClick={handlerDeploy}
          >
            {loadingDeploy ? <CircularProgress size={25} /> : !loadingDeploy && poolDetail?.is_deploying ? 'Deploying': 'Deploy'}
          </button>
        )}
        <button
          disabled={loading || loadingDeploy}
          className={classes.formButtonUpdatePool}
          onClick={handleCampaignCreateUpdate}
        >
          {loading || loadingDeploy ? <CircularProgress size={25} /> : isEdit ? 'Update' : 'Create'}
        </button>
      </Box>
    </>
  );
};

export default withRouter(WrapPools);
