import { Box, CircularProgress, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Web3 from 'web3';
import ButtonAction from '../../../components/Base/ButtonCustom';
import { CHAIN_ID_NAME_MAPPING, ROLE_ADMIN, STATUS_IDO } from '../../../constants';
import useAddress from '../../../hooks/useAddress';
import usePermission from '../../../hooks/usePermission';
import { getListTicket } from '../../../request/pool';
import { alertFailure } from '../../../store/actions/alert';
import { getRandomChainLink, writeResultRandomTicket } from '../../../store/actions/campaign';
import { etherscanRouteTxHash } from '../../../utils';
import { getChainIdNetWork, getNameNetWork } from '../../../utils/contractAddress/getAddresses';
import ModalConfirmRandom from '../Components/modules/ModalConfirmRandom';
import TicketTable from '../Components/templates/TicketTable';
import useStyles from './styles';

const TicketDetail = (props: any) => {
  const { poolDetail, confirmModal } = props;
  const { id }: any = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const walletAddress = useAddress();
  const [tab, setTab] = useState<string>('all');
  const [dataTabAll, setDataTabAll] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [dataTable, setDataTable] = useState<any>([]);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingOnBlockChain, setLoadingOnBlockChain] = useState<boolean>(false);
  const { currentNetworkId } = useSelector((state: any) => state.userCurrentNetwork);
  const isPermission = usePermission([ROLE_ADMIN.SUPPER_ADMIN, ROLE_ADMIN.ADMIN_IDO_CHAINLINK]);

  const getListTicketFunc = useCallback(() => {
    getListTicket(id, tab, currentPage)
      .then((res) => {
        setLastPage(res.total_page);
        setDataTable(res.data);
        if (tab === 'all') {
          setDataTabAll(res.data);
        }
      })
      .catch(() => dispatch(alertFailure('Ticket load fail !!!')));
  }, [id, tab, currentPage, dispatch]);

  useEffect(() => {
    getListTicketFunc();
  }, [getListTicketFunc]);

  const handleClickWriteResult = async () => {
    if (checkNetworkAllowWrite()) {
      return handleAlertSwitchNetwork('write functions', poolDetail.network);
    }
    if (loadingOnBlockChain) return;
    setLoadingOnBlockChain(true);
    await dispatch(
      writeResultRandomTicket(
        id,
        poolDetail.win_ticker_root,
        poolDetail.contract_address,
        poolDetail.network,
        () => {
          confirmModal();
          setLoadingOnBlockChain(false);
        },
      ),
    );
  };

  const handleChangeTab = (e: any, newTab: any) => {
    setTab(newTab);
    setCurrentPage(1);
  };

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleShowModal = () => {
    if (loading) return;
    if (loadingOnBlockChain) return;
    setOpenModalConfirm(!openModalConfirm);
  };

  const checkNetworkAllowRandom = () => {
    if (poolDetail) {
      const chainIDPolygon = process.env.REACT_APP_POLYGON_NETWORK_ID;
      return chainIDPolygon !== currentNetworkId;
    } else {
      return false;
    }
  };

  const handleAlertSwitchNetwork = (title: any, network: any) => {
    if (
      !window.confirm(
        `The system will switch to ${network} network to call ${title}. Do you want to continue?`,
      )
    ) {
      return false;
    } else if (title === 'Chainlink functions') {
      return handleClickSwitchNetworkRandomTicket();
    } else return handleClickSwitchNetwork(network);
  };

  const handleSubmitModal = async () => {
    if (checkNetworkAllowRandom()) {
      return handleAlertSwitchNetwork('Chainlink functions', 'Polygon');
    }
    if (loading) return;
    setLoading(true);
    await dispatch(
      getRandomChainLink(Number(id), () => {
        confirmModal();
        setLoading(false);
        setOpenModalConfirm(false);
        getListTicketFunc();
      }),
    );
  };

  const checkNetworkAllowWrite = () => {
    if (poolDetail) {
      return !CHAIN_ID_NAME_MAPPING[Number(currentNetworkId)]
        ?.toLowerCase()
        .includes(poolDetail.network);
    } else {
      return false;
    }
  };

  const validateTimeClose = useMemo(() => {
    const poolTicketClose = moment(poolDetail?.start_ticket_close).unix();
    const poolEnd = moment(poolDetail?.start_pool_end).unix();
    const currentDate = moment().unix();

    return poolTicketClose <= currentDate && currentDate <= poolEnd;
  }, [poolDetail]);

  const handleClickSwitchNetwork = async (network: string) => {
    const { ethereum } = window as any;
    const chainIDNetwork = getChainIdNetWork(network) || 0;
    const chainNameNetwork = getNameNetWork(network) || 0;
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(chainIDNetwork) }],
      });
    } catch (switchError: any) {
      try {
        if (switchError?.code === 4902) {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: Web3.utils.toHex(chainIDNetwork),
                chainName: chainNameNetwork,
              },
            ],
          });
        }
      } catch (addError) {
        dispatch(alertFailure('Switch network failed'));
      }
    }
  };
  const handleClickSwitchNetworkRandomTicket = async () => {
    const { ethereum } = window as any;
    const chainIDNetwork = (process.env.REACT_APP_POLYGON_NETWORK_ID as string) || 0;
    const chainNameNetwork = (process.env.REACT_APP_POLYGON_NETWORK_NAME as string) || 0;
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(chainIDNetwork) }],
      });
    } catch (switchError: any) {
      try {
        if (switchError?.code === 4902) {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: Web3.utils.toHex(chainIDNetwork),
                chainName: chainNameNetwork,
              },
            ],
          });
        }
      } catch (addError) {
        dispatch(alertFailure('Switch network failed'));
      }
    }
  };

  return (
    <Grid container spacing={2} className={classes.spacingBlock}>
      <Grid item xs={12} className={classes.blockBtnAction}>
        <Typography className={classes.titleField}>Ticket detail</Typography>
        {poolDetail.state === STATUS_IDO.CALCULATED || poolDetail.state === STATUS_IDO.ENDED ? (
          <span>
            Lottery result stored on Blockchain{' '}
            <a
              href={etherscanRouteTxHash(poolDetail.send_lottery_txhash, poolDetail)}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              View Transaction <OpenInNewIcon style={{ fontSize: '1rem' }} />
            </a>
          </span>
        ) : (
          <>
            {poolDetail.deployed_by?.toLowerCase() === walletAddress?.toLowerCase() &&
              validateTimeClose && (
                <Box>
                  <ButtonAction
                    onClick={handleShowModal}
                    disabled={
                      (!poolDetail.random_number && poolDetail?.is_get_random_number) ||
                      (!checkNetworkAllowRandom() && !isPermission) ||
                      poolDetail?.is_send_lottery ||
                      dataTabAll?.length === 0
                    }
                  >
                    {poolDetail?.is_get_random_number
                      ? 'Processing...'
                      : 'Randomize winning tickets'}
                  </ButtonAction>
                  <ButtonAction
                    onClick={handleClickWriteResult}
                    disabled={
                      poolDetail.is_get_random_number ||
                      !poolDetail.is_lottery_done ||
                      !poolDetail.win_ticker_root ||
                      poolDetail.is_send_lottery ||
                      dataTabAll?.length === 0
                    }
                  >
                    {poolDetail?.is_send_lottery
                      ? 'Writing result on Blockchain...'
                      : 'Write result on Blockchain'}
                    {loadingOnBlockChain ? <CircularProgress size={25} /> : null}
                  </ButtonAction>
                </Box>
              )}
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.tabTableTicket} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleChangeTab}>
            <Tab className={classes.titleTicketTab} label="All" value="all" />
            <Tab className={classes.titleTicketTab} label="Winning tickets" value="true" />
            <Tab className={classes.titleTicketTab} label="Refund tickets" value="false" />
          </Tabs>
        </Box>
        {dataTable.length ? (
          <Box>
            <Box className={classes.bodyTable}>
              <TicketTable data={dataTable} />
            </Box>
            {lastPage > 1 && (
              <Pagination
                page={currentPage}
                className={classes.pagination}
                count={lastPage}
                onChange={handlePaginationChange}
              />
            )}
          </Box>
        ) : (
          <Box className={classes.noItemTicket}>
            <Typography>There is no ticket to show</Typography>
          </Box>
        )}
      </Grid>
      <ModalConfirmRandom
        open={openModalConfirm}
        handleClose={handleShowModal}
        handleSubmit={handleSubmitModal}
        loading={loading}
      />
    </Grid>
  );
};

export default TicketDetail;
