import React, {useEffect, useState} from 'react';
import useStyles from "../style";
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {renderErrorCreatePool} from "../../../utils/validate";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// CSS in /src/index.css

function PoolDescription(props: any) {
  const classes = useStyles();
  const {
    register, setValue, errors,
    poolDetail,name,title, isViewDetail
  } = props;
  const renderError = renderErrorCreatePool;

  const defaultValue = '';
  const [description, setDescription] = useState(defaultValue);

  useEffect(() => {
    if (poolDetail && poolDetail[name]) {
      setValue(name, poolDetail[name]);
      setDescription(poolDetail[name]);
    }
  }, [poolDetail]);
  const isDeployed = !!poolDetail?.is_deploy;

  return (
    <>
      <div className={classes.formCKEditor}>
        <label className={classes.formControlLabel}>{title}</label>

      
        <textarea
          // val={poolDetail[name]}
          name={name}
          ref={register({})}
          // onChange={e => setDescription(e?.target?.value)}
          className={classes.formControlInput}
          rows={5}
          cols={50}
          disabled={isViewDetail}
          placeholder="Maximun 20,000 character"
          maxLength={20000}
        />

        {/* <p className={classes.formErrorMessage}>
          {
            renderError(errors, name)
          }
        </p> */}

      </div>
    </>
  );
}

export default PoolDescription;
