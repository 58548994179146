import { MenuItem, Select } from '@material-ui/core';
import { forwardRef } from 'react';
import useStyles from './styles';

const dataTierStatus = [
  { id: 3, label: 'Legend' },
  { id: 2, label: 'Rookie' },
  { id: 1, label: 'Starter' },
];

interface ISelectTierStatus {
  value: any;
  onChange: (e: any) => void;
}

const SelectTierStatus = forwardRef((props: ISelectTierStatus, ref) => {
  const classes = useStyles();
  const { value, onChange } = props;

  return (
    <Select
      ref={ref}
      value={value}
      onChange={onChange}
      displayEmpty
      disableUnderline
      className={classes.select}
      MenuProps={{
        classes: { list: classes.list },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      <MenuItem value="">All</MenuItem>
      {dataTierStatus?.map((option: any, index: any) => (
        <MenuItem value={option.id} key={index}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
});
export default SelectTierStatus;
