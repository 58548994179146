import _clone from 'lodash/clone';
import _toPair from 'lodash/toPairs';

import { FormEvent, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from './PoolLPForm.style';

interface PoolLP {
  apr?: number;
  pool_capacity?: number;
  individual_min?: number;
  individual_max?: number;
}

interface ErrorMgs {
  apr?: string;
  aprlessThan100?:string;
  pool_capacity?: string;
  pool_capacity_gt0?:string;
  individual_min?: string;
  individual_max?: string;
}

type ComponentProps = {
  form?: PoolLP | undefined;
  onSubmit: (data: any) => void;
  onClose: () => void;
  errorMessageList?: ErrorMgs;
  name?:string,
  validateData?:any
};

const defaultForm = {
  apr: undefined,
  pool_capacity: undefined,
  individual_min: undefined,
  individual_max: undefined,
};

const defaultError = {
  apr: false,
  pool_capacity: false,
  individual_min: false,
  individual_max: false,
};

const PoolLPForm: React.FC<ComponentProps> = ({
  form,
  onSubmit,
  errorMessageList,
  onClose,
  name,validateData
}: ComponentProps) => {
  const classes = useStyles();
  const [formFields, setFormFields] = useState(form ? _clone(form) : _clone(defaultForm));
  const [errors, setErrors] = useState(defaultError);
  const [typeOfMessage, setTypeOfMessage] = useState<any>([]);
  const [validators, setValidators] = useState<{
    [key: string]: (n: any) => Promise<void>;
  } | undefined >() ;


  useEffect(()=>{
    typeValid(name)
  },[name])

  const typeValid = (type: any) => {
    // console.log(type);
    
    switch (type) {
      case 'apr':
        setValidators({
          apr: async (value: any) => {
            if (!value || Number(value) <= 0 ) {
              setTypeOfMessage('apr')
              return Promise.reject('apr');
            }
            if ( Number(value) > 100) {
              setTypeOfMessage('aprLessThan100')
              return Promise.reject('aprLessThan100');
            }

            return Promise.resolve();
          },
        });
        break;
      case 'pool_capacity':
        setValidators({
          pool_capacity: async (value: any) => {
            if (Number(value) <= 0 ) {
              setTypeOfMessage('pool_capacity_gt0')
              return Promise.reject('pool_capacity_gt0');
            }
            // console.log('Number(value) <= Number(validateData.individual_max)',Number(value) , Number(validateData.individual_max));
            
            if (!Number(value) || Number(value) <= Number(validateData.individual_max)) {
              setTypeOfMessage('pool_capacity')
              return Promise.reject('pool_capacity');
            }

            return Promise.resolve();
          },
        });
        break;
      case 'individual_min':
      case 'individual_max':
        setValidators({
          individual_max: async (value: any) => {
            if (
              !Number(value) ||
              Number(value) >= Number(validateData.pool_capacity) ||
              Number(value) < 0
            ) {
              return Promise.reject('individual_max');
            }

            return Promise.resolve();
          },
          individual_min: async (value: any) => {
            if (
              !Number(value) ||
              Number(value) >= Number(formFields.individual_max) ||
              Number(value) < 0
            ) {
              return Promise.reject('individual_min');
            }

            return Promise.resolve();
          },
        });
        break;
    }
  };

  const formSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();
    if(errors.apr || errors.individual_max || errors.individual_min || errors.pool_capacity) { return }
    onSubmit(formFields);
    // try {
    //   const validationStates = await Promise.allSettled(
    //     _toPair(formFields).map((entries: [string, any]) => validators?.[entries[0]](entries[1])),
    //   );
    //   const errorKeys = validationStates
    //     .filter((state) => state.status === 'rejected')
    //     .map((state: any) => state.reason);
    //   if (errorKeys.length > 0) {
    //     throw errorKeys;
    //   }

    //   setErrors(_clone(defaultError));
    //   onSubmit(formFields);
    // } catch (errorKeys: any) {
    //   const errorKeyList: {
    //     [key: string]: any;
    //   } = {};
    //   errorKeys && errorKeys.length > 0 && errorKeys.forEach((key: string) => {
    //     errorKeyList[key] = true;
    //   });
    //   setErrors({ ...errors, ...errorKeyList });
    // }
  };

  const onEnterIndividualMin = async (event: any) => {
    const value = event.target.value;
    setFormFields({ ...formFields, individual_min: value });
    if (Number(value) > Number(formFields.individual_max)){
      setErrors({ ...errors, individual_min: true });
    } else {
      setErrors({ ...errors, individual_min: false });
    }
  };

  const onEnterIndividualMax = async (event: any) => {
    const value = event.target.value;
    setFormFields({ ...formFields, individual_max: value });
    if (Number(value) < Number(formFields.individual_min)){
      setErrors({ ...errors, individual_min: true });
    } else if (Number(value) > Number(validateData.pool_capacity)){
      setErrors({ ...errors, individual_max: true });
    } else {
      setErrors({ ...errors, individual_max: false, individual_min: false });
    }
  };

  const onEnterPoolCapacity = async (event: any) => {
    const value = event.target.value;
    setFormFields({ ...formFields, pool_capacity: value });
    try {
      await validators?.pool_capacity(value);
      setErrors({ ...errors, pool_capacity: false });
    } catch (error) {
      setErrors({ ...errors, pool_capacity: true });
    }
  };

  const onEnterAPR = async (event: any) => {
    const value = event.target.value;
    setFormFields({ ...formFields, apr: value });
    try {
      await validators?.apr(value);
      setErrors({ ...errors, apr: false });
    } catch (error) {
      setErrors({ ...errors, apr: true });
    }
  };

  return (
    <form onSubmit={formSubmitHandler}>
      {(name === 'individual_min' || name ==='individual_max') && (
        <>
          <p>Individual min for Reward staking</p>
          <TextField
            variant="outlined"
            id="starter-input"
            error={errors.individual_min}
            helperText={errorMessageList?.individual_min}
            value={formFields.individual_min}
            onChange={onEnterIndividualMin}
            size="small"
            classes={{
              root: classes.formInput,
            }}
            fullWidth
          />
        </>
      )}

      {(name === 'individual_min' || name ==='individual_max') && (
        <>
          <p>Individual max for Reward staking</p>
          <TextField
            variant="outlined"
            id="starter-input"
            error={errors.individual_max}
            helperText={errorMessageList?.individual_max}
            value={formFields.individual_max}
            onChange={onEnterIndividualMax}
            size="small"
            classes={{
              root: classes.formInput,
            }}
            fullWidth
          />
        </>
      )}

      {name === 'pool_capacity'&& (
        <>
          <p>Pool Capacity for Reward staking</p>
          <TextField
            variant="outlined"
            id="starter-input"
            error={errors.pool_capacity}
            helperText={typeOfMessage === 'pool_capacity' ? errorMessageList?.pool_capacity  : errorMessageList?.pool_capacity_gt0}
            value={formFields.pool_capacity}
            onChange={onEnterPoolCapacity}
            size="small"
            classes={{
              root: classes.formInput,
            }}
            fullWidth
          />
        </>
      )}
      {name === 'apr' && (
        <>
          <p>APR for Reward staking (%)</p>
          <TextField
            variant="outlined"
            id="starter-input"
            error={errors.apr}
            helperText={typeOfMessage === 'aprLessThan100' ? errorMessageList?.aprlessThan100 : errorMessageList?.apr}
            value={formFields.apr}
            onChange={onEnterAPR}
            size="small"
            classes={{
              root: classes.formInput,
            }}
            fullWidth
          />
        </>
      )}

      <div className={classes.btns}>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};

export default PoolLPForm;
