import React from 'react';
import PoolForm from '../../PoolCreate/PoolForm';
import WrapPoolForm from '../../PoolCreate/WrapPoolForm';
import useStyles from './styles';

interface IProps {
  poolDetail: any;
  failure: any;
  poolId:number
  fetchPoolDetailData?:() => void
}
const Info = (props: IProps) => {
  const classes = useStyles();
  return (
    <>
      {props.poolDetail && (
        <WrapPoolForm isViewDetail={false} isEdit={true} poolDetail={props.poolDetail} poolId={props.poolId} fetchPoolDetailData={props.fetchPoolDetailData}/>
      )}
      {props.failure && <p className={classes.errorMessage}>{props.failure}</p>}
    </>
  );
};
export default React.memo(Info);
