import React, { useMemo } from 'react';
import useStyles from '../../style';
import { renderErrorCreatePool } from '../../../../utils/validate';

function DiscordBot(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, watch, getValues, needValidate, poolDetail, isViewDetail } =
    props;
  const renderError = renderErrorCreatePool;
  const watchIDODiscordBot = watch('ido_discord_bot');
  const watchPlatformDiscordBot = watch('platform_discord_bot');

  const renderErrorDiscordBot = useMemo(() => {
    if (watchIDODiscordBot && watchPlatformDiscordBot) {
      if (watchIDODiscordBot === watchPlatformDiscordBot) {
        return true;
      }
    } else return false;
  }, [watchIDODiscordBot, watchPlatformDiscordBot]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Self Discord Bot</label>
        <input
          type="text"
          name="platform_discord_bot"
          defaultValue={poolDetail?.platform_discord_bot}
          ref={register({
            // required: true
          })}
          className={classes.formControlInput}
          disabled={isViewDetail}
        />
        <p className={classes.formErrorMessage}>{renderError(errors, 'platform_discord_bot')}</p>
        {renderErrorDiscordBot && (
          <p className={classes.formErrorMessage}>Duplicate Discord Bot</p>
        )}
      </div>
    </>
  );
}

export default DiscordBot;
