import React from 'react';
import useStyles from '../style';
import { renderErrorCreatePool } from '../../../utils/validate';
import { MESSAGE_INVALID_URL, REGEX_URL } from '../../../constants';

function PoolBanner(props: any) {
  const classes = useStyles();
  const { register, errors, poolDetail, isViewDetail } = props;
  const renderError = renderErrorCreatePool;

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>
          Cover photo <span style={{ color: '#e51d1d' }}>*</span>
        </label>
        <input
          type="text"
          name="cover_photo"
          placeholder="Photo URL"
          defaultValue={poolDetail?.cover_photo}
          ref={register({
            required: true,
            pattern: {
              value: REGEX_URL,
              message: MESSAGE_INVALID_URL,
            },
          })}
          className={classes.formControlInput}
          disabled={isViewDetail}
        />

        <p className={classes.formErrorMessage}>{renderError(errors, 'cover_photo')}</p>
      </div>
    </>
  );
}

export default PoolBanner;
